import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doLogin } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import Cookies from 'js-cookie';
import { getEntityRecord, getEntityRecords, getStatementList } from '@app/api/master/entity.api';
import * as S from './LoginForm.styles';
import { Buffer } from 'buffer';
import { getBranchDetail } from '@app/api/master/branch.api';
import { useAtom } from 'jotai';
import { ActiveBranchAtom } from '@app/components/header/components/branchDropdown';

interface LoginFormData {
  username: string;
  password: string;
}

export const initValues: LoginFormData = {
  username: '',
  password: '',
};

enum USER_TYPE {
  VENDOR = 'VENDOR',
  ADMIN = 'ADMIN',
  TECHNICIAN = 'TECHNICIAN',
  SALESPERSON = 'SALES',
}

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { token: authToken } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [_, setActiveBranch] = useAtom(ActiveBranchAtom);
  const [finishLoadBranch, setFinishLoadBranch] = useState(false);
  const [finishLoadUser, setFinishLoadUser] = useState(false);

  useEffect(() => {
    const token = Cookies.get('token');
    if (token && token.length > 20) toDashboard();
  }, []);

  const handleSubmit = (values: LoginFormData) => {
    setLoading(true);
    dispatch(doLogin(values))
      .unwrap()
      .then((res) => {
        Cookies.set('token', res.token);
        setUser(res.token);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  const toDashboard = () => {
    navigate('/');
  };

  const setUser = (token: string) => {
    try {
      const userData = decodeToken(token);
      if (userData.type) {
        // not superadmin, registered used
        let userTypeField = 'admin_id';
        switch (userData.type) {
          case USER_TYPE.ADMIN:
            userTypeField = 'admin_id';
            break;
          case USER_TYPE.TECHNICIAN:
            userTypeField = 'technician_id';
            break;
          case USER_TYPE.VENDOR:
            userTypeField = 'vendor_id';
            break;
          case USER_TYPE.SALESPERSON:
            userTypeField = 'sales_id';
            break;
        }
        const userRelatedId = userData[userTypeField];
        getUserProfile(userRelatedId, userData.type);
        if (userData.type == USER_TYPE.ADMIN) getAdminBranch(userRelatedId);
      } else {
        // check if root/superadmin
        if (userData.sub && userData.sub.includes('root')) {
          getRootData(userData.sub.split(',')[0]);
        }
      }
    } catch (e) {
      console.error(`[Error Load Profile] - ${e}`);
    }
  };

  const decodeToken = (token: string) => {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
  };

  const getRootData = (user_id: string) => {
    getEntityRecord('s_user', user_id).then((res) => {
      Cookies.set('user_logged', JSON.stringify({ ...res, user_type: 'ROOT', entity: 's_user', is_root: true }));
      Cookies.set('branch', JSON.stringify([]));
      setFinishLoadUser(true);
      navigate('/');
    });
  };

  const getUserProfile = (userRelatedId: string, userType: string) => {
    let entity = 'tt_admin';
    switch (userType) {
      case USER_TYPE.SALESPERSON:
        entity = 'tt_sales_person';
        break;
      case USER_TYPE.TECHNICIAN:
        entity = 'tt_technician';
        break;
      case USER_TYPE.VENDOR:
        entity = 'tt_vendor';
        break;
      default:
        entity = 'tt_admin';
        break;
    }
    getEntityRecord(entity, userRelatedId)
      .then((res) => {
        Cookies.set('user_logged', JSON.stringify({ ...res, user_type: userType, entity: entity, is_root: false }));
        if (userType != USER_TYPE.ADMIN) {
          Cookies.set('branch', JSON.stringify([{ ...res.branch, selected: true }]));
          setFinishLoadBranch(true);
          setActiveBranch({
            name: res.branch.name,
            id: res.branch.id,
          });
        }
      })
      .catch((e) => {
        console.error(`[Error Load User] - ${e}`);
        Cookies.remove('token');
      })
      .finally(() => {
        setFinishLoadUser(true);
        navigate('/');
      });
  };

  const getAdminBranch = (admin_id: string) => {
    getEntityRecords('tt_admin_branch', {
      limit: 100,
      page: 0,
      inf: true,
      q: `deleted==false;admin.id==${admin_id}`,
    })
      .then((res) => {
        if (res.records.length > 0)
          Cookies.set(
            'branch',
            JSON.stringify(
              res.records.map((admin_branch, index) => {
                if (index == 0)
                  setActiveBranch({
                    name: admin_branch.branch.name,
                    id: admin_branch.branch.id,
                  });
                return {
                  ...admin_branch.branch,
                  selected: index == 0, // set the first branch as default selected
                };
              }),
            ),
          );
      })
      .catch((e) => {
        console.error(`[Error Load Branch User] - ${e}`);
        Cookies.remove('token');
      })
      .finally(() => {
        setFinishLoadBranch(true);
      });
  };

  useEffect(() => {
    if (finishLoadBranch && finishLoadUser) {
      toDashboard();
    }
  }, [finishLoadBranch, finishLoadUser]);

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Auth.FormTitle>Login</Auth.FormTitle>
        <Auth.FormItem
          name="username"
          label="Username"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <BaseForm.Item name="termOfUse" valuePropName="checked" noStyle>
          <div style={{ marginBottom: '1rem' }}>
            <Link to="/terms" target={'_blank'}>
              <Auth.LinkText>Term and Condition</Auth.LinkText>
            </Link>{' '}
          </div>
        </BaseForm.Item>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        <Auth.ActionsWrapper>
          {/* <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <S.RememberMeText>{t('login.rememberMe')}</S.RememberMeText>
            </Auth.FormCheckbox>
          </BaseForm.Item> */}
          <Link to="/auth/reset-password">
            <S.ForgotPasswordText>Reset Password</S.ForgotPasswordText>
          </Link>
        </Auth.ActionsWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
