import { CategoryType } from '../categoriesList';

export interface Component {
  name: string;
  title: string;
  url: string;
  categories: CategoryType[];
  keywords: string[];
}

// TODO review and come up with a better approach for urls
// maybe we need to have enum with all routes like we had before?

// TODO change urls according to new dashboard routes and add new NFT components
export const components: Component[] = [
  {
    name: 'Admin',
    title: 'admin',
    url: `/master/admin`,
    categories: ['master'],
    keywords: ['admin', 'master'],
  },
  {
    name: 'Cabang',
    title: 'branch',
    url: `/master/branch`,
    categories: ['master'],
    keywords: ['cabang', 'branch', 'master'],
  },
  {
    name: 'Sales',
    title: 'sales',
    url: `/master/sales`,
    categories: ['master'],
    keywords: ['sales', 'master'],
  },
  {
    name: 'Customer',
    title: 'customer',
    url: `/master/customer`,
    categories: ['master'],
    keywords: ['pelanggan', 'pembeli', 'customer', 'master'],
  },
  {
    name: 'Spesialisasi',
    title: 'specialization',
    url: `/master/specialization`,
    categories: ['master'],
    keywords: ['spesialisasi', 'specialization'],
  },
  {
    name: 'Kategori Produk',
    title: 'category',
    url: `/master/category`,
    categories: ['master'],
    keywords: ['kategori', 'produk', 'category', 'product'],
  },
  {
    name: 'Teknisi',
    title: 'technician',
    url: `/master/technician`,
    categories: ['master'],
    keywords: ['teknisi', 'technician', 'tukang', 'user', 'master'],
  },
  {
    name: 'UOM',
    title: 'uom',
    url: `/master/uom`,
    categories: ['master'],
    keywords: ['satuan', 'berat', 'uom', 'master'],
  },
  {
    name: 'Produk',
    title: 'product',
    url: `/master/product`,
    categories: ['master'],
    keywords: ['produk', 'product', 'barang', 'paket', 'master'],
  },
  {
    name: 'Paket',
    title: 'package',
    url: `/master/package`,
    categories: ['master'],
    keywords: ['produk', 'product', 'barang', 'package', 'paket', 'master'],
  },
  {
    name: 'Vendor',
    title: 'vendor',
    url: `/master/vendor`,
    categories: ['master'],
    keywords: ['vendor', 'master'],
  },
  {
    name: 'Penawaran',
    title: 'quotation',
    url: `/transaction/quotation`,
    categories: ['transaction'],
    keywords: ['dokumen', 'penawaran', 'dokumen penawaran', 'transaksi'],
  },
  {
    name: 'SPK',
    title: 'spk',
    url: `/transaction/spk`,
    categories: ['transaction'],
    keywords: ['spk', 'dokumen', 'transaksi', 'surat perintah'],
  },
  {
    name: 'Invoice',
    title: 'invoice',
    url: `/transaction/invoice`,
    categories: ['transaction'],
    keywords: ['invoice', 'dokumen', 'transaksi', 'nota'],
  },
  {
    name: 'Pembayaran',
    title: 'payment',
    url: `/transaction/payment`,
    categories: ['transaction'],
    keywords: ['payment', 'dokumen', 'transaksi', 'pembayaran'],
  },
  {
    name: 'Settings',
    title: 'settings',
    url: `/system/settings`,
    categories: ['system'],
    keywords: ['settings', 'system', 'pengaturan'],
  },
];
