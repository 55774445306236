import { DashboardOutlined } from '@ant-design/icons';
import {
  EntityData,
  EntityFieldsResponse,
  EntityRecordRequest,
  createEntityRecord,
  getEntityFields,
} from '@app/api/master/entity.api';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Card } from '@app/components/common/Card/Card';
import { Modal } from '@app/components/common/Modal/Modal';
import { Switch } from '@app/components/common/Switch/Switch';
import { Button } from '@app/components/common/buttons/Button/Button';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Input, TextArea } from '@app/components/common/inputs/Input/Input';
import { H3 } from '@app/components/common/typography/H3/H3';
import { notificationController } from '@app/controllers/notificationController';
import { useLoading } from '@app/hooks/useLoading';
import { useMounted } from '@app/hooks/useMounted';
import { Col, Row, Space } from 'antd';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PolicyStatementModal from './components/PolicyStatementModal';
import PolicyStatementTable from './components/PolicyStatementTable';

const CreatePolicyPage = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [loadingFinish, setLoadingFinish] = useState(0);
  const navigate = useNavigate();
  const { isMounted } = useMounted();

  const [form] = BaseButtonsForm.useForm();
  const [createForm] = BaseButtonsForm.useForm();

  const [refreshToken, setRefreshToken] = useState(0);
  const [_, setLoadingScreen] = useAtom(useLoading);
  const [metafield, setMetafield] = useState<EntityFieldsResponse>();
  const [policyStatements, setPolicyStatements] = useState<EntityData[]>([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    getEntityFields('s_policy')
      .then((res) => {
        setMetafield(res);
      })
      .catch((err) => {
        console.error(`[Error Get Policy Fields] - ${err}`);
      })
      .finally(() => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  }, [refreshToken]);

  const onFinish = useCallback(
    (values: EntityData) => {
      if (policyStatements.length === 0) {
        notificationController.error({ message: 'Mohon tambahkan minimal 1 akses' });
        return;
      }

      setLoadingScreen({
        isLoading: true,
        message: 'Mohon menunggu...',
      });
      setTimeout(() => {
        setFieldsChanged(false);

        createPolicy(values, (policyId) => {
          setLoadingFinish(policyStatements?.length || 0);

          policyStatements.forEach(async (statement) => {
            // call create policy statement API
            const params: EntityRecordRequest = {
              ...statement,
              policy: {
                id: policyId,
              },
            };

            await createEntityRecord('s_policy_statement', params)
              .then((res) => {
                if (loadingFinish === 0) {
                  notificationController.success({ message: 'Sukses menambah hak akses' });
                  navigate(`/permission/policy/${policyId}/update`);
                }
              })
              .catch((err) => {
                console.error(`[Create Policy Statement Error] - ${err}`);
                notificationController.error({ message: 'Gagal menambah hak akses' });
              });
            setLoadingFinish(loadingFinish - 1);
          });
          setLoadingScreen({
            isLoading: false,
            message: 'Mohon menunggu...',
          });
        });
      }, 1000);
    },
    [policyStatements],
  );

  const createPolicy = (values: EntityData, onSuccess: (userId: string) => void) => {
    createEntityRecord('s_policy', values)
      .then((res) => onSuccess(res.id))
      .catch((err) => {
        console.error(`[Create Policy Error] - ${err}`);
        notificationController.error({ message: 'Gagal menambah hak akses' });
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  };

  const addPolicyStatement = (values: any) => {
    setPolicyStatements([...policyStatements, values]);
    setOpenModal(false);
    createForm.resetFields();
  };
  const deletePolicyStatements = (entity_name: string) => {
    const newPolicyStatements = [...policyStatements];
    const deletedIndex = newPolicyStatements.findIndex((statement) => statement.entity_name == entity_name);

    newPolicyStatements.splice(deletedIndex, 1);
    if (deletedIndex > -1) setPolicyStatements([...newPolicyStatements]);
  };
  const checkPolicyStatement = (entity_name: string, access: string) => {
    const newPolicyStatements = policyStatements.map((statement) => {
      if (statement.entity_name != entity_name) return statement;
      const data = { ...statement };
      data[access] = !data[access];
      return data;
    });

    setPolicyStatements(newPolicyStatements);
  };
  const checkAllPolicyStatement = (entity_names: string[]) => {
    console.log(policyStatements);
    console.log(entity_names);
    const newPolicyStatements = policyStatements.map((statement) => {
      const check = entity_names.includes(statement.entity_name);
      return {
        ...statement,
        grant_read: check,
        grant_create: check,
        grant_update: check,
        grant_delete: check,
      };
    });

    setPolicyStatements(newPolicyStatements);
  };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="/permission/policy">Hak Akses</BreadcrumbItem>
        <BreadcrumbItem href="#">Tambah</BreadcrumbItem>
      </Breadcrumb>
      <Row gutter={[30, 30]}>
        {/* Main Data */}
        <Col xs={24} md={24}>
          <Card title="Data Hak Akses" padding="1.25rem">
            <BaseButtonsForm
              form={form}
              name="Tambah Hak Akses"
              isFieldsChanged={isFieldsChanged}
              onFieldsChange={() => setFieldsChanged(true)}
              setFieldsChanged={setFieldsChanged}
              onFinish={onFinish}
              loading={isLoading}
            >
              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  <BaseButtonsForm.Item
                    name="name"
                    label="Nama Hak Akses"
                    rules={[
                      {
                        required: !metafield?.name.nullable,
                        message: 'Harap masukkan nama hak akses',
                        type: 'string',
                      },
                    ]}
                  >
                    <Input />
                  </BaseButtonsForm.Item>
                </Col>

                <Col xs={24} md={12}>
                  <BaseButtonsForm.Item
                    name="description"
                    label="Deskripsi"
                    rules={[
                      {
                        required: !metafield?.description.nullable,
                        message: 'Harap masukkan deskripsi',
                      },
                    ]}
                  >
                    <TextArea />
                  </BaseButtonsForm.Item>
                </Col>

                <Col xs={24} md={12}>
                  <BaseButtonsForm.Item
                    name="administrator"
                    label="Administrator"
                    rules={[{ required: !metafield?.administrator.nullable, message: 'Harap memilih' }]}
                    valuePropName="checked"
                  >
                    <Switch />
                  </BaseButtonsForm.Item>
                </Col>
              </Row>

              <div
                style={{
                  display: 'flex',
                  placeContent: 'space-between',
                  margin: '12px 0',
                  alignItems: 'center',
                }}
              >
                <H3>Detil Akses</H3>
                <Button onClick={() => setOpenModal(true)}>Tambah Akses</Button>
              </div>

              <PolicyStatementTable
                data={policyStatements}
                onDelete={deletePolicyStatements}
                onSelectAll={checkAllPolicyStatement}
                onChangeCheck={checkPolicyStatement}
              />
              <br />
            </BaseButtonsForm>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Tambah Akses"
        open={openModal}
        onOk={() => createForm.submit()}
        onCancel={() => setOpenModal(false)}
        destroyOnClose
      >
        <PolicyStatementModal form={createForm} onFinish={addPolicyStatement} />
      </Modal>
    </>
  );
};

export default CreatePolicyPage;
