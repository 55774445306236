import React from 'react';
import { Card } from '@app/components/common/Card/Card';
import { LinkButton } from '@app/components/common/buttons/Button/Button';
import { DashboardOutlined, PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Space } from 'antd';
import { GroupListTable } from '@app/components/tables/master/group/GroupListTable';

const ListGroupPage: React.FC = () => {
  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="#">Grup Akses</BreadcrumbItem>
      </Breadcrumb>

      <Card
        title="Grup Akses"
        padding="1.25rem 1.25rem 0"
        extra={
          <LinkButton size="middle" icon={<PlusOutlined />} to="/permission/group/create">
            Tambah Grup Akses
          </LinkButton>
        }
      >
        <GroupListTable />
      </Card>
    </>
  );
};

export default ListGroupPage;
