/* eslint-disable prettier/prettier */
import { DashboardOutlined } from '@ant-design/icons';
import {
  EntityData,
  EntityFieldsResponse,
  EntityRecordRequest,
  createEntityRecord,
  getEntityFields,
  getEntityRecord,
} from '@app/api/master/entity.api';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Card } from '@app/components/common/Card/Card';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { DatePicker } from '@app/components/common/pickers/DatePicker';
import { CurrencyInput } from '@app/components/forms/master/CurrencyInput';
import { DataSelectionForm } from '@app/components/forms/master/DataSelectionForm';
import { PaymentTypeForm } from '@app/components/forms/master/PaymentType/PaymentTypeFormSelection';
import { SelectionForm } from '@app/components/forms/master/SelectionForm';
import { notificationController } from '@app/controllers/notificationController';
import { useLoading } from '@app/hooks/useLoading';
import { useMounted } from '@app/hooks/useMounted';
import { checkPagePermission, formatRupiahPrice } from '@app/utils/utils';
import { Col, Row, Space } from 'antd';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InvoiceTabData } from '../invoice/UpdateInvoicePage';

const InfoText = styled.div`
  text-align: right;
  font-size: 14px;
  margin-top: 5px;
  cursor: pointer;
  color: var(--text-nft-light-color);
`;

const CreatePaymentPage: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isMounted } = useMounted();

  const [form] = BaseButtonsForm.useForm();

  const [refreshToken, setRefreshToken] = useState(0);
  const [_, setLoadingScreen] = useAtom(useLoading);
  const [metafield, setMetafield] = useState<EntityFieldsResponse>();

  const [outstanding, setOutstanding] = useState(0);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_payment',
          action: 'CREATE',
        },
      ])
    )
      navigate('/');

    form.setFieldValue('invoice', parseInt(query.get('invoice') || '0'));
    form.setFieldValue('date', dayjs());
    fetchInvoiceDetail();
  }, []);

  useEffect(() => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    getEntityFields('tt_payment')
      .then((res) => {
        setMetafield(res);
      })
      .catch((err) => {
        console.error(`[Error Get Payment Fields] - ${err}`);
      })
      .finally(() => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  }, [refreshToken]);

  const fetchInvoiceDetail = () => {
    setLoading(true);
    getEntityRecord('tt_invoice', parseInt(query.get('invoice') || '0'))
      .then((res) => {
        setOutstanding(res.outstanding);
      })
      .catch((err) => {
        console.error(`[Get Payment Error] - ${err}`);
        navigate(`/transaction/invoice`);
        notificationController.error({
          message: 'Maaf terjadi kesalahan pada sistem. Silahkan coba beberapa saat lagi.',
        });
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });
  };

  const onFinish = useCallback((values: EntityData) => {
    // todo dispatch an action here
    setLoading(true);
    setTimeout(() => {
      setFieldsChanged(false);
      // call create payment API
      const body: EntityRecordRequest = {
        ...values,
        invoice: {
          id: values.invoice,
        },
        type: {
          id: values.type,
        },
      };

      if (values.date) body['date'] = dayjs(values.date).format('YYYY-MM-DD 00:00:00');
      createEntityRecord('tt_payment', body)
        .then((res) => {
          notificationController.success({ message: 'Sukses menambah pembayaran' });
          navigate(`/transaction/invoice/${values.invoice}/update?tab=${InvoiceTabData.PAYMENT}`);
        })
        .catch((err) => {
          console.error(`[Error Create Payment] - ${err}`);
          notificationController.error({ message: 'Gagal menambah pembayaran' });
        })
        .finally(() => {
          if (isMounted.current) {
            setLoading(false);
          }
        });
    }, 1000);
  }, []);

  const setTotal = () => {
    form.setFieldValue('total', outstanding);
    setFieldsChanged(true);
  };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href={`/transaction/invoice/${query.get('invoice') || 0}/update?tab=${InvoiceTabData.PAYMENT}`}>
          Invoice
        </BreadcrumbItem>
        <BreadcrumbItem href="#">Tambah Pembayaran</BreadcrumbItem>
      </Breadcrumb>
      <Row gutter={[30, 30]}>
        {/* Main Data */}
        <Col xs={24} md={24}>
          <Card title="Data Pembayaran" padding="1.25rem">
            <BaseButtonsForm
              form={form}
              name="Tambah Pembayaran"
              isFieldsChanged={isFieldsChanged}
              onFieldsChange={() => setFieldsChanged(true)}
              setFieldsChanged={setFieldsChanged}
              onFinish={onFinish}
              loading={isLoading}
            >
              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={8}>
                  <SelectionForm
                    entity="tt_invoice"
                    field="invoice"
                    form={form}
                    label="Invoice"
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.invoice}
                    optionLabel="code"
                    disabled
                  />
                </Col>

                <Col xs={24} md={8}>
                  <BaseButtonsForm.Item
                    name="date"
                    label="Tanggal"
                    rules={[
                      {
                        required: !metafield?.date.nullable,
                        message: 'Harap masukkan tanggal',
                      },
                    ]}
                  >
                    <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} placeholder="Pilih tanggal" />
                  </BaseButtonsForm.Item>
                </Col>

                <Col xs={24} md={8}>
                  <PaymentTypeForm form={form} onChange={() => setFieldsChanged(true)} />
                </Col>

                <Col xs={24} md={8}>
                  <BaseButtonsForm.Item
                    name="total"
                    label="Total"
                    rules={[{ required: !metafield?.total.nullable, message: 'Harap masukkan total pembayaran' }]}
                  >
                    <CurrencyInput
                      onChange={() => setFieldsChanged(true)}
                      textInfo={
                        outstanding > 0
                          ? {
                            value: `Belum terbayar: ${formatRupiahPrice(outstanding)}`,
                            onChange: setTotal,
                          }
                          : undefined
                      }
                    />
                  </BaseButtonsForm.Item>
                </Col>

                <Col xs={24} md={8}>
                  <DataSelectionForm
                    field="paid_by"
                    form={form}
                    label="Paid By"
                    options={
                      metafield?.paid_by.expected_values?.map((value) => {
                        const seperatedValue = value.toString().split('_');
                        const paidMethodLabel = seperatedValue.join(' ');
                        return { value: value, label: paidMethodLabel };
                      }) || []
                    }
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.paid_by}
                  />
                </Col>
              </Row>
            </BaseButtonsForm>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreatePaymentPage;
