import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { notificationController } from '@app/controllers/notificationController';
import { useMounted } from '@app/hooks/useMounted';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { FormInstance } from 'antd/es/form/Form';
import { EntityData, EntityField, getEntityRecords } from '@app/api/master/entity.api';
import { Pagination } from '@app/api/general.api';
import useDebounce from '@app/hooks/useDebounce';
import { CloseCircleOutlined } from '@ant-design/icons';

export type CityFormProps = {
  form: FormInstance;
  metafield?: EntityField;
  onChange: () => void;
  onClear?: (value?: any) => void;
  disabled?: boolean;
};

export const CityForm: React.FC<CityFormProps> = ({ form, metafield, onChange, disabled, onClear }) => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [cityData, setCityData] = useState<Array<any> | null>(null);
  const [refetchToken, setRefetch] = useState(0);
  const { isMounted } = useMounted();
  const [pagination, setPagination] = useState<Pagination>();
  const [query, setQuery] = useState<string>();

  const search = useDebounce(query, 250);

  useEffect(() => {
    fetch();
  }, [refetchToken, search]);

  const fetch = () => {
    const id = form.getFieldValue('city');
    let params: {
      page: number;
      limit: number;
      q?: string;
      inf: boolean;
    } = {
      page: (pagination?.current || 1) - 1,
      limit: pagination?.pageSize || 10,
      inf: true,
    };

    if (id) params = { ...params, q: 'id==' + id };
    if (search) params = { ...params, q: search };

    getEntityRecords('c_city', params)
      .then((res) => {
        setCityData(
          res.records.map((data: EntityData) => {
            return {
              value: data.id,
              label: data.name,
            };
          }),
        );
      })
      .catch((err) => {
        console.error(`[Get City List Error] ${err}`);
      })
      .finally(() => {
        if (isMounted.current) {
          setFetchLoading(false);
        }
      });
  };

  const loadingItem = () => {
    return (
      <div style={{ textAlign: 'center', padding: '4px' }}>
        <Spin size="small" />
        <div>Mohon menunggu</div>
      </div>
    );
  };

  const handleChange = (value: unknown) => {
    const val = value as string[];
    form.setFieldValue('city', val);
    if (onChange !== undefined) onChange();
  };

  const handleSearch = (value: string) => {
    setFetchLoading(true);
    setPagination({
      current: 1,
      pageSize: 10,
    });
    if (value) setQuery(`name=ilike=${value}`);
    else setQuery('');
  };

  const handleClear = () => {
    form?.resetFields(['city']);
    setPagination({
      current: 1,
      pageSize: 10,
    });
    setQuery('');
    setFetchLoading(true);
    setRefetch(refetchToken + 1);

    if (onClear) onClear();
  };

  return (
    <>
      <BaseButtonsForm.Item
        name="city"
        label="Kota"
        rules={[{ required: !metafield?.nullable, message: 'Harap pilih kota', type: 'number' }]}
        hasFeedback
      >
        <Select
          defaultValue={form.getFieldValue('city')}
          showSearch
          onChange={handleChange}
          onSearch={handleSearch}
          onClear={handleClear}
          filterOption={false}
          notFoundContent={fetchLoading ? loadingItem() : 'Tidak ada data'}
          disabled={disabled}
          clearIcon={<CloseCircleOutlined />}
          allowClear
        >
          {cityData &&
            cityData.map((city, idx) => {
              return (
                <Option value={city.value} key={`city-selection-${idx}`}>
                  {city.label}
                </Option>
              );
            })}
        </Select>
      </BaseButtonsForm.Item>
    </>
  );
};
