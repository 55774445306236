import { getPicture } from '@app/api/master/entity.api';
import { Modal } from '@app/components/common/Modal/Modal';
import { Button } from '@app/components/common/buttons/Button/Button';
import SkeletonImage from 'antd/lib/skeleton/Image';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const ImageWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1 / 1;
  border: dotted 1px #808080;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
`;

type PhotoProps = {
  mediaId: string;
  onDelete?: () => void;
};

const Photo = ({ mediaId, onDelete }: PhotoProps) => {
  const [imageFile, setImageFile] = useState(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const fetchImage = () => {
    getPicture(mediaId)
      .then((res) => {
        setImageFile(res);
      })
      .catch((err) => {
        console.error(`[Error Fetch Image] - ${err}`);
      });
  };

  useEffect(() => {
    fetchImage();
  }, [mediaId]);

  const deletePhoto = () => {
    setOpenDeleteModal(true);
  };

  const doDeletePhoto = () => {
    onDelete && onDelete();
    setOpenDeleteModal(false);
    setOpenModal(false);
  };

  return (
    <>
      <ImageWrapper>
        {imageFile ? (
          <>
            <img style={{ width: '100%', objectFit: 'cover' }} src={imageFile} onClick={() => setOpenModal(true)} />
          </>
        ) : (
          <SkeletonImage style={{ width: '100%', objectFit: 'cover' }} />
        )}
      </ImageWrapper>
      <Modal title="Lihat foto" open={openModal} onCancel={() => setOpenModal(false)} width={'40%'} footer={null}>
        <div style={{ width: '100%' }}>
          {imageFile && (
            <img style={{ width: '100%', objectFit: 'cover' }} src={imageFile} onClick={() => setOpenModal(true)} />
          )}
        </div>
        {onDelete && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
            <Button type="primary" danger onClick={deletePhoto} style={{ width: '100%' }}>
              Hapus
            </Button>
          </div>
        )}
      </Modal>
      <Modal title="Hapus foto" open={openDeleteModal} onOk={doDeletePhoto} onCancel={() => setOpenDeleteModal(false)}>
        Apakah yakin ingin menghapus foto ini?
      </Modal>
    </>
  );
};

export default Photo;
