import { DashboardOutlined } from '@ant-design/icons';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Card } from '@app/components/common/Card/Card';
import { PaymentListTable } from '@app/components/tables/master/payment/PaymentListTable';
import { checkPagePermission } from '@app/utils/utils';
import { Space } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ListPaymentPage: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_payment',
          action: 'READ',
        },
      ])
    )
      navigate('/');
  }, []);

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="#">Pembayaran</BreadcrumbItem>
      </Breadcrumb>
      <Card title="Pembayaran" padding="1.25rem 1.25rem 0">
        <PaymentListTable />
      </Card>
    </>
  );
};

export default ListPaymentPage;
