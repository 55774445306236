import React from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Input } from '@app/components/common/inputs/Input/Input';

export const UsernameItem: React.FC = () => {
  return (
    <BaseButtonsForm.Item
      name="username"
      label="Username"
      hasFeedback
      rules={[{ required: true, message: 'Harap masukkan username' }]}
    >
      <Input />
    </BaseButtonsForm.Item>
  );
};
