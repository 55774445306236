import { DeleteOutlined, EditTwoTone, SearchOutlined } from '@ant-design/icons';
import { Pagination } from '@app/api/general.api';
import {
  EntityData,
  EntityRecordFilter,
  deleteEntity,
  getEntityRecordCount,
  getEntityRecords,
} from '@app/api/master/entity.api';
import { Modal } from '@app/components/common/Modal/Modal';
import { ActiveBranchAtom } from '@app/components/header/components/branchDropdown';
import { notificationController } from '@app/controllers/notificationController';
import { useLoading } from '@app/hooks/useLoading';
import { useMounted } from '@app/hooks/useMounted';
import { formatFullDate, formatRupiahPrice, getUserBranch } from '@app/utils/utils';
import { Input, Space, TablePaginationConfig } from 'antd';
import { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps, FilterValue } from 'antd/es/table/interface';
import { Table } from 'components/common/Table/Table';
import { Button } from 'components/common/buttons/Button/Button';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const initialPagination: Pagination = {
  pageSize: 10,
  current: 1,
};

export type PaymentListTableProps = {
  invoiceId?: string | number;
};

export const PaymentListTable: React.FC<PaymentListTableProps> = ({ invoiceId }) => {
  const [activeBranchAtom] = useAtom(ActiveBranchAtom);
  const [_, setLoading] = useAtom(useLoading);
  const [deleteModalData, setDeleteModalData] = useState<{
    show: boolean;
    data: EntityData | null;
  }>({
    show: false,
    data: null,
  });

  const [tableData, setTableData] = useState<{
    data: EntityData[];
    pagination: Pagination;
    loading: boolean;
    q: number | string | boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
    q: '',
  });

  const [recordCount, setRecordCount] = useState<{
    loading: boolean;
    count: number;
  }>({
    loading: false,
    count: 0,
  });

  const { isMounted } = useMounted();
  const navigate = useNavigate();
  const [refetchToken, setRefetch] = useState(0);

  const fetch = useCallback(
    (pagination: Pagination, query?: string) => {
      const activeBranch = getUserBranch();
      setTableData((tableData) => ({ ...tableData, loading: true }));

      const body: EntityRecordFilter = {
        page: (pagination.current || 1) - 1,
        limit: pagination.pageSize || 10,
        q:
          (invoiceId ? `invoice.id==${invoiceId}` : activeBranch ? `invoice.spk.vendor.id==${activeBranch.id}` : '') +
          (query ? `;${query}` : ''),
        sort: 'createdDate DESC',
      };
      if (!body.q) delete body['q'];
      getEntityRecords('tt_payment', body)
        .then((res) => {
          if (isMounted.current) {
            setTableData({
              data: res.records,
              pagination: {
                current: pagination.current,
                pageSize: pagination.pageSize,
              },
              loading: false,
              q: query || '',
            });
          }
        })
        .catch((err) => {
          console.error(`[Get Payment List Error] - ${err}`);
        });
    },
    [isMounted, refetchToken, activeBranchAtom],
  );

  const fetchCount = useCallback(
    (pagination: Pagination, query?: string) => {
      const activeBranch = getUserBranch();
      setRecordCount((recordCount) => ({ ...recordCount, loading: true }));

      getEntityRecordCount('tt_payment', {
        page: (pagination.current || 1) - 1,
        limit: pagination.pageSize || 10,
        q:
          (invoiceId ? `invoice.id==${invoiceId}` : activeBranch ? `invoice.spk.vendor.id==${activeBranch.id}` : '') +
          (query ? `;${query}` : ''),
      })
        .then((res) => {
          if (isMounted.current) {
            setRecordCount({
              count: res.count,
              loading: false,
            });
          }
        })
        .catch((err) => {
          console.error(`[Get Payment List Error] - ${err}`);
        });
    },
    [isMounted, refetchToken, activeBranchAtom],
  );

  useEffect(() => {
    fetch(initialPagination);
    fetchCount(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
    if (filters.code) {
      fetchCount(pagination, `code=ilike=${filters.code}`);
      fetch(pagination, `code=ilike=${filters.code}`);
    }
    fetch(pagination);
  };

  const handleDeleteRow = (row: EntityData | null) => {
    setDeleteModalData({
      show: false,
      data: null,
    });

    setLoading({
      isLoading: true,
      message: 'Mohon menunggu...',
    });

    deleteEntity('tt_payment', row?.id || '')
      .then(() => {
        notificationController.success({ message: 'Sukses menghapus pembayaran' });
        setRefetch(refetchToken + 1);
      })
      .catch((err) => {
        console.error(`[Delete Payment Error] - ${err}`);
        notificationController.error({ message: 'Gagal menghapus pembayaran' });
      })
      .finally(() => {
        setLoading({
          isLoading: false,
        });
      });
  };

  const handleEditRow = (rowId: string | number) => {
    navigate(`/transaction/payment/${rowId}/update`);
  };

  // Advanced Search
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string): ColumnType<EntityData> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={''}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            Cari
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (!record[dataIndex]) return false;
      else
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase());
    },
  });

  const columns: ColumnsType<EntityData> = [
    {
      title: 'Kode',
      dataIndex: 'code',
      ...getColumnSearchProps('code'),
    },
    {
      title: 'Invoice',
      render: (text: string, record: EntityData) => {
        return (
          <Space>
            <Link to={`/transaction/invoice/${record.invoice_id}/update`} target="_blank">
              {record.invoice_code}
            </Link>
          </Space>
        );
      },
      ...getColumnSearchProps('invoice_code'),
    },
    {
      title: 'Total',
      render: (text: string, record: EntityData) => {
        return <>{formatRupiahPrice(record.total || 0)}</>;
      },
    },
    {
      title: 'Dibuat pada',
      render: (text: string, record: EntityData) => {
        return record.created_date ? formatFullDate(record.created_date) : '';
      },
    },
    {
      title: 'Terakhir diubah',
      render: (text: string, record: EntityData) => {
        return record.updated_date ? formatFullDate(record.updated_date) : '';
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      width: '5%',
      render: (_, record: EntityData) => {
        return (
          <Space
            direction="vertical"
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <EditTwoTone onClick={() => handleEditRow(record.id)} style={{ fontSize: '20px' }} />
            <DeleteOutlined
              onClick={() => setDeleteModalData({ show: true, data: record })}
              style={{
                fontSize: '20px',
                color: 'red',
              }}
            />
          </Space>
        );
      },
    },
  ];

  /**
   * Since the data supplied has 2 or more level, need to masks into 1 level
   */
  const maskingData = (data: EntityData[]) => {
    const maskData = data.map((eachData: EntityData) => {
      const newData = {
        ...eachData,
        spk_id: eachData.invoice.spk.id,
        spk_code: eachData.invoice.spk.code,
        invoice_id: eachData.invoice.id,
        invoice_code: eachData.invoice.code,
      };
      return newData;
    });

    return maskData;
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={maskingData(tableData.data)}
        pagination={{ ...tableData.pagination, total: recordCount.count }}
        loading={tableData.loading || recordCount.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      <Modal
        title="Konfirmasi"
        visible={deleteModalData.show}
        onOk={() => handleDeleteRow(deleteModalData.data)}
        onCancel={() => setDeleteModalData({ show: false, data: deleteModalData.data })}
      >
        Apakah yakin ingin menghapus {deleteModalData.data?.name}?
      </Modal>
    </>
  );
};
