import React, { useEffect } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { LinkButton } from '@app/components/common/buttons/Button/Button';
import { DashboardOutlined, PlusOutlined } from '@ant-design/icons';
import { UOMListTable } from '@app/components/tables/master/uom/UomListTable';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Space } from 'antd';
import { checkPagePermission, checkPermission } from '@app/utils/utils';
import { useNavigate } from 'react-router-dom';

const ListUOMPage: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'c_uom',
          action: 'READ',
        },
      ])
    )
      navigate('/');
  }, []);
  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="#">UOM</BreadcrumbItem>
      </Breadcrumb>
      <Card
        title="UOM"
        padding="1.25rem 1.25rem 0"
        extra={
          checkPermission('c_uom', 'CREATE') && (
            <LinkButton size="middle" icon={<PlusOutlined />} to="/master/uom/create">
              Tambah UOM
            </LinkButton>
          )
        }
      >
        <UOMListTable />
      </Card>
    </>
  );
};

export default ListUOMPage;
