import React, { useCallback, useEffect, useState } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Col, Row } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMounted } from '@app/hooks/useMounted';
import { useAtom } from 'jotai';
import { useLoading } from '@app/hooks/useLoading';
import {
  createEntityRecord,
  EntityData,
  EntityFieldsResponse,
  getEntityFields,
  getEntityRecord,
  getEntityRecords,
  updateEntityRecord,
} from '@app/api/master/entity.api';
import { SelectionForm } from '@app/components/forms/master/SelectionForm';
import { CurrencyInput } from '@app/components/forms/master/CurrencyInput';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Space } from 'antd';
import { DashboardOutlined } from '@ant-design/icons';
import { PercentageInput } from '@app/components/forms/master/PercentageInput';
import { NumberInput } from '@app/components/forms/master/NumberInput';
import { checkPagePermission, roundingPriceUp } from '@app/utils/utils';

const CreateProductQuotationPage: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isMounted } = useMounted();

  const [form] = BaseButtonsForm.useForm();
  const [refreshToken, setRefreshToken] = useState(0);
  const [_, setLoadingScreen] = useAtom(useLoading);
  const [metafield, setMetafield] = useState<EntityFieldsResponse>();
  const [initialData, setInitialData] = useState<EntityData>();

  const [quotationProducts, setQuotationProducts] = useState<EntityData[]>([]);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  useEffect(() => {
    form.setFieldValue('quotation', parseInt(query.get('quotation') || '0'));
    getAllProductQuotation();
  }, []);

  const recalculateSubtotal = () => {
    // calculate the rounding up
    const qty = form.getFieldValue('qty') || 0;
    const markup = form.getFieldValue('markup_percentage') || 0;
    const price = form.getFieldValue('price') || 0;
    const markupPrice = price + price * (markup / 100);
    const onePiecePrice = roundingPriceUp(markupPrice, 3);
    form.setFieldValue('markup_price', markupPrice);
    form.setFieldValue('rounded', onePiecePrice);
    form.setFieldValue('subtotal', onePiecePrice * qty);
  };

  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_product_quotation',
          action: 'CREATE',
        },
      ])
    )
      navigate('/');

    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    getEntityFields('tt_product_quotation')
      .then((res) => {
        setMetafield(res);
      })
      .catch((err) => {
        console.error(`[Error Get Product Quotation Fields] - ${err}`);
      })
      .finally(() => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  }, [refreshToken]);

  const onFinish = useCallback(
    (values: EntityData) => {
      // todo dispatch an action here
      setLoading(true);
      setTimeout(() => {
        setFieldsChanged(false);
        // call create country API

        const body: EntityData = {
          ...values,
          quotation: {
            id: values.quotation,
          },
          product: {
            id: values.product,
          },
        };
        delete body['markup_price'];

        const checkExistingProduct = checkQuotationProducts(body);
        if (checkExistingProduct) updateProductQuotation(body, checkExistingProduct);
        else createProductQuotation(body);
      }, 1000);
    },
    [quotationProducts],
  );

  const createProductQuotation = (body: EntityData) => {
    const tempBody = { ...body };
    if (tempBody.id) delete tempBody['id'];
    return createEntityRecord('tt_product_quotation', tempBody)
      .then((res) => {
        notificationController.success({ message: 'Sukses menambah produk penawaran' });
        navigate(`/transaction/quotation/${query.get('quotation')}/update`);
      })
      .catch((err) => {
        console.error(`[Create Product Quotation Error] - ${err}`);
        notificationController.error({ message: 'Gagal menambah produk penawaran' });
      });
  };

  const updateProductQuotation = (body: EntityData, existingProduct: EntityData) => {
    return updateEntityRecord('tt_product_quotation', existingProduct.id, {
      qty: existingProduct.qty + body.qty,
    })
      .then((res) => {
        // only do the real success if all the product is added
        notificationController.success({ message: 'Sukses menambah produk penawaran' });
        navigate(`/transaction/quotation/${query.get('quotation')}/update`);
      })
      .catch((err) => {
        console.error(`[Create Product Quotation Error] - ${err}`);
        notificationController.error({ message: 'Gagal menambah produk penawaran' });
      });
  };

  const changeProduct = (value: any) => {
    setFieldsChanged(true);

    // get the product data
    getProductDetail(value).then((value: EntityData) => {
      form.setFieldValue('price', value.price);
      recalculateSubtotal();
    });
  };

  const getProductDetail = (productId: string): Promise<any> => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    return getEntityRecord('tt_product', productId)
      .then((res) => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
        return res;
      })
      .catch((err) => {
        notificationController.error({
          message: 'Maaf terjadi kesalahan pada sistem. Silahkan coba beberapa saat lagi.',
        });
        console.error(`[Get Product Error] - ${err}`);
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
        return null;
      });
  };

  const getAllProductQuotation = useCallback(() => {
    getEntityRecords('tt_product_quotation', {
      page: 0,
      limit: 50,
      q: `quotation.id==${query.get('quotation') || ''}`,
      sort: 'product.id ASC',
      inf: true,
    })
      .then((res) => {
        setQuotationProducts(res.records);
      })
      .catch((err) => {
        console.error(`[Get Product Quotation List Error] - ${err}`);
      });
  }, []);

  const checkQuotationProducts = (product: EntityData): EntityData | null => {
    let existingProduct = null;
    quotationProducts.map((qProduct: EntityData) => {
      if (
        qProduct.product.id == product.product.id &&
        qProduct.markup_percentage == product.markup_percentage &&
        qProduct.price == product.price
      )
        existingProduct = { ...qProduct };
    });

    return existingProduct;
  };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="/transaction/quotation">Dokumen Penawaran</BreadcrumbItem>
        <BreadcrumbItem href={`/transaction/quotation/${query.get('quotation')}/update`}>Ubah</BreadcrumbItem>
        <BreadcrumbItem href={`?quotation=${query.get('quotation')}`}>Tambah Produk</BreadcrumbItem>
      </Breadcrumb>
      <Row gutter={[30, 30]}>
        {/* Main Data */}
        <Col xs={24} md={24}>
          <Card title="Data Produk Penawaran" padding="1.25rem">
            <BaseButtonsForm
              form={form}
              name="Tambah Produk Penawaran"
              isFieldsChanged={isFieldsChanged}
              onFieldsChange={() => setFieldsChanged(true)}
              setFieldsChanged={setFieldsChanged}
              onFinish={onFinish}
              loading={isLoading}
              initialValues={initialData}
            >
              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  <SelectionForm
                    entity="tt_quotation"
                    field="quotation"
                    form={form}
                    label="Dokumen Penawaran"
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.quotation}
                    optionLabel="code"
                    disabled
                  />
                </Col>
              </Row>

              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  {/* Product */}
                  <SelectionForm
                    entity="tt_product"
                    field="product"
                    form={form}
                    label="Produk"
                    onChange={(value) => changeProduct(value)}
                    metafield={metafield?.product}
                  />
                </Col>
              </Row>

              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  <BaseButtonsForm.Item
                    name="price"
                    label="Harga"
                    rules={[{ required: !metafield?.price.nullable, message: 'Harap masukkan harga' }]}
                  >
                    <CurrencyInput onChange={recalculateSubtotal} />
                  </BaseButtonsForm.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  <BaseButtonsForm.Item
                    name="markup_percentage"
                    label="Markup"
                    rules={[
                      {
                        required: !metafield?.markup_percentage.nullable,
                        message: 'Harap masukkan persentasi markup',
                      },
                    ]}
                  >
                    <PercentageInput onChange={recalculateSubtotal} />
                  </BaseButtonsForm.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  <BaseButtonsForm.Item name="markup_price" label="Harga Markup">
                    <CurrencyInput disabled />
                  </BaseButtonsForm.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  <BaseButtonsForm.Item
                    name="rounded"
                    label="Pembulatan"
                    rules={[{ required: !metafield?.rounded.nullable, message: 'Harap masukkan pembulatan' }]}
                  >
                    <CurrencyInput disabled />
                  </BaseButtonsForm.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  <BaseButtonsForm.Item
                    name="qty"
                    label="Kuantiti"
                    rules={[{ required: !metafield?.qty.nullable, message: 'Harap masukkan kuantiti' }]}
                  >
                    <NumberInput onChange={recalculateSubtotal} />
                  </BaseButtonsForm.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  <BaseButtonsForm.Item
                    name="subtotal"
                    label="Subtotal"
                    rules={[{ required: !metafield?.subtotal.nullable, message: 'Harap masukkan subtotal' }]}
                  >
                    <CurrencyInput disabled={true} />
                  </BaseButtonsForm.Item>
                </Col>
              </Row>
            </BaseButtonsForm>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreateProductQuotationPage;
