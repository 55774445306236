import { DashboardOutlined } from '@ant-design/icons';
import { EntityData, EntityFieldsResponse, createEntityRecord, getEntityFields } from '@app/api/master/entity.api';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Card } from '@app/components/common/Card/Card';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { DatePicker } from '@app/components/common/pickers/DatePicker';
import { SelectionForm } from '@app/components/forms/master/SelectionForm';
import { notificationController } from '@app/controllers/notificationController';
import { useLoading } from '@app/hooks/useLoading';
import { useMounted } from '@app/hooks/useMounted';
import { checkPagePermission } from '@app/utils/utils';
import { Col, Row, Space } from 'antd';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CreateVendorPaymentPage: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isMounted } = useMounted();

  const [form] = BaseButtonsForm.useForm();

  const [refreshToken, setRefreshToken] = useState(0);
  const [_, setLoadingScreen] = useAtom(useLoading);
  const [metafield, setMetafield] = useState<EntityFieldsResponse>();

  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_vendor_payment',
          action: 'CREATE',
        },
      ])
    )
      navigate('/');

    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    getEntityFields('tt_vendor_payment')
      .then((res) => {
        setMetafield(res);
      })
      .catch((err) => {
        console.error(`[Error Get Vendor Payment Fields] - ${err}`);
      })
      .finally(() => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  }, [refreshToken]);

  const onFinish = useCallback((values: EntityData) => {
    // todo dispatch an action here
    setLoading(true);
    setTimeout(() => {
      setFieldsChanged(false);
      createEntityRecord('tt_vendor_payment', {
        ...values,
        spk: {
          id: values.spk,
        },
        product: {
          id: values.product,
        },
        vendor: {
          id: values.vendor,
        },
      })
        .then((res) => {
          notificationController.success({ message: 'Sukses menambah pembayaran vendor' });
          navigate(`/master/vendor-payment/${res.id}/update`);
        })
        .catch((err) => {
          console.error(`[Error Create Vendor Payment] - ${err}`);
          notificationController.error({ message: 'Gagal menambah pembayaran vendor' });
        })
        .finally(() => {
          if (isMounted.current) {
            setLoading(false);
          }
        });
    }, 1000);
  }, []);

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="/master/vendor-payment">Pembayaran Vendor</BreadcrumbItem>
        <BreadcrumbItem href="#">Tambah Pembayaran</BreadcrumbItem>
      </Breadcrumb>
      <Row gutter={[30, 30]}>
        {/* Main Data */}
        <Col xs={24} md={24}>
          <Card title="Data Pembayaran Vendor" padding="1.25rem">
            <BaseButtonsForm
              form={form}
              name="Tambah Pembayaran Vendor"
              isFieldsChanged={isFieldsChanged}
              onFieldsChange={() => setFieldsChanged(true)}
              setFieldsChanged={setFieldsChanged}
              onFinish={onFinish}
              loading={isLoading}
            >
              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  <SelectionForm
                    entity="tt_spk"
                    label="SPK"
                    field="spk"
                    form={form}
                    optionLabel="code"
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.spk}
                  />
                  <BaseButtonsForm.Item name="installation_date" label="Tanggal Instalasi">
                    <DatePicker
                      disabled={true}
                      format="DD-MM-YYYY"
                      style={{ width: '100%' }}
                      placeholder="Pilih tanggal"
                    />
                  </BaseButtonsForm.Item>
                </Col>
                <Col xs={24} md={12}>
                  <SelectionForm
                    entity="tt_vendor"
                    label="Vendor"
                    field="vendor"
                    form={form}
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.vendor}
                  />
                </Col>
                {/*<Col xs={24} md={12}>*/}
                {/*  <BaseButtonsForm.Item*/}
                {/*    name="payment"*/}
                {/*    label="Pembayaran"*/}
                {/*    rules={[{ required: !metafield?.payment.nullable, message: 'Harap masukkan pembayaran' }]}*/}
                {/*  >*/}
                {/*    <CurrencyInput />*/}
                {/*  </BaseButtonsForm.Item>*/}
                {/*</Col>*/}
              </Row>
            </BaseButtonsForm>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreateVendorPaymentPage;
