import { Category, Pkg, Uom } from '@app/domain/_shared';
import { TERMedia } from '@app/domain/Media';

export enum VendorPaymentType {
  Survey = 'SURVEY',
  Template = 'TEMPLATE',
  FetchMedia = 'fetch_media',
}

export interface VendorPayment {
  id: number;
  created_date: string;
  updated_date: string;
  created_by_id: number;
  updated_by_id: number;
  code: string;
  installation_type: string;
  spk?: Spk;
  vendor: Vendor;
  technician?: Technician;
  installation_date: string;
  payments: VendorPaymentItem[];
  media: TERMedia[];
  state: string;
  total?: number;
}

export interface VendorPaymentItem {
  id: number;
  created_date: string;
  updated_date: string;
  created_by_id: number;
  updated_by_id: number;
  vendor_payment: VendorPayment;
  product: Product;
  package_product: PackageProduct;
  margin: number;
  qty: number;
  subtotal: number;
}

export interface Spk {
  id: number;
  created_date: string;
  updated_date: string;
  created_by_id: number;
  updated_by_id: number;
  code: string;
  date: string;
  installation_date: string;
  type: string;
  admin: Admin;
  vendor: Vendor;
  technician: Technician;
  sales: Sales;
  customer: Customer;
  customer_email?: string;
  address: string;
  city: City;
  postal_code?: string;
  longitude?: number;
  latitude?: number;
  phone?: string;
  mobile_phone: string;
  offer?: Offer;
  status: string;
  status_string: string;
  total: number;
}

export interface Admin {
  id: number;
  created_date: string;
  updated_date: string;
  created_by_id: number;
  updated_by_id: number;
  nik: string;
  name: string;
  mobile_phone: string;
  gender: string;
  user: User;
  phone: string;
  email: string;
  date_of_birth?: string;
  profile_picture?: string;
}

export interface User {
  id: number;
  created_date: string;
  updated_date: string;
  created_by_id: number;
  updated_by_id: number;
  username: string;
  name: string;
  root: boolean;
  admin_id: number;
  sales_id?: string;
  vendor_id?: string;
  technician_id?: string;
  type: string;
}

export interface Customer {
  id: number;
  created_date: string;
  updated_date: string;
  created_by_id: number;
  updated_by_id: number;
  name: string;
  address: string;
  city: City;
  mobile_phone: string;
  email: string;
  postal_code?: string;
  longitude?: number;
  latitude?: number;
  phone?: string;
  gender: string;
  date_of_birth?: string;
}

export interface Vendor {
  id: number;
  created_date: string;
  updated_date: string;
  created_by_id: number;
  updated_by_id: number;
  name: string;
  code: string;
  mobile_phone: string;
  address: string;
  city: City;
  phone?: string;
  email: string;
  postal_code?: string;
  longitude?: number;
  latitude?: number;
  profile_picture?: string;
  branch: Branch;
  user: User;
}

export interface Technician {
  id: number;
  created_date: string;
  updated_date: string;
  created_by_id: number;
  updated_by_id: number;
  nik: string;
  name: string;
  mobile_phone: string;
  gender: string;
  date_of_birth?: string;
  vendor: Vendor;
  user: User;
  phone?: string;
  email: string;
  profile_picture?: string;
}

export interface City {
  id: number;
  created_date: string;
  updated_date: string;
  created_by_id: number;
  updated_by_id: number;
  province: Province;
  name: string;
}

export interface Province {
  id: number;
  created_date: string;
  updated_date: string;
  created_by_id: number;
  updated_by_id: number;
  country: Country;
  name: string;
}

export interface Country {
  id: number;
  created_date: string;
  updated_date: string;
  created_by_id: number;
  updated_by_id: number;
  name: string;
  abbr: string;
}

export interface Branch {
  id: number;
  created_date: string;
  updated_date: string;
  created_by_id: number;
  updated_by_id: number;
  name: string;
  code: string;
  address: string;
  city: City;
  phone: string;
  postal_code?: string;
  longitude?: number;
  latitude?: number;
}

export interface Branch {
  id: number;
  created_date: string;
  updated_date: string;
  created_by_id: number;
  updated_by_id: number;
  name: string;
  code: string;
  address: string;
  city: City;
  phone: string;
  postal_code?: string;
  longitude?: number;
  latitude?: number;
}

export interface Sales {
  id: number;
  created_date: string;
  updated_date: string;
  created_by_id: number;
  updated_by_id: number;
  nik: string;
  name: string;
  mobile_phone: string;
  gender: string;
  branch: Branch;
  user: User;
  phone?: string;
  email: string;
  date_of_birth?: string;
  profile_picture?: string;
}

export interface Offer {
  id: number;
  created_date: string;
  updated_date: string;
  created_by_id: number;
  updated_by_id: number;
  code: string;
  date: string;
  survey_date: string;
  admin: Admin;
  vendor: Vendor;
  technician: Technician;
  sales: Sales;
  customer: Customer;
  customer_email?: string;
  address: string;
  city: City;
  postal_code?: string;
  longitude?: number;
  latitude?: number;
  phone?: string;
  mobile_phone: string;
  status: string;
  status_string: string;
  total: number;
  spk_id: number;
  spk_code: string;
}

export interface PackageProduct {
  id: number;
  created_date: string;
  updated_date: string;
  created_by_id: number;
  updated_by_id: number;
  pkg: Pkg;
  product: Product;
  price: number;
}

export interface Product {
  id: number;
  created_date: string;
  updated_date: string;
  created_by_id: number;
  updated_by_id: number;
  name: string;
  price: number;
  uom: Uom;
  category: Category;
  is_product_unit: boolean;
}
