import React, { useCallback, useEffect, useState } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Col, Row } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useLoading } from '@app/hooks/useLoading';
import {
  createEntityRecord,
  EntityData,
  EntityFieldsResponse,
  getEntityRecord,
  EntityRecordRequest,
  getEntityRecords,
  updateEntityRecord,
} from '@app/api/master/entity.api';
import { SelectionForm } from '@app/components/forms/master/SelectionForm';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Space } from 'antd';
import { DashboardOutlined } from '@ant-design/icons';
import { EditableSPKProductListTable } from '@app/components/tables/master/spk_product/EditableSPKProductListTable';
import { SPKTabData } from '../spk/UpdateSPKPage';
import { checkPagePermission } from '@app/utils/utils';

const CreateProductPackageSPKPage: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const navigate = useNavigate();

  const [form] = BaseButtonsForm.useForm();
  const [_, setLoadingScreen] = useAtom(useLoading);
  const [productData, setProductData] = useState<EntityData[]>();
  const [packageData, setPackageData] = useState<EntityData>();

  const [isEditing, setIsEditing] = useState(false);
  const [loadingFinish, setLoadingFinish] = useState(0);
  const [SPKProducts, setSPKProducts] = useState<EntityData[]>([]);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_spk_product',
          action: 'CREATE',
        },
      ])
    )
      navigate('/');

    form.setFieldValue('spk', parseInt(query.get('spk') || '0'));
    getAllProductSPK();
  }, []);

  const onFinish = useCallback(
    (values: EntityData) => {
      setLoadingScreen({
        isLoading: true,
        message: 'Mohon menunggu...',
      });
      setLoadingFinish(productData?.length || 0);

      setFieldsChanged(false);
      //need to do it one by one
      productData?.forEach(async (product: EntityData) => {
        const body: EntityRecordRequest = {
          ...product,
          spk: {
            id: query.get('spk') || '',
          },
          product: {
            id: product.product.id,
          },
          pkg: {
            id: product.pkg.id,
          },
        };
        const existingSPKProducts = checkSPKProducts(body);

        if (existingSPKProducts) await updateProductSPK(body, existingSPKProducts);
        else await createSPKProduct(body);
        setLoadingFinish(loadingFinish - 1);
      });
      setLoadingScreen({
        isLoading: false,
        message: 'Mohon menunggu...',
      });
    },
    [productData, SPKProducts],
  );

  const createSPKProduct = (body: EntityData) => {
    const tempBody = { ...body };
    if (tempBody.id) delete tempBody['id'];
    return createEntityRecord('tt_spk_product', tempBody)
      .then((res) => {
        // only do the real success if all the product is added
        if (loadingFinish === 0) {
          notificationController.success({ message: 'Sukses menambah produk SPK' });
          navigate(`/transaction/spk/${query.get('spk')}/update?tab=${SPKTabData.PRODUCT}`);
        }
      })
      .catch((err) => {
        console.error(`[Create Product SPK Error] - ${err}`);
        notificationController.error({ message: 'Gagal menambah produk SPK' });
      });
  };

  const updateProductSPK = (body: EntityData, existingProduct: EntityData) => {
    return updateEntityRecord('tt_spk_product', existingProduct.id, {
      ...body,
      qty: existingProduct.qty + body.qty,
    })
      .then((res) => {
        // only do the real success if all the product is added
        if (loadingFinish === 0) {
          notificationController.success({ message: 'Sukses menambah produk SPK' });
          navigate(`/transaction/spk/${query.get('spk')}/update`);
        }
      })
      .catch((err) => {
        console.error(`[Create Product SPK Error] - ${err}`);
        notificationController.error({ message: 'Gagal menambah produk SPK' });
      });
  };

  // cek kalau qtynya 0, berarti ini unique, buat product spk baru
  // kalau qtynya > 0, update qty existing
  const checkSPKProducts = (product: EntityData): EntityData | null => {
    let existingProduct = null;
    SPKProducts.map((sProduct: EntityData) => {
      if (
        sProduct.product.id == product.product.id &&
        sProduct.price == product.price &&
        ((product.pkg && sProduct.pkg && product.pkg.id == sProduct.pkg.id) || !product.pkg == !sProduct.pkg)
      )
        existingProduct = { ...sProduct };
    });

    return existingProduct;
  };

  const changePackage = (value: any) => {
    setFieldsChanged(true);

    getPackageDetail(value).then((res) => {
      setPackageData(res);
    });
  };

  const getPackageDetail = (packageId: string): Promise<any> => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    return getEntityRecord('tt_package', packageId)
      .then((res) => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
        return res;
      })
      .catch((err) => {
        notificationController.error({
          message: 'Maaf terjadi kesalahan pada sistem. Silahkan coba beberapa saat lagi.',
        });
        console.error(`[Get Package Error] - ${err}`);
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
        return null;
      });
  };

  const productChanges = (data: EntityData[]) => {
    setFieldsChanged(true);
    setProductData(data);
  };

  const getAllProductSPK = useCallback(() => {
    getEntityRecords('tt_spk_product', {
      page: 0,
      limit: 50,
      q: `spk.id==${query.get('spk') || ''}`,
      sort: 'product.id ASC',
      inf: true,
    })
      .then((res) => {
        setSPKProducts(res.records);
      })
      .catch((err) => {
        console.error(`[Get Product SPK List Error] - ${err}`);
      });
  }, []);

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="/transaction/spk">SPK</BreadcrumbItem>
        <BreadcrumbItem href={`/transaction/spk/${query.get('spk')}/update?tab=${SPKTabData.PRODUCT}`}>
          Ubah
        </BreadcrumbItem>
        <BreadcrumbItem href={`?spk=${query.get('spk')}`}>Tambah Produk</BreadcrumbItem>
      </Breadcrumb>
      <Card title="Data Paket SPK" padding="1.25rem">
        <BaseButtonsForm
          isFieldsChanged={isFieldsChanged && !isEditing}
          onFieldsChange={() => setFieldsChanged(true)}
          setFieldsChanged={setFieldsChanged}
          onFinish={onFinish}
        >
          <Row gutter={{ xs: 10, md: 15 }}>
            <Col xs={24} md={12}>
              <SelectionForm
                entity="tt_package"
                field="package"
                form={form}
                label="Paket"
                onChange={(value) => changePackage(value)}
              />
            </Col>
            <Col xs={24}>
              {packageData && (
                <EditableSPKProductListTable
                  packageId={packageData.id || ''}
                  onChange={(data) => productChanges(data)}
                  setIsEditing={setIsEditing}
                />
              )}
            </Col>
          </Row>
        </BaseButtonsForm>
      </Card>
    </>
  );
};

export default CreateProductPackageSPKPage;
