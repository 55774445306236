import { DashboardOutlined } from '@ant-design/icons';
import {
  EntityData,
  EntityFieldsResponse,
  createEntityRecord,
  getEntityFields,
  getEntityRecord,
  getEntityRecords,
  updateEntityRecord,
} from '@app/api/master/entity.api';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Card } from '@app/components/common/Card/Card';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { CurrencyInput } from '@app/components/forms/master/CurrencyInput';
import { NumberInput } from '@app/components/forms/master/NumberInput';
import { SelectionForm } from '@app/components/forms/master/SelectionForm';
import { notificationController } from '@app/controllers/notificationController';
import { useLoading } from '@app/hooks/useLoading';
import { useMounted } from '@app/hooks/useMounted';
import { checkPagePermission } from '@app/utils/utils';
import { Col, Row, Space } from 'antd';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SPKTabData } from '../spk/UpdateSPKPage';

const CreateProductSPKPage: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isMounted } = useMounted();

  const [form] = BaseButtonsForm.useForm();
  const [refreshToken, setRefreshToken] = useState(0);
  const [_, setLoadingScreen] = useAtom(useLoading);
  const [metafield, setMetafield] = useState<EntityFieldsResponse>();
  const [initialData, setInitialData] = useState<EntityData>();
  const [SPKProducts, setSPKProducts] = useState<EntityData[]>([]);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_spk_product',
          action: 'CREATE',
        },
      ])
    )
      navigate('/');

    form.setFieldValue('spk', parseInt(query.get('spk') || '0'));
    getAllProductSPK();
  }, []);

  const recalculateSubtotal = () => {
    // calculate the rounding up
    const qty = form.getFieldValue('qty') || 0;
    const price = form.getFieldValue('price') || 0;
    form.setFieldValue('subtotal', price * qty);
  };

  useEffect(() => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    getEntityFields('tt_spk_product')
      .then((res) => {
        setMetafield(res);
      })
      .catch((err) => {
        console.error(`[Error Get Product SPK Fields] - ${err}`);
      })
      .finally(() => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  }, [refreshToken]);

  const onFinish = useCallback(
    (values: EntityData) => {
      // todo dispatch an action here
      setLoading(true);
      setTimeout(async () => {
        setFieldsChanged(false);
        // call create country API

        const body: EntityData = {
          ...values,
          spk: {
            id: values.spk,
          },
          product: {
            id: values.product,
          },
          commission: 0, // TODO: calculate the commission
        };
        if (values.pkg) body['pkg'] = { id: values.pkg };

        const checkExistingProduct = checkSPKProducts(body);
        if (checkExistingProduct) updateProductSPK(body, checkExistingProduct);
        else await createProductSPK(body);
      }, 1000);
    },
    [SPKProducts],
  );

  const createProductSPK = (body: EntityData) => {
    const tempBody = { ...body };
    if (tempBody.id) delete tempBody['id'];
    console.log(tempBody);
    return createEntityRecord('tt_spk_product', tempBody)
      .then((res) => {
        notificationController.success({ message: 'Sukses menambah produk spk' });
        navigate(`/transaction/spk/${query.get('spk')}/update`);
      })
      .catch((err) => {
        console.error(`[Create Product SPK Error] - ${err}`);
        notificationController.error({ message: 'Gagal menambah produk spk' });
      });
  };

  const updateProductSPK = (body: EntityData, existingProduct: EntityData) => {
    return updateEntityRecord('tt_spk_product', existingProduct.id, {
      ...body,
      qty: existingProduct.qty + body.qty,
    })
      .then((res) => {
        // only do the real success if all the product is added
        notificationController.success({ message: 'Sukses menambah produk spk' });
        navigate(`/transaction/spk/${query.get('spk')}/update`);
      })
      .catch((err) => {
        console.error(`[Create Product SPK Error] - ${err}`);
        notificationController.error({ message: 'Gagal menambah produk spk' });
      });
  };

  const changeProduct = (value: any) => {
    setFieldsChanged(true);

    // get the product data
    getProductDetail(value).then((value: EntityData) => {
      form.setFieldValue('price', value.price);
      recalculateSubtotal();
    });
  };

  const getProductDetail = (productId: string): Promise<any> => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    return getEntityRecord('tt_product', productId)
      .then((res) => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
        return res;
      })
      .catch((err) => {
        notificationController.error({
          message: 'Maaf terjadi kesalahan pada sistem. Silahkan coba beberapa saat lagi.',
        });
        console.error(`[Get Product Error] - ${err}`);
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
        return null;
      });
  };

  const getAllProductSPK = useCallback(() => {
    getEntityRecords('tt_spk_product', {
      page: 0,
      limit: 50,
      q: `spk.id==${query.get('spk') || ''}`,
      sort: 'product.id ASC',
      inf: true,
    })
      .then((res) => {
        setSPKProducts(res.records);
      })
      .catch((err) => {
        console.error(`[Get Product SPK List Error] - ${err}`);
      });
  }, []);

  const checkSPKProducts = (product: EntityData): EntityData | null => {
    let existingProduct = null;
    SPKProducts.map((sProduct: EntityData) => {
      if (
        sProduct.product.id == product.product.id &&
        sProduct.price == product.price &&
        ((product.pkg && sProduct.pkg && product.pkg.id == sProduct.pkg.id) || !product.pkg == !sProduct.pkg)
      )
        existingProduct = { ...sProduct };
    });

    return existingProduct;
  };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="/transaction/spk">SPK</BreadcrumbItem>
        <BreadcrumbItem href={`/transaction/spk/${query.get('spk')}/update?tab=${SPKTabData.PRODUCT}`}>
          Ubah
        </BreadcrumbItem>
        <BreadcrumbItem href={`?spk=${query.get('spk')}`}>Tambah Produk</BreadcrumbItem>
      </Breadcrumb>
      <Row gutter={[30, 30]}>
        {/* Main Data */}
        <Col xs={24} md={24}>
          <Card title="Data SPK" padding="1.25rem">
            <BaseButtonsForm
              form={form}
              name="Tambah SPK"
              isFieldsChanged={isFieldsChanged}
              onFieldsChange={() => setFieldsChanged(true)}
              setFieldsChanged={setFieldsChanged}
              onFinish={onFinish}
              loading={isLoading}
              initialValues={initialData}
            >
              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  <SelectionForm
                    entity="tt_spk"
                    field="spk"
                    form={form}
                    label="SPK"
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.spk}
                    optionLabel="code"
                    disabled
                  />
                </Col>
              </Row>

              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  {/* Package */}
                  <SelectionForm
                    entity="tt_package"
                    field="pkg"
                    form={form}
                    label="Paket"
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.pkg}
                  />
                </Col>
              </Row>

              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  {/* Product */}
                  <SelectionForm
                    entity="tt_product"
                    field="product"
                    form={form}
                    label="Produk"
                    onChange={(value) => changeProduct(value)}
                    metafield={metafield?.product}
                  />
                </Col>
              </Row>

              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  <BaseButtonsForm.Item
                    name="qty"
                    label="Kuantiti"
                    rules={[{ required: !metafield?.qty.nullable, message: 'Harap masukkan kuantiti' }]}
                  >
                    <NumberInput onChange={recalculateSubtotal} />
                  </BaseButtonsForm.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  <BaseButtonsForm.Item
                    name="price"
                    label="Harga"
                    rules={[{ required: !metafield?.price.nullable, message: 'Harap masukkan harga' }]}
                  >
                    <CurrencyInput onChange={recalculateSubtotal} />
                  </BaseButtonsForm.Item>
                </Col>
              </Row>

              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  <BaseButtonsForm.Item
                    name="subtotal"
                    label="Subtotal"
                    rules={[{ required: !metafield?.subtotal.nullable, message: 'Harap masukkan subtotal' }]}
                  >
                    <CurrencyInput disabled={true} />
                  </BaseButtonsForm.Item>
                </Col>
              </Row>
            </BaseButtonsForm>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreateProductSPKPage;
