import { DashboardOutlined, PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Card } from '@app/components/common/Card/Card';
import { LinkButton } from '@app/components/common/buttons/Button/Button';
import VendorPaymentListTable from '@app/components/tables/master/vendor_payment/VendorPaymentListTable';
import { checkPagePermission, checkPermission } from '@app/utils/utils';
import { Col, Row, Space } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ListVendorPaymentPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_vendor_payment',
          action: 'READ',
        },
      ])
    )
      navigate('/');
  }, []);

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="#">Pembayaran Vendor</BreadcrumbItem>
      </Breadcrumb>
      <Card
        title="Pembayaran Vendor"
        padding="1.25rem 1.25rem 0"
        extra={
          checkPermission('tt_vendor_payment', 'CREATE') &&
          checkPermission('tt_vendor_payment', 'CREATE') && (
            <Row gutter={16}>
              <Col>
                <LinkButton size="middle" icon={<PlusOutlined />} to="/master/vendor-payment/create/bulk">
                  Tambah Bulk
                </LinkButton>
              </Col>
              <Col>
                <LinkButton size="middle" icon={<PlusOutlined />} to="/master/vendor-payment/create">
                  Tambah Pembayaran Vendor
                </LinkButton>
              </Col>
            </Row>
          )
        }
      >
        <VendorPaymentListTable />
      </Card>
    </>
  );
};

export default ListVendorPaymentPage;
