import React, { useCallback, useEffect, useState } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Col, Row } from 'antd';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { useMounted } from '@app/hooks/useMounted';
import { UserForm } from '@app/components/forms/master/User/UserForm';
import { PersonalInfoForm } from '@app/components/forms/master/Personal/PersonalInfoForm';
import dayjs from 'dayjs';
import {
  changeUserPassword,
  createEntityRecord,
  EntityData,
  EntityFieldsResponse,
  EntityRecordRequest,
  getEntityFields,
} from '@app/api/master/entity.api';
import { useAtom } from 'jotai';
import { useLoading } from '@app/hooks/useLoading';
import { SelectionForm } from '@app/components/forms/master/SelectionForm';
import { DashboardOutlined } from '@ant-design/icons';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Space } from 'antd';
import { checkPagePermission } from '@app/utils/utils';

const CreateTechnicianPage: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isMounted } = useMounted();

  const [form] = BaseButtonsForm.useForm();
  const [refreshToken, setRefreshToken] = useState(0);
  const [_, setLoadingScreen] = useAtom(useLoading);
  const [metafield, setMetafield] = useState<EntityFieldsResponse>();

  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_technician',
          action: 'CREATE',
        },
        {
          entity: 's_user',
          action: 'CREATE',
        },
      ])
    )
      navigate('/');

    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    getEntityFields('tt_technician')
      .then((res) => {
        setMetafield(res);
      })
      .catch((err) => {
        console.error(`[Error Get Technician Fields] - ${err}`);
      })
      .finally(() => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  }, [refreshToken]);

  const onFinish = useCallback((values: EntityData) => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    setTimeout(() => {
      setFieldsChanged(false);

      createUser(values, (userId) => {
        // call create technician API
        const params: EntityRecordRequest = {
          ...values,
          vendor: {
            id: values.vendor,
          },
          user: {
            id: userId,
          },
        };

        delete params['username'];
        delete params['password'];
        if (values.date_of_birth) params['date_of_birth'] = dayjs(values.date_of_birth).format('YYYY-MM-DD 00:00:00');

        createEntityRecord('tt_technician', params)
          .then((res) => {
            notificationController.success({ message: 'Sukses menambah teknisi' });
            navigate(`/master/technician/${res.id}/update`);
          })
          .catch((err) => {
            console.error(`[Create Technician Error] - ${err}`);
            notificationController.error({ message: 'Gagal menambah teknisi' });
          })
          .finally(() => {
            if (isMounted.current) {
              setLoading(false);
              setLoadingScreen({
                isLoading: false,
                message: 'Mohon menunggu...',
              });
            }
          });
      });
    }, 1000);
  }, []);
  const createUser = (values: EntityData, onSuccess: (userId: string) => void) => {
    createEntityRecord('s_user', {
      username: values.username,
      password: values.password,
      name: values.name,
    })
      .then((res) => onSuccess(res.id))
      .catch((err) => {
        console.error(`[Create User Technician Error] - ${err}`);
        notificationController.error({ message: 'Gagal menambah teknisi' });
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  };
  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="/master/technician">Teknisi</BreadcrumbItem>
        <BreadcrumbItem href="#">Tambah</BreadcrumbItem>
      </Breadcrumb>
      <Row gutter={[30, 30]}>
        {/* Main Data */}
        <Col xs={24} md={24}>
          <Card title="Data Teknisi" padding="1.25rem">
            <BaseButtonsForm
              form={form}
              name="Tambah Teknisi"
              isFieldsChanged={isFieldsChanged}
              onFieldsChange={() => setFieldsChanged(true)}
              setFieldsChanged={setFieldsChanged}
              onFinish={onFinish}
              loading={isLoading}
            >
              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  {/* Admin */}
                  <BaseButtonsForm.Item
                    name="name"
                    label="Nama Teknisi"
                    rules={[
                      {
                        required: !metafield?.name.nullable,
                        message: 'Harap masukkan nama teknisi',
                        type: 'string',
                      },
                    ]}
                  >
                    <Input />
                  </BaseButtonsForm.Item>
                </Col>

                <Col xs={24} md={12}>
                  <BaseButtonsForm.Item
                    name="nik"
                    label="NIK"
                    rules={[
                      { required: !metafield?.nik.nullable, message: 'Harap masukkan nomor NIK', type: 'string' },
                    ]}
                  >
                    <Input />
                  </BaseButtonsForm.Item>
                </Col>
              </Row>

              <PersonalInfoForm metafield={metafield} />

              <BaseButtonsForm.Item>
                <BaseButtonsForm.Title>Autentikasi</BaseButtonsForm.Title>
              </BaseButtonsForm.Item>

              <UserForm />

              <BaseButtonsForm.Item>
                <BaseButtonsForm.Title>Vendor</BaseButtonsForm.Title>
              </BaseButtonsForm.Item>

              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  <SelectionForm
                    entity="tt_vendor"
                    label="Vendor"
                    field="vendor"
                    form={form}
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.vendor}
                  />
                </Col>
              </Row>
            </BaseButtonsForm>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreateTechnicianPage;
