import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import LoginPage from '@app/pages/LoginPage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import SignUpPage from '@app/pages/SignUpPage';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';

import { DashboardPage } from '@app/pages/DashboardPages/DashboardPage';

// Branch
import CreateBranchPage from '@app/pages/system/branch/CreateBranchPage';
import ListBranchPage from '@app/pages/system/branch/ListBranchPage';
const UpdateBranchPage = React.lazy(() => import('@app/pages/system/branch/UpdateBranchPage'));
const UpdateBranch = withLoading(UpdateBranchPage);

// Admin
import CreateAdminPage from '@app/pages/system/admin/CreateAdminPage';
import ListAdminPage from '@app/pages/system/admin/ListAdminPage';
import CreateAdminBranchPage from '@app/pages/system/admin_branch/CreateAdminBranchPage';
const UpdateAdminPage = React.lazy(() => import('@app/pages/system/admin/UpdateAdminPage'));
const UpdateAdmin = withLoading(UpdateAdminPage);

// Customer
import CreateCustomerPage from '@app/pages/system/customer/CreateCustomerPage';
import ListCustomerPage from '@app/pages/system/customer/ListCustomerPage';
const UpdateCustomerPage = React.lazy(() => import('@app/pages/system/customer/UpdateCustomerPage'));
const UpdateCustomer = withLoading(UpdateCustomerPage);

// Vendor
import CreateVendorPage from '@app/pages/system/vendor/CreateVendorPage';
import ListVendorPage from '@app/pages/system/vendor/ListVendorPage';
const UpdateVendorPage = React.lazy(() => import('@app/pages/system/vendor/UpdateVendorPage'));
const UpdateVendor = withLoading(UpdateVendorPage);

// Technician
import CreateTechnicianPage from '@app/pages/system/technician/CreateTechnicianPage';
import ListTechnicianPage from '@app/pages/system/technician/ListTechnicianPage';
const UpdateTechnicianPage = React.lazy(() => import('@app/pages/system/technician/UpdateTechnicianPage'));
const UpdateTechnician = withLoading(UpdateTechnicianPage);

// UOM
import CreateUOMPage from '@app/pages/system/uom/CreateUOMPage';
import ListUOMPage from '@app/pages/system/uom/ListUOMPage';
const UpdateUOMPage = React.lazy(() => import('@app/pages/system/uom/UpdateUOMPage'));
const UpdateUOM = withLoading(UpdateUOMPage);

// product
import CreateProductPage from '@app/pages/system/product/CreateProductPage';
import ListProductPage from '@app/pages/system/product/ListProductPage';
const UpdateProductPage = React.lazy(() => import('@app/pages/system/product/UpdateProductPage'));
const UpdateProduct = withLoading(UpdateProductPage);

// package
import CreatePackagePage from '@app/pages/system/package/CreatePackagePage';
import ListPackagePage from '@app/pages/system/package/ListPackagePage';
const UpdatePackagePage = React.lazy(() => import('@app/pages/system/package/UpdatePackagePage'));
const UpdatePackage = withLoading(UpdatePackagePage);

import CreatePackageProductPage from '@app/pages/system/package_product/CreatePackageProductPage';
import CreateVendorSpecializationPage from '@app/pages/system/vendor_specialization/CreateVendorSpecializationPage';
import TermAndConditionPage from '@app/pages/TermAndConditionPage';

// specialization
import CreateSpecializationPage from '@app/pages/system/specialization/CreateSpecializationPage';
import ListSpecializationPage from '@app/pages/system/specialization/ListSpecializationPage';
const UpdateSpecializationPage = React.lazy(() => import('@app/pages/system/specialization/UpdateSpecializationPage'));
const UpdateSpecialization = withLoading(UpdateSpecializationPage);

// product category
import CreateProductCategoryPage from '@app/pages/system/product_category/CreateProductCategoryPage';
import ListProductCategoryPage from '@app/pages/system/product_category/ListProductCategoryPage';
const UpdateProductCategoryPage = React.lazy(
  () => import('@app/pages/system/product_category/UpdateProductCategoryPage'),
);
const UpdateProductCategory = withLoading(UpdateProductCategoryPage);

// sales
import CreateSalesPage from '@app/pages/system/sales/CreateSalesPage';
import ListSalesPage from '@app/pages/system/sales/ListSalesPage';
const UpdateSalesPage = React.lazy(() => import('@app/pages/system/sales/UpdateSalesPage'));
const UpdateSales = withLoading(UpdateSalesPage);

// Quotation / Penawaran
import CreateProductPackageQuotationPage from '@app/pages/transaction/product_quotation/CreateProductPackageQuotationPage';
import CreateProductQuotationPage from '@app/pages/transaction/product_quotation/CreateProductQuotationPage';
import CreateQuotationPage from '@app/pages/transaction/quotation/CreateQuotationPage';
import ListQuotationPage from '@app/pages/transaction/quotation/ListQuotationPage';
const UpdateQuotationPage = React.lazy(() => import('@app/pages/transaction/quotation/UpdateQuotationPage'));
const UpdateQuotation = withLoading(UpdateQuotationPage);
const UpdateProductQuotationPage = React.lazy(
  () => import('@app/pages/transaction/product_quotation/UpdateProductQuotationPage'),
);
const UpdateProductQuotation = withLoading(UpdateProductQuotationPage);

// SPK
import CreateProductPackageSPKPage from '@app/pages/transaction/product_spk/CreateProductPackageSPKPage';
import CreateProductSPKPage from '@app/pages/transaction/product_spk/CreateProductSPKPage';
import CreateSPKPage from '@app/pages/transaction/spk/CreateSPKPage';
import ListSPKPage from '@app/pages/transaction/spk/ListSPKPage';
const UpdateSPKPage = React.lazy(() => import('@app/pages/transaction/spk/UpdateSPKPage'));
const UpdateSPK = withLoading(UpdateSPKPage);
const UpdateProductSPKPage = React.lazy(() => import('@app/pages/transaction/product_spk/UpdateProductSPKPage'));
const UpdateProductSPK = withLoading(UpdateProductSPKPage);

// Invoice
import CreateInvoicePage from '@app/pages/transaction/invoice/CreateInvoicePage';
import ListInvoicePage from '@app/pages/transaction/invoice/ListInvoicePage';
const UpdateInvoicePage = React.lazy(() => import('@app/pages/transaction/invoice/UpdateInvoicePage'));
const UpdateInvoice = withLoading(UpdateInvoicePage);

// Payment
import ProfilePage from '@app/pages/system/ProfilePage';
import SchedulePage from '@app/pages/system/schedule/SchedulePage';
import CreatePaymentPage from '@app/pages/transaction/payment/CreatePaymentPage';
import ListPaymentPage from '@app/pages/transaction/payment/ListPaymentPage';
const UpdatePaymentPage = React.lazy(() => import('@app/pages/transaction/payment/UpdatePaymentPage'));
const UpdatePayment = withLoading(UpdatePaymentPage);

// Policy
import CreatePolicyPage from '@app/pages/permission/policy/CreatePolicyPage';
import ListPolicyPage from '@app/pages/permission/policy/ListPolicyPage';
const UpdatePolicyPage = React.lazy(() => import('@app/pages/permission/policy/UpdatePolicyPage'));
const UpdatePolicy = withLoading(UpdatePolicyPage);

// Group
import CreateGroupPage from '@app/pages/permission/group/CreateGroupPage';
import ListGroupPage from '@app/pages/permission/group/ListGroupPage';
const UpdateGroupPage = React.lazy(() => import('@app/pages/permission/group/UpdateGroupPage'));
const UpdateGroup = withLoading(UpdateGroupPage);

// Vendor Commission
import CreateVendorCommissionPage from '@app/pages/system/vendor_commission/CreateVendorCommissionPage';
import ListVendorCommissionPage from '@app/pages/system/vendor_commission/ListVendorCommissionPage';
const UpdateVendorCommissionPage = React.lazy(
  () => import('@app/pages/system/vendor_commission/UpdateVendorCommissionPage'),
);
const UpdateVendorCommission = withLoading(UpdateVendorCommissionPage);

// Vendor Payment
import CreateVendorPaymentPage from '@app/pages/system/vendor_payment/CreateVendorPaymentPage';
import ListVendorPaymentPage from '@app/pages/system/vendor_payment/ListVendorPaymentPage';
const VendorPaymentDetailPage = React.lazy(() => import('@app/pages/system/vendor_payment/VendorPaymentDetailPage'));
const VendorPaymentDetail = withLoading(VendorPaymentDetailPage);

import { SettingsPage } from '@app/pages/system/settings/SettingsPage';
import CreateVendorBulkPage from '@app/pages/system/vendor_payment/CreateVendorBulkPage';

const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const Logout = React.lazy(() => import('./Logout'));

export const NFT_DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={NFT_DASHBOARD_PATH} element={protectedLayout}>
          <Route path="/" element={<DashboardPage />} />

          <Route path="master/admin" element={<ListAdminPage />} />
          <Route path="master/admin/create" element={<CreateAdminPage />} />
          <Route path="master/admin/:id/update" element={<UpdateAdmin />} />

          <Route path="master/admin-branch/create" element={<CreateAdminBranchPage />} />

          <Route path="master/sales" element={<ListSalesPage />} />
          <Route path="master/sales/create" element={<CreateSalesPage />} />
          <Route path="master/sales/:id/update" element={<UpdateSales />} />

          <Route path="master/admin-branch/create" element={<CreateAdminBranchPage />} />

          <Route path="master/branch" element={<ListBranchPage />} />
          <Route path="master/branch/create" element={<CreateBranchPage />} />
          <Route path="master/branch/:id/update" element={<UpdateBranch />} />

          <Route path="master/customer" element={<ListCustomerPage />} />
          <Route path="master/customer/create" element={<CreateCustomerPage />} />
          <Route path="master/customer/:id/update" element={<UpdateCustomer />} />

          <Route path="master/vendor" element={<ListVendorPage />} />
          <Route path="master/vendor/create" element={<CreateVendorPage />} />
          <Route path="master/vendor/:id/update" element={<UpdateVendor />} />

          <Route path="master/vendor-specialization/create" element={<CreateVendorSpecializationPage />} />

          <Route path="master/technician" element={<ListTechnicianPage />} />
          <Route path="master/technician/create" element={<CreateTechnicianPage />} />
          <Route path="master/technician/:id/update" element={<UpdateTechnician />} />

          <Route path="master/uom" element={<ListUOMPage />} />
          <Route path="master/uom/create" element={<CreateUOMPage />} />
          <Route path="master/uom/:id/update" element={<UpdateUOM />} />

          <Route path="master/specialization" element={<ListSpecializationPage />} />
          <Route path="master/specialization/create" element={<CreateSpecializationPage />} />
          <Route path="master/specialization/:id/update" element={<UpdateSpecialization />} />

          <Route path="master/product" element={<ListProductPage />} />
          <Route path="master/product/create" element={<CreateProductPage />} />
          <Route path="master/product/:id/update" element={<UpdateProduct />} />

          <Route path="master/package" element={<ListPackagePage />} />
          <Route path="master/package/create" element={<CreatePackagePage />} />
          <Route path="master/package/:id/update" element={<UpdatePackage />} />

          <Route path="master/product-package/create" element={<CreatePackageProductPage />} />

          <Route path="master/category" element={<ListProductCategoryPage />} />
          <Route path="master/category/create" element={<CreateProductCategoryPage />} />
          <Route path="master/category/:id/update" element={<UpdateProductCategory />} />

          <Route path="master/vendor-commission" element={<ListVendorCommissionPage />} />
          <Route path="master/vendor-commission/create" element={<CreateVendorCommissionPage />} />
          <Route path="master/vendor-commission/:id/update" element={<UpdateVendorCommission />} />

          <Route path="master/vendor-payment" element={<ListVendorPaymentPage />} />
          <Route path="master/vendor-payment/create" element={<CreateVendorPaymentPage />} />
          <Route path="master/vendor-payment/create/bulk" element={<CreateVendorBulkPage />} />
          <Route path="master/vendor-payment/:id/detail" element={<VendorPaymentDetail />} />

          <Route path="transaction/quotation" element={<ListQuotationPage />} />
          <Route path="transaction/quotation/create" element={<CreateQuotationPage />} />
          <Route path="transaction/quotation/:id/update" element={<UpdateQuotation />} />

          <Route path="transaction/product-quotation/create" element={<CreateProductQuotationPage />} />
          <Route path="transaction/:quotation/product-quotation/:id/update" element={<UpdateProductQuotation />} />
          <Route path="transaction/package-quotation/create" element={<CreateProductPackageQuotationPage />} />

          <Route path="transaction/spk" element={<ListSPKPage />} />
          <Route path="transaction/spk/create" element={<CreateSPKPage />} />
          <Route path="transaction/spk/:id/update" element={<UpdateSPK />} />

          <Route path="transaction/product-spk/create" element={<CreateProductSPKPage />} />
          <Route path="transaction/:spk/product-spk/:id/update" element={<UpdateProductSPK />} />
          <Route path="transaction/package-spk/create" element={<CreateProductPackageSPKPage />} />

          <Route path="transaction/invoice" element={<ListInvoicePage />} />
          <Route path="transaction/invoice/create" element={<CreateInvoicePage />} />
          <Route path="transaction/invoice/:id/update" element={<UpdateInvoice />} />

          <Route path="transaction/payment/create" element={<CreatePaymentPage />} />
          <Route path="transaction/payment/:id/update" element={<UpdatePayment />} />

          <Route path="transaction/payment" element={<ListPaymentPage />} />

          <Route path="system/schedule" element={<SchedulePage />} />

          <Route path="profile" element={<ProfilePage />} />

          <Route path="permission/policy" element={<ListPolicyPage />} />
          <Route path="permission/policy/create" element={<CreatePolicyPage />} />
          <Route path="permission/policy/:id/update" element={<UpdatePolicy />} />

          <Route path="permission/group" element={<ListGroupPage />} />
          <Route path="permission/group/create" element={<CreateGroupPage />} />
          <Route path="permission/group/:id/update" element={<UpdateGroup />} />

          <Route path="system/settings" element={<SettingsPage />} />

          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route path="reset-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="forgot-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/terms" element={<TermAndConditionPage />} />
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
