import { EntityData } from '@app/api/master/entity.api';
import { Modal } from '@app/components/common/Modal/Modal';
import { Switch } from '@app/components/common/Switch/Switch';
import { Table } from '@app/components/common/Table/Table';
import { Button, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';

type PolicyStatementTableProps = {
  data: EntityData[];
  onChangeCheck: (entity_name: string, access: string) => void;
  onSelectAll: (entity_names: string[]) => void;
  onDelete: (entity_name: string) => void;
};

const PolicyStatementTable = ({ data, onChangeCheck, onSelectAll, onDelete }: PolicyStatementTableProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const columns: ColumnsType<EntityData> = [
    {
      title: 'Nama',
      dataIndex: 'entity_name',
    },
    {
      title: 'Akses',
      children: [
        {
          title: 'Baca',
          render: (text: string, record: EntityData) => (
            <Switch checked={record.grant_read} onChange={() => onChangeCheck(record.entity_name, 'grant_read')} />
          ),
        },
        {
          title: 'Buat',
          render: (text: string, record: EntityData) => (
            <Switch checked={record.grant_create} onChange={() => onChangeCheck(record.entity_name, 'grant_create')} />
          ),
        },
        {
          title: 'Ubah',
          render: (text: string, record: EntityData) => (
            <Switch checked={record.grant_update} onChange={() => onChangeCheck(record.entity_name, 'grant_update')} />
          ),
        },
        {
          title: 'Hapus',
          render: (text: string, record: EntityData) => (
            <Switch checked={record.grant_delete} onChange={() => onChangeCheck(record.entity_name, 'grant_delete')} />
          ),
        },
      ],
    },
    {
      title: '',
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: EntityData) => {
        return (
          <Space>
            <Button type="default" danger onClick={() => onDelete(record.entity_name)}>
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    onSelectAll(newSelectedRowKeys as string[]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 800 }} bordered />
    </>
  );
};

export default PolicyStatementTable;
