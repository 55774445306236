import { Col, Row, Tabs } from 'antd';
import React, { memo } from 'react';
import BulkPaymentTab from './BulkPaymentTab';

enum BulkPaymentTabData {
  PEMBAYARAN = '1',
  PAYMENT_PROOF = '2',
}

type Props = {
  loading: boolean;
  setLoading: (status: boolean) => void;
};

const BulkMasterView: React.FC<Props> = ({ loading: getLoading, setLoading: setGetLoading }) => {
  const tabItems = [
    {
      label: 'SPK',
      key: BulkPaymentTabData.PEMBAYARAN,
      children: <BulkPaymentTab />,
    },
    {
      label: 'Bukti Bayar',
      key: BulkPaymentTabData.PAYMENT_PROOF,
      // children: <ProofsTab getMedia={onGetMedia} />,
    },
  ];

  return (
    <Row gutter={[30, 30]} style={{ marginTop: '16px' }}>
      <Col xs={24} md={24}>
        <Tabs defaultActiveKey="1" items={tabItems} />
      </Col>
    </Row>
  );
};

export default memo(BulkMasterView);
