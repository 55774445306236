import React, { ComponentProps } from 'react';
import { Col, Row } from 'antd';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useTranslation } from 'react-i18next';

interface BaseButtonsGroupProps extends ComponentProps<typeof Button> {
  className?: string;
  onCancel: () => void;
  loading?: boolean;
  isFieldChanged?: boolean;
}

export const BaseButtonsGroup: React.FC<BaseButtonsGroupProps> = ({
  className,
  onCancel,
  loading,
  isFieldChanged = true,
  ...props
}) => {
  return (
    <Row className={className} gutter={[10, 10]} wrap={false}>
      {/* <Col md={8}>
        <Button block type="ghost" onClick={onCancel} {...props}>
          Reset
        </Button>
      </Col> */}
      <Col xs={24} sm={24} md={{ offset: 8, span: 8 }}>
        <Button block type="primary" loading={loading} htmlType="submit" {...props} disabled={!isFieldChanged}>
          Simpan
        </Button>
      </Col>
    </Row>
  );
};
