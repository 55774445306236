import { EntityData } from '@app/api/master/entity.api';
import { Table } from '@app/components/common/Table/Table';
import { Button, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';

type GroupPolicyTableProps = {
  data: EntityData[];
  onDelete: (entity_name: string) => void;
};

const GroupPolicyTable = ({ data, onDelete }: GroupPolicyTableProps) => {
  const columns: ColumnsType<EntityData> = [
    {
      title: 'Akses',
      dataIndex: 'name',
      render: (text: string, record: EntityData) => (
        <Space direction="vertical">
          <div>{record.name}</div>
        </Space>
      ),
    },
    {
      title: 'Deskripsi',
      dataIndex: 'description',
    },
    {
      title: '',
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: EntityData) => {
        return (
          <Space>
            <Button type="default" danger onClick={() => onDelete(record.name)}>
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 800 }} bordered />
    </>
  );
};

export default GroupPolicyTable;
