import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Form, Space, TablePaginationConfig } from 'antd';
import { Pagination } from '@app/api/general.api';
import { Table } from 'components/common/Table/Table';
import { Button } from 'components/common/buttons/Button/Button';
import { useMounted } from '@app/hooks/useMounted';
import type { FilterValue } from 'antd/es/table/interface';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useLoading } from '@app/hooks/useLoading';
import { EntityData, getEntityRecords, getEntityRecordCount } from '@app/api/master/entity.api';
import { formatNumberWithCommas, formatRupiahPrice } from '@app/utils/utils';
import { EditableCell } from '../../editableTable/EditableCell';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm.styles';

const initialPagination: Pagination = {
  pageSize: 50,
  current: 1,
};

export type SPKProductListTableProps = {
  packageId: string | number;
  onChange: (data: EntityData[]) => void;
  setIsEditing: (isEdit: boolean) => void;
};

export const EditableSPKProductListTable: React.FC<SPKProductListTableProps> = ({
  packageId,
  onChange,
  setIsEditing,
}) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState(0);

  const [_, setLoading] = useAtom(useLoading);
  const [deleteModalData, setDeleteModalData] = useState<{
    show: boolean;
    data: EntityData | null;
  }>({
    show: false,
    data: null,
  });

  const [tableData, setTableData] = useState<{
    data: EntityData[];
    pagination: Pagination;
    loading: boolean;
    q: number | string | boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
    q: '',
  });

  const [recordCount, setRecordCount] = useState<{
    loading: boolean;
    count: number;
  }>({
    loading: false,
    count: 0,
  });

  const { isMounted } = useMounted();
  const navigate = useNavigate();
  const [refetchToken, setRefetch] = useState(0);

  const fetch = useCallback(
    (pagination: Pagination, query?: string) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));

      getEntityRecords('tt_package_product', {
        page: (pagination.current || 1) - 1,
        limit: pagination.pageSize || 50,
        q: `pkg.id==${packageId}` + (query ? `;${query}` : ''),
        sort: 'product.id ASC',
      })
        .then((res) => {
          if (isMounted.current) {
            const newData = res.records.map((item) => {
              return recalculateSubtotal({
                ...item,
                qty: 1,
              });
            });
            setTableData({
              data: newData,
              pagination: {
                current: pagination.current,
                pageSize: pagination.pageSize,
              },
              loading: false,
              q: query || '',
            });
            onChange(newData);
          }
        })
        .catch((err) => {
          console.error(`[Get SPK Product List Error] - ${err}`);
        });
    },
    [isMounted, refetchToken, packageId],
  );

  const fetchCount = useCallback(
    (pagination: Pagination, query?: string) => {
      setRecordCount((recordCount) => ({ ...recordCount, loading: true }));

      getEntityRecordCount('tt_spk_product', {
        page: (pagination.current || 1) - 1,
        limit: pagination.pageSize || 50,
        q: `pkg.id==${packageId}` + (query ? `;${query}` : ''),
      })
        .then((res) => {
          if (isMounted.current) {
            setRecordCount({
              count: res.count,
              loading: false,
            });
          }
        })
        .catch((err) => {
          console.error(`[Get SPK Product List Error] - ${err}`);
        });
    },
    [isMounted, refetchToken, packageId],
  );

  useEffect(() => {
    fetch(initialPagination);
    fetchCount(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
    if (filters.name) {
      fetchCount(pagination, `product.name=ilike=${filters.name}`);
      fetch(pagination, `product.name=ilike=${filters.name}`);
    }
    fetch(pagination);
  };

  const handleDeleteRow = (rowId: number) => {
    const newData = tableData.data.filter((item) => item.id !== rowId);
    setTableData({ ...tableData, data: newData });
    onChange(newData);
  };

  const isEditing = (record: EntityData) => record.id === editingKey;

  const edit = (record: EntityData) => {
    form.setFieldsValue(record);
    setEditingKey(record.id);
    setIsEditing(record.id > 0 ? true : false);
  };

  const cancel = () => {
    setEditingKey(0);
    setIsEditing(false);
  };

  const save = async (id: string) => {
    try {
      const row = (await form.validateFields()) as EntityData;

      const newData = [...tableData.data];
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        newData[index] = recalculateSubtotal(newData[index]);
      } else {
        newData.push(row);
      }
      setTableData({ ...tableData, data: newData });
      setEditingKey(0);
      onChange(newData);
      setIsEditing(false);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const recalculateSubtotal = (record: EntityData) => {
    // calculate the rounding up
    const qty = record.qty || 0;
    const price = record.price || 0;

    return {
      ...record,
      subtotal: price * qty,
    };
  };

  const columns = [
    {
      title: 'Produk',
      dataIndex: 'product',
      render: (text: string, record: EntityData) => {
        return <Space>{record.product.name}</Space>;
      },
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      render: (text: string, record: EntityData) => {
        return formatNumberWithCommas(record.qty);
      },
      editable: true,
      inputType: 'number',
    },
    {
      title: 'UOM',
      dataIndex: 'uom',
      render: (text: string, record: EntityData) => {
        return <Space>{record.product.uom.name}</Space>;
      },
    },
    {
      title: 'Harga',
      dataIndex: 'price',
      render: (text: string, record: EntityData) => {
        return <>{record.price > 0 ? formatRupiahPrice(record.price) : '-'}</>;
      },
      editable: true,
      inputType: 'number',
    },
    {
      title: 'Subtotal',
      render: (text: string, record: EntityData) => {
        return <>{record.subtotal > 0 ? formatRupiahPrice(record.subtotal) : '-'}</>;
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: EntityData) => {
        const editable = isEditing(record);
        return (
          <Space>
            {editable ? (
              <>
                <Button type="primary" onClick={() => save(record.id)}>
                  Simpan
                </Button>
                <Popconfirm title="Batal" onConfirm={cancel}>
                  <Button type="ghost">Batal</Button>
                </Popconfirm>
              </>
            ) : (
              <>
                <Button type="ghost" disabled={editingKey !== 0} onClick={() => edit(record)}>
                  Ubah
                </Button>
                <Button type="default" danger onClick={() => handleDeleteRow(record.id)}>
                  Delete
                </Button>
              </>
            )}
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: EntityData) => ({
        record,
        inputType: col.inputType || 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={tableData.data}
        pagination={{ ...tableData.pagination, total: recordCount.count }}
        loading={tableData.loading || recordCount.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
    </Form>
  );
};
