export const BASE_API_URL = process.env.REACT_APP_API_URL;

export const METABASE_URL = process.env.REACT_APP_METABASE_URL;
export const METABASE_TOKEN = process.env.REACT_APP_METABASE_TOKEN || 'xxxx';
export const METABASE_DASHBOARD = process.env.REACT_APP_METABASE_DASHBOARD || 1;

export const DAYS = ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu'];

export const MONTHS = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
];
