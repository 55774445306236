import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { notificationController } from '@app/controllers/notificationController';
import { useMounted } from '@app/hooks/useMounted';
import { Col, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { Modal } from '@app/components/common/Modal/Modal';
import styled from 'styled-components';
import { UOMModalForm } from './UOMModalForm';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { FormInstance } from 'antd/es/form/Form';
import { EntityData, EntityField, getEntityRecords } from '@app/api/master/entity.api';
import { Pagination } from '@app/api/general.api';
import useDebounce from '@app/hooks/useDebounce';

export type UOMFormProps = {
  form: FormInstance;
  metafield?: EntityField;
  onChange: () => void;
};

export const UOMForm: React.FC<UOMFormProps> = ({ form, metafield, onChange }) => {
  const [openModal, setOpenModal] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [UOMData, setUOMData] = useState<Array<any> | null>(null);
  const [refetchToken, setRefetch] = useState(0);
  const { isMounted } = useMounted();
  const [pagination, setPagination] = useState<Pagination>();
  const [query, setQuery] = useState<string>();

  const search = useDebounce(query, 250);

  useEffect(() => {
    fetch();
  }, [refetchToken, search]);

  const fetch = () => {
    let params: {
      page: number;
      limit: number;
      q?: string;
    } = {
      page: (pagination?.current || 1) - 1,
      limit: pagination?.pageSize || 10,
    };
    if (search) params = { ...params, q: search };

    getEntityRecords('c_uom', params)
      .then((res) => {
        setUOMData(
          res.records.map((data: EntityData) => {
            return {
              value: data.id,
              label: data.name,
            };
          }),
        );
      })
      .catch((err) => {
        console.error(`[Get UOM List Error] ${err}`);
      })
      .finally(() => {
        if (isMounted.current) {
          setFetchLoading(false);
        }
      });
  };

  const createUOM = () => {
    setOpenModal(false);
    createForm.submit();
  };

  const loadingItem = () => {
    return (
      <div style={{ textAlign: 'center', padding: '4px' }}>
        <Spin size="small" />
        <div>Mohon menunggu</div>
      </div>
    );
  };

  const resetSelection = () => {
    setRefetch(refetchToken + 1);
  };

  const handleChange = (value: unknown) => {
    const val = value as string[];
    form.setFieldValue('uom', val);
    if (onChange !== undefined) onChange();

    console.log(form.getFieldsValue());
  };

  const handleSearch = (value: string) => {
    setFetchLoading(true);
    setPagination({
      current: 1,
      pageSize: 10,
    });
    if (value) setQuery(`name=ilike=${value}`);
    else setQuery('');
  };

  const [createForm] = BaseForm.useForm();

  return (
    <>
      <BaseButtonsForm.Item
        name="uom"
        label="UOM"
        rules={[{ required: !metafield?.nullable, message: 'Harap masukkan UOM', type: 'number' }]}
        hasFeedback
      >
        <Select
          defaultValue={form.getFieldValue('uom')}
          showSearch
          onChange={handleChange}
          onSearch={handleSearch}
          filterOption={false}
          notFoundContent={fetchLoading ? loadingItem() : 'Tidak ada data'}
        >
          {UOMData &&
            UOMData.map((uom, idx) => {
              return (
                <Option value={uom.value} key={`uom-selection-${idx}`}>
                  {uom.label}
                </Option>
              );
            })}
        </Select>
        <ClickableText onClick={() => setOpenModal(true)}>+ Tambah UOM</ClickableText>
      </BaseButtonsForm.Item>
      <Modal title="Tambah UOM" visible={openModal} onOk={() => createUOM()} onCancel={() => setOpenModal(false)}>
        <UOMModalForm form={createForm} onSubmit={resetSelection} />
      </Modal>
    </>
  );
};

const ClickableText = styled.div`
  text-align: right;
  font-size: 14px;
  margin-top: 5px;
  cursor: pointer;
  color: var(--text-nft-light-color);
`;
