import axios from 'axios';
import { AxiosError } from 'axios';
import { ApiError } from '@app/api/ApiError';
import Cookies from 'js-cookie';
import { BASE_API_URL } from '@app/utils/constants';

export const httpApi = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

httpApi.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
  };

  if (Cookies.get('token'))
    config.headers = {
      Authorization: 'Bearer ' + Cookies.get('token'),
    };

  return config;
});

httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
  throw new ApiError<ApiErrorData>(error.response?.data.message || error.message, error.response?.data);
});

export interface ApiErrorData {
  message: string;
}
