import { DeleteOutlined, EditTwoTone, SearchOutlined } from '@ant-design/icons';
import { Pagination } from '@app/api/general.api';
import { EntityData, deleteEntity, getEntityRecordCount, getEntityRecords } from '@app/api/master/entity.api';
import { Modal } from '@app/components/common/Modal/Modal';
import { ActiveBranchAtom } from '@app/components/header/components/branchDropdown';
import { notificationController } from '@app/controllers/notificationController';
import { useLoading } from '@app/hooks/useLoading';
import { useMounted } from '@app/hooks/useMounted';
import { formatFullDate, getStatusLabelTag, getUserBranch } from '@app/utils/utils';
import { Input, Space, TablePaginationConfig } from 'antd';
import { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps, FilterValue } from 'antd/es/table/interface';
import { Table } from 'components/common/Table/Table';
import { Button } from 'components/common/buttons/Button/Button';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const initialPagination: Pagination = {
  pageSize: 10,
  current: 1,
};

export const QuotationListTable: React.FC = () => {
  const [activeBranchAtom] = useAtom(ActiveBranchAtom);
  const [refetchToken, setRefetch] = useState(0);
  const [_, setLoading] = useAtom(useLoading);
  const [deleteModalData, setDeleteModalData] = useState<{
    show: boolean;
    data: EntityData | null;
  }>({
    show: false,
    data: null,
  });

  const [tableData, setTableData] = useState<{
    data: EntityData[];
    pagination: Pagination;
    loading: boolean;
    q: number | string | boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
    q: '',
  });

  const [recordCount, setRecordCount] = useState<{
    loading: boolean;
    count: number;
  }>({
    loading: false,
    count: 0,
  });

  const { isMounted } = useMounted();
  const navigate = useNavigate();

  const fetch = useCallback(
    (pagination: Pagination, query?: string) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));

      const activeBranch = getUserBranch();

      getEntityRecords('tt_quotation', {
        page: (pagination.current || 1) - 1,
        limit: pagination.pageSize || 10,
        q: `${activeBranch ? `vendor.branch.id==${activeBranch.id}${query ? ';' : ''}` : ''}${query || ''}`,
        sort: 'createdDate DESC',
      })
        .then((res) => {
          if (isMounted.current) {
            setTableData({
              data: res.records,
              pagination: {
                current: pagination.current,
                pageSize: pagination.pageSize,
              },
              loading: false,
              q: query || '',
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [isMounted, refetchToken, activeBranchAtom],
  );

  const fetchCount = useCallback(
    (pagination: Pagination, query?: string) => {
      const activeBranch = getUserBranch();
      setRecordCount((recordCount) => ({ ...recordCount, loading: true }));

      getEntityRecordCount('tt_quotation', {
        page: (pagination.current || 1) - 1,
        limit: pagination.pageSize || 10,
        q: `${activeBranch ? `vendor.branch.id==${activeBranch.id}${query ? ';' : ''}` : ''}${query || ''}`,
      })
        .then((res) => {
          if (isMounted.current) {
            setRecordCount({
              count: res.count,
              loading: false,
            });
          }
        })
        .catch((err) => {
          console.error(`[Get Quotation List Error] - ${err}`);
        });
    },
    [isMounted, refetchToken, activeBranchAtom],
  );

  useEffect(() => {
    fetch(initialPagination);
    fetchCount(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
    let query = '';

    if (filters.code) {
      if (query !== '') query += ';';

      query += `code=ilike=${filters.code}`;
    }

    if (filters.status) {
      if (query !== '') query += ';';

      const seperatedStatus = filters.status.toString().split(' ');
      const status = seperatedStatus.join('_').toUpperCase();
      query += `status==${status}`;
    }

    fetchCount(pagination, `code=ilike=${filters.name}`);
    fetch(pagination, query);
  };

  const handleDeleteRow = (row: EntityData | null) => {
    setDeleteModalData({
      show: false,
      data: null,
    });

    setLoading({
      isLoading: true,
      message: 'Mohon menunggu...',
    });

    deleteEntity('tt_quotation', row?.id || '')
      .then((res) => {
        notificationController.success({ message: 'Sukses menghapus penawaran' });
        setRefetch(refetchToken + 1);
      })
      .catch((err) => {
        console.error(`[Delete Quotation Error] - ${err}`);
        notificationController.error({ message: 'Gagal menghapus penawaran' });
      })
      .finally(() => {
        setLoading({
          isLoading: false,
        });
      });
  };

  const handleEditRow = (rowId: string) => {
    navigate(`/transaction/quotation/${rowId}/update`);
  };

  // Advanced Search
  const searchInput = useRef(null);

  const handlerClearFilter = (confirm: (param?: FilterConfirmProps | undefined) => void, clearFilters: () => void) => {
    clearFilters();
    confirm();
  };

  const getColumnSearchProps = (dataIndex: string): ColumnType<EntityData> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Cari penawaran`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small">
            Cari
          </Button>
          <Button
            onClick={() => clearFilters && handlerClearFilter(confirm, clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (!record[dataIndex]) return false;
      else {
        const seperatedStatus = record[dataIndex].toString().split('_');
        const status = seperatedStatus.join(' ').toString().toLowerCase();
        return status.toLowerCase().includes((value as string).toLowerCase());
      }
    },
  });

  const columns: ColumnsType<EntityData> = [
    {
      title: 'Kode',
      dataIndex: 'code',
      ...getColumnSearchProps('code'),
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      render: (text: string, record: EntityData) => {
        return <Space>{record.customer.name}</Space>;
      },
    },
    {
      title: 'Lokasi',
      render: (text: string, record: EntityData) => {
        return (
          <Space direction="vertical">
            <div>{record.address}</div>
            <div>
              {record.city.name}, {record.city.province.name}, {record.city.province.country.name}
            </div>
            <div>{record.postal_number}</div>
          </Space>
        );
      },
    },
    {
      title: 'Nomor Telp',
      dataIndex: 'mobile_phone',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_: undefined, record: EntityData) => {
        return getStatusLabelTag(record.status);
      },
      ...getColumnSearchProps('status'),
    },
    {
      title: 'Dibuat pada',
      render: (text: string, record: EntityData) => {
        return record.created_date ? formatFullDate(record.created_date) : '';
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      width: '5%',
      render: (text: string, record: EntityData) => {
        return (
          <Space
            direction="vertical"
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <EditTwoTone onClick={() => handleEditRow(record.id)} style={{ fontSize: '20px' }} />
            <DeleteOutlined
              onClick={() => setDeleteModalData({ show: true, data: record })}
              style={{
                fontSize: '20px',
                color: 'red',
              }}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={tableData.data}
        pagination={{ ...tableData.pagination, total: recordCount.count }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      <Modal
        title="Konfirmasi"
        visible={deleteModalData.show}
        onOk={() => handleDeleteRow(deleteModalData.data)}
        onCancel={() => setDeleteModalData({ show: false, data: deleteModalData.data })}
      >
        Apakah yakin ingin menghapus {deleteModalData.data?.name}?
      </Modal>
    </>
  );
};
