import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { FormInstance } from 'antd/es/form/Form';
import React from 'react';
import { useAtom } from 'jotai';
import { useLoading } from '@app/hooks/useLoading';
import { createEntityRecord, EntityData } from '@app/api/master/entity.api';

export interface PaymentTypeModalFormProps {
  form: FormInstance<any> | undefined;
  onSubmit: () => void;
}

export const PaymentTypeModalForm: React.FC<PaymentTypeModalFormProps> = ({ form, onSubmit }) => {
  const [_, setLoading] = useAtom(useLoading);

  const onFinish = (values: EntityData) => {
    setLoading({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    createEntityRecord('tt_payment_type', values)
      .then((res) => {
        notificationController.success({ message: 'Berhasil menambahkan tipe pembayaran' });
      })
      .catch((err) => {
        console.error(`[Create Payment Type Modal Form Error] - ${err}`);
        notificationController.error({ message: 'Gagal menambahkan tipe pembayaran' });
      })
      .finally(() => {
        setLoading({
          isLoading: false,
        });
        form?.resetFields();
        onSubmit();
      });
  };

  return (
    <>
      <BaseForm form={form} onFinish={onFinish}>
        <BaseForm.Item name="field" label="Tipe Pembayaran">
          <Input />
        </BaseForm.Item>
      </BaseForm>
    </>
  );
};
