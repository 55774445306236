import React, { useEffect, useRef, useState } from 'react';
import { Modal } from '@app/components/common/Modal/Modal';
import { UploadDragger } from '@app/components/common/Upload/Upload';
import { InboxOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload';
import { uploadPicture } from '@app/api/master/entity.api';
import { notificationController } from '@app/controllers/notificationController';
import { Form } from 'antd';

export type UploadPhotoProps = {
  onUploadSuccess: (imageId: number, file: any) => void;
  onCancel: () => void;
  filename: string;
  trigger: boolean;
};

const UploadPhoto = ({ onUploadSuccess, onCancel, filename, trigger }: UploadPhotoProps) => {
  const [url, setUrl] = useState<any>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [file, setFile] = useState<UploadFile>();
  const uploaderRef = useRef<any>();

  const updatePhoto = () => {
    if (file) {
      uploadPicture(filename, file)
        .then((res) => {
          onUploadSuccess(res.id, file);
          setOpenModal(false);
          setUrl(file);
          setFile(undefined);
        })
        .catch((err) => {
          console.error(`[Upload Photo Error] - ${err}`);
          notificationController.error({ message: 'Gagal upload foto. Silahkan dicoba lagi beberapa saat.' });
        });
    } else {
      notificationController.error({ message: 'Harap masukkan foto dulu.' });
    }
  };

  const beforeUpload = (file: UploadFile) => {
    setFile(file);
    return false;
  };

  useEffect(() => {
    if (trigger) setOpenModal(true);
  }, [trigger]);

  const cancelModal = () => {
    onCancel();
    setOpenModal(false);
  };

  return (
    <>
      <Modal
        title="Upload foto"
        open={openModal}
        onOk={() => updatePhoto()}
        onCancel={cancelModal}
        destroyOnClose={true}
      >
        <UploadDragger
          maxCount={1}
          beforeUpload={beforeUpload}
          accept="image/png, image/jpeg, image/jpg, image/svg"
          ref={uploaderRef}
        >
          <p>
            <InboxOutlined />
          </p>
          <p>Klik atau tarik file foto ke dalam kotak ini</p>
        </UploadDragger>
      </Modal>
    </>
  );
};

export default UploadPhoto;
