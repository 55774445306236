import React from 'react';
import { BreadcrumbProps } from 'antd';
import * as S from './Breadcrumb.styles';
import { Link } from 'react-router-dom';

export type BreadcrumbItemProps = {
  href: string;
};

export const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({ href, children }) => {
  return (
    <S.BreadcrumbItem>
      <Link to={href}>{children}</Link>
    </S.BreadcrumbItem>
  );
};

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ children, ...props }) => {
  return <S.Breadcrumb {...props}>{children}</S.Breadcrumb>;
};
