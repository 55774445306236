import styled from 'styled-components';
import { Breadcrumb as AntBreadcrumb } from 'antd';
export const { Item } = AntBreadcrumb;

export const BreadcrumbItem = styled.li`
  &:not(:last-child):after {
    content: '/';
    margin: 0 0.5rem;
  }
`;

export const Breadcrumb = styled(AntBreadcrumb)`
  margin-bottom: 1rem;
`;
