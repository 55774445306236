import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './BranchDropdown.styles';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { BranchOptions } from './BranchOptions';
import { EntityData } from '@app/api/master/entity.api';
import { BankOutlined, CaretDownFilled } from '@ant-design/icons';
import { H6 } from '@app/components/common/typography/H6/H6';
import { atom, useAtom } from 'jotai';
import { getUserDetail } from '@app/utils/utils';

type ActiveBranchType = {
  name: string;
  id: string;
};

export const ActiveBranchAtom = atom<ActiveBranchType>({
  name: '',
  id: '',
});

export const BranchDropdown: React.FC = () => {
  const [activeBranch, setActiveBranch] = useAtom(ActiveBranchAtom);

  const user = getUserDetail();

  useEffect(() => {
    const branches = Cookies.get('branch');

    if (!branches) return;
    const dataBranches = JSON.parse(branches) as Array<EntityData>;
    dataBranches.map((branch) => {
      if (branch.selected)
        setActiveBranch({
          name: branch.name,
          id: branch.id,
        });
    });
  }, []);

  return (
    <>
      {!user?.is_root && (
        <Dropdown overlay={<BranchOptions />} trigger={['click']}>
          <S.ProfileDropdownHeader as={Row} gutter={[10, 10]} align="middle">
            <Col>
              <p style={{ fontSize: '11px', marginBottom: '0' }}>
                <BankOutlined /> Cabang
              </p>
              <H6 style={{ padding: '0 4px' }}>
                {activeBranch.name} <CaretDownFilled />
              </H6>
            </Col>
          </S.ProfileDropdownHeader>
        </Dropdown>
      )}
    </>
  );
};
