import { DashboardOutlined } from '@ant-design/icons';
import {
  EntityData,
  EntityFieldsResponse,
  EntityRecordRequest,
  getEntityFields,
  getPicture,
  updateEntityRecord,
} from '@app/api/master/entity.api';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Card } from '@app/components/common/Card/Card';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import UploadProfilePicture from '@app/components/forms/Profile/uploadProfilePicture';
import { PersonalInfoForm } from '@app/components/forms/master/Personal/PersonalInfoForm';
import { Loading } from '@app/components/loading';
import { notificationController } from '@app/controllers/notificationController';
import { useLoading } from '@app/hooks/useLoading';
import { useMounted } from '@app/hooks/useMounted';
import { doLogout } from '@app/store/slices/authSlice';
import { Col, Row, Space, Tabs } from 'antd';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useState } from 'react';
import { useProfileUpdate } from '@app/hooks/useProfileUpdate';
import { useNavigate } from 'react-router-dom';
import { checkPagePermission } from '@app/utils/utils';
import TabPane from 'antd/lib/tabs/TabPane';
import UpdatePasswordForm from '@app/components/forms/update_password';

enum ProfileTabData {
  DETAIL = '1',
  CHANGE_PASSWORD = '2',
}

const ProfilePage = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [data, setData] = useState<EntityData | null>(null);
  const [image, setImage] = useState<any>();
  const [isLoading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const { isMounted } = useMounted();
  const [_, setLoadingScreen] = useAtom(useLoading);
  const [metafield, setMetafield] = useState<EntityFieldsResponse>();
  const [refetchToken, setRefetch] = useState(0);
  const [profileUpdate] = useAtom(useProfileUpdate);
  const navigate = useNavigate();

  useEffect(() => {
    const user = Cookies.get('user_logged');
    if (!user) {
      // no user logged in, force logout
      doLogout();
      navigate('/auth/login');
      return;
    }

    const parsedUser = JSON.parse(user || '');

    if (
      !checkPagePermission([
        {
          entity: parsedUser?.entity,
          action: 'READ',
        },
      ])
    )
      navigate('/');

    setData({ ...parsedUser });
    if (parsedUser.profile_picture) fetchProfilePicture(parsedUser.profile_picture?.id);
    fetchMetafield(parsedUser?.entity);
  }, []);

  useEffect(() => {
    setRefetch(refetchToken + 1);
  }, [data]);

  const fetchProfilePicture = (imageId: string | number) => {
    getPicture(imageId)
      .then((res) => {
        setImage(res);
      })
      .catch((err) => {
        console.error(`[Error Get Profile Photo] - ${err}`);
      });
  };

  const fetchMetafield = (entity: string) => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    getEntityFields(entity)
      .then((res) => {
        setMetafield(res);
      })
      .catch((err) => {
        console.error(`[Error Get Profile Fields] - ${err}`);
      })
      .finally(() => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  };

  const uploadPictureSuccess = (imageId: number, file: any) => {
    const body: EntityRecordRequest = {
      ...data,
      profile_picture: {
        id: imageId,
      },
    };
    if (data?.date_of_birth) body['date_of_birth'] = dayjs(data?.date_of_birth).format('YYYY-MM-DD 00:00:00');

    updateEntityRecord(data?.entity, data?.id, body)
      .then((res) => {
        setImage(URL.createObjectURL(file));
        const newData = { ...data, ...res };
        Cookies.set('user_logged', JSON.stringify(newData));
        setData(newData);
        profileUpdate.onUpdate();
        notificationController.success({ message: 'Sukses mengganti foto profil' });
      })
      .catch((err) => {
        console.error(`[Update Profile Picture Error] - ${err}`);
        notificationController.error({ message: 'Gagal mengganti foto profil' });
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });
  };

  const onFinish = useCallback((values: EntityData, _data: EntityData | null, onSuccess: () => void) => {
    // todo dispatch an action here
    setLoading(true);
    setTimeout(() => {
      setFieldsChanged(false);
      // call update profile API
      const body: EntityRecordRequest = { ...values };

      if (_data?.profile_picture)
        body['profile_picture'] = {
          id: _data.profile_picture.id,
        };
      if (values.date_of_birth) body['date_of_birth'] = dayjs(values.date_of_birth).format('YYYY-MM-DD 00:00:00');
      updateEntityRecord(_data?.entity, _data?.id, body)
        .then((res) => {
          const newData = { ..._data, ...res };
          Cookies.set('user_logged', JSON.stringify(newData));
          onSuccess();
          notificationController.success({ message: 'Sukses mengubah profile' });
        })
        .catch((err) => {
          console.error(`[Update Profile Error] - ${err}`);
          notificationController.error({ message: 'Gagal mengubah profile' });
        })
        .finally(() => {
          if (isMounted.current) {
            setLoading(false);
          }
        });
    }, 1000);
  }, []);
  const [form] = BaseButtonsForm.useForm();

  // create the global form here
  // so just change the entity params when updating
  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="/profile">Profile</BreadcrumbItem>
      </Breadcrumb>
      <Tabs defaultActiveKey={ProfileTabData.DETAIL}>
        <TabPane tab="Detil Profil" key={ProfileTabData.DETAIL}>
          <Row gutter={[30, 30]}>
            <Col xs={24} md={8}>
              <Card
                title=""
                padding="1.25rem"
                style={{ display: 'flex', alignItems: 'center' }}
                bodyStyle={{ display: 'flex', alignItems: 'center' }}
              >
                <UploadProfilePicture
                  filename={`profile-${data?.id}`}
                  onUploadSuccess={uploadPictureSuccess}
                  image={image}
                />
              </Card>
            </Col>
            {/* Main Data */}
            <Col xs={24} md={16}>
              <Card title="Data Profil" padding="1.25rem">
                {getLoading ? (
                  <Loading message="Sedang mengambil data" />
                ) : (
                  <BaseButtonsForm
                    form={form}
                    name="Edit"
                    isFieldsChanged={isFieldsChanged}
                    onFieldsChange={() => setFieldsChanged(true)}
                    setFieldsChanged={setFieldsChanged}
                    onFinish={() => onFinish(form.getFieldsValue(), data, profileUpdate.onUpdate)}
                    loading={isLoading}
                    initialValues={data || undefined}
                    key={refetchToken}
                  >
                    <Row gutter={{ xs: 10, md: 15 }}>
                      <Col xs={24} md={12}>
                        <BaseButtonsForm.Item
                          name="name"
                          label="Nama"
                          rules={[
                            {
                              required: !metafield?.name.nullable,
                              message: 'Harap masukkan nama admin',
                              type: 'string',
                            },
                          ]}
                        >
                          <Input />
                        </BaseButtonsForm.Item>
                      </Col>

                      <Col xs={24} md={12}>
                        <BaseButtonsForm.Item
                          name="nik"
                          label="NIK"
                          rules={[
                            { required: !metafield?.nik.nullable, message: 'Harap masukkan nomor NIK', type: 'string' },
                          ]}
                        >
                          <Input />
                        </BaseButtonsForm.Item>
                      </Col>
                    </Row>

                    <PersonalInfoForm metafield={metafield} />
                  </BaseButtonsForm>
                )}
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Ubah Password" key={ProfileTabData.CHANGE_PASSWORD}>
          <Card title="" padding="1.25rem">
            <UpdatePasswordForm user_id={data?.user.id} />
          </Card>
        </TabPane>
      </Tabs>
    </>
  );
};

export default ProfilePage;
