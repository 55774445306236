import React, { useEffect } from 'react';
import Calendar from '@app/components/common/Calendar';
import { Card } from '@app/components/common/Card/Card';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { checkPagePermission } from '@app/utils/utils';

const SchedulePage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_technician_schedule',
          action: 'READ',
        },
      ])
    )
      navigate('/');
  }, []);

  return (
    <>
      <Row gutter={[30, 30]}>
        {/* Main Data */}
        <Col xs={24} md={24}>
          <Card title="Jadwal Teknisi" padding="1.25rem">
            <Calendar />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SchedulePage;
