import React, { useEffect, useState } from 'react';
import { Switch } from '@app/components/common/Switch/Switch';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Col, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { DataSelectionForm } from '@app/components/forms/master/DataSelectionForm';
import { getEntityList } from '@app/api/master/entity.api';

type PolicyStatementModalProps = {
  form: FormInstance<any> | undefined;
  onFinish: (values: any) => void;
};

const PolicyStatementModal = ({ form, onFinish }: PolicyStatementModalProps) => {
  const [entityList, setEntityList] = useState([]);

  useEffect(() => {
    getEntityList().then((res) => {
      setEntityList(
        res.entities
          .map((entity: string) => {
            const name = entity.split('_');
            return {
              label: name.length == 1 ? name : name.slice(1).join(' '),
              value: entity,
            };
          })
          .sort((a: any, b: any) => (a.label < b.label ? -1 : 1)),
      );
    });
  }, []);

  const onFinishForm = (values: any) => {
    onFinish({ ...values });
  };

  return (
    <>
      <BaseForm form={form} onFinish={onFinishForm}>
        <Row gutter={{ xs: 10, md: 15 }}>
          <Col xs={24}>
            <DataSelectionForm field="entity_name" form={form} label="Entitas" options={entityList} />
          </Col>
          <Col xs={24} md={12}>
            <BaseForm.Item name="grant_read" label="Akses Baca">
              <Switch />
            </BaseForm.Item>
          </Col>
          <Col xs={24} md={12}>
            <BaseForm.Item name="grant_create" label="Akses Buat">
              <Switch />
            </BaseForm.Item>
          </Col>
          <Col xs={24} md={12}>
            <BaseForm.Item name="grant_update" label="Akses Ubah">
              <Switch />
            </BaseForm.Item>
          </Col>
          <Col xs={24} md={12}>
            <BaseForm.Item name="grant_delete" label="Akses Hapus">
              <Switch />
            </BaseForm.Item>
          </Col>
        </Row>
      </BaseForm>
    </>
  );
};

export default PolicyStatementModal;
