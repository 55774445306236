import React from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { TextArea } from '@app/components/common/inputs/Input/Input';
import { GenericFormProps } from '@app/types/generalTypes';

export const AddressItem: React.FC<GenericFormProps> = ({ metafield, disabled }) => {
  return (
    <BaseButtonsForm.Item
      name="address"
      label="Alamat"
      hasFeedback
      rules={[{ required: !metafield?.nullable, message: 'Harap masukkan alamat' }]}
    >
      <TextArea rows={5} disabled={disabled} />
    </BaseButtonsForm.Item>
  );
};
