/* eslint-disable prettier/prettier */
import { SearchOutlined } from '@ant-design/icons';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm.styles';
import { Pagination } from '@app/api/general.api';
import {
  EntityData,
  deleteEntity,
  getEntityRecordCount,
  getEntityRecords,
  updateEntityRecord,
} from '@app/api/master/entity.api';
import { UOMData } from '@app/api/master/uom.api';
import { Modal } from '@app/components/common/Modal/Modal';
import { notificationController } from '@app/controllers/notificationController';
import { useLoading } from '@app/hooks/useLoading';
import { useMounted } from '@app/hooks/useMounted';
import { formatRupiahPrice, roundingPriceUp } from '@app/utils/utils';
import { Form, Input, Space, TablePaginationConfig } from 'antd';
import { ColumnType } from 'antd/es/table';
import type { FilterConfirmProps, FilterValue } from 'antd/es/table/interface';
import { Table } from 'components/common/Table/Table';
import { Button } from 'components/common/buttons/Button/Button';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditableCell } from '../../editableTable/EditableCell';

const initialPagination: Pagination = {
  pageSize: 10,
  current: 1,
};

export type PackageProductListTableProps = {
  packageId: string | number;
  onChange?: (data: EntityData[]) => void;
  isPagination?: boolean;
  isEditable?: boolean;
  setIsEditing: (isEdit: boolean) => void;
};

export const PackageProductListTable: React.FC<PackageProductListTableProps> = ({
  packageId,
  onChange,
  isPagination = true,
  isEditable = true,
  setIsEditing,
}) => {
  const [form] = Form.useForm();

  const [editingKey, setEditingKey] = useState(0);
  const isEditing = (record: EntityData) => record.id === editingKey;

  const edit = (record: EntityData) => {
    form.setFieldsValue(record);
    setEditingKey(record.id);
    setIsEditing(record.id ? true : false);
  };

  const cancel = () => {
    setIsEditing(false);
    setEditingKey(0);
  };

  const recalculateSubtotal = (record: EntityData) => {
    // calculate the rounding up
    const qty = record.qty || 0;
    const markup = record.markup_percentage || 0;
    const price = record.price || 0;
    const markupPrice = price + price * (markup / 100);
    const onePiecePrice = roundingPriceUp(markupPrice, 3);
    const sequence = record.sequence;

    return {
      ...record,
      sequence: sequence,
      markup_price: markupPrice,
      rounded: onePiecePrice,
      subtotal: onePiecePrice * qty,
    };
  };

  const save = async (id: string) => {
    try {
      const row = (await form.validateFields()) as EntityData;

      const newData = [...tableData.data];
      console.info({ newData });
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        newData[index] = recalculateSubtotal(newData[index]);
      } else {
        newData.push(row);
      }
      setTableData({ ...tableData, data: newData });
      setEditingKey(0);
      setIsEditing(false);

      updateEntityRecord('tt_package_product', newData[index].id, newData[index]).then(() => {
        notificationController.success({ message: 'Sukses Merubah Sequence' });
        fetch(initialPagination);
      });
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const [_, setLoading] = useAtom(useLoading);
  const [deleteModalData, setDeleteModalData] = useState<{
    show: boolean;
    data: EntityData | null;
  }>({
    show: false,
    data: null,
  });

  const [tableData, setTableData] = useState<{
    data: EntityData[];
    pagination?: Pagination;
    loading: boolean;
    q: number | string | boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
    q: '',
  });

  const [recordCount, setRecordCount] = useState<{
    loading: boolean;
    count: number;
  }>({
    loading: false,
    count: 0,
  });

  const { isMounted } = useMounted();
  const navigate = useNavigate();
  const [refetchToken, setRefetch] = useState(0);

  const fetch = useCallback(
    (pagination: Pagination, query?: string) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));

      getEntityRecords('tt_package_product', {
        page: isPagination ? (pagination.current || 1) - 1 : undefined,
        limit: isPagination ? pagination.pageSize || 10 : undefined,
        q: `pkg.id==${packageId}` + (query ? `;${query}` : ''),
        sort: 'sequence ASC',
        inf: !isPagination,
      })
        .then((res) => {
          if (isMounted.current) {
            setTableData({
              data: res.records,
              pagination: isPagination
                ? {
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                  }
                : undefined,
              loading: false,
              q: query || '',
            });
            if (onChange !== undefined) {
              onChange(res.records);
            }
          }
        })
        .catch((err) => {
          console.error(`[Get UOM List Error] - ${err}`);
        });
    },
    [isMounted, refetchToken, packageId],
  );

  const fetchCount = useCallback(
    (pagination: Pagination, query?: string) => {
      setRecordCount((recordCount) => ({ ...recordCount, loading: true }));

      getEntityRecordCount('tt_package_product', {
        page: (pagination.current || 1) - 1,
        limit: pagination.pageSize || 10,
        q: query,
      })
        .then((res) => {
          if (isMounted.current) {
            setRecordCount({
              count: res.count,
              loading: false,
            });
          }
        })
        .catch((err) => {
          console.error(`[Get UOM List Error] - ${err}`);
        });
    },
    [isMounted, refetchToken],
  );

  useEffect(() => {
    fetch(initialPagination);
    fetchCount(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
    if (filters.name) {
      fetchCount(pagination, `name=ilike=${filters.name}`);
      fetch(pagination, `name=ilike=${filters.name}`);
    }
    fetch(pagination);
  };

  const handleDeleteRow = (row: EntityData | null) => {
    setDeleteModalData({
      show: false,
      data: null,
    });

    setLoading({
      isLoading: true,
      message: 'Mohon menunggu...',
    });

    deleteEntity('tt_package_product', row?.id || '')
      .then(() => {
        notificationController.success({ message: 'Sukses menghapus uom' });
        setRefetch(refetchToken + 1);
      })
      .catch((err) => {
        console.error(`[Delete UOM Error] - ${err}`);
        notificationController.error({ message: 'Gagal menghapus uom' });
      })
      .finally(() => {
        setLoading({
          isLoading: false,
        });
      });
  };

  const handleEditRow = (rowId: string | number) => {
    navigate(`/master/uom/${rowId}/update`);
  };

  // Advanced Search
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  type DataIndex = keyof UOMData;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<EntityData> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`cari UOM`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            Cari
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (!record[dataIndex]) return false;
      else
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase());
    },
  });

  let columns = [
    {
      title: 'No',
      dataIndex: 'sequence',
      render: (text: string, record: EntityData) => {
        return <>{record.sequence}</>;
      },
      editable: true,
      inputType: 'number',
    },
    {
      title: 'Produk',
      dataIndex: 'product',
      render: (text: string, record: EntityData) => {
        return <Space>{record.product.name}</Space>;
      },
    },
    {
      title: 'Qty',
      render: (text: string, record: EntityData) => {
        return <>{record.product.qty ?? 0}</>;
      },
    },
    {
      title: 'Unit',
      render: (text: string, record: EntityData) => {
        return <>{record.product.uom.name}</>;
      },
    },
    {
      title: 'Harga Satuan',
      dataIndex: 'price',
      render: (text: string, record: EntityData) => {
        return <>{record.price > 0 ? formatRupiahPrice(record.price) : '-'}</>;
      },
      editable: true,
      inputType: 'number',
    },
    {
      title: 'Total',
      render: (text: string, record: EntityData) => {
        const qty = record.product.qty ?? 0;
        return <>{formatRupiahPrice(record.price * qty)}</>;
      },
    },
  ];

  if (isEditable) {
    const editableColumn = {
      title: '',
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: EntityData) => {
        const editable = isEditing(record);
        return (
          <Space>
            {editable ? (
              <>
                <Button type="primary" onClick={() => save(record.id)}>
                  Simpan
                </Button>
                <Popconfirm title="Batal" onConfirm={cancel}>
                  <Button type="ghost">Batal</Button>
                </Popconfirm>
              </>
            ) : (
              <>
                <Button type="ghost" disabled={editingKey !== 0} onClick={() => edit(record)}>
                  Ubah
                </Button>
                <Button type="default" danger onClick={() => handleDeleteRow(record.id)}>
                  Delete
                </Button>
              </>
            )}
          </Space>
        );
      },
    };
    columns = [...columns, editableColumn];
  }

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: EntityData) => ({
        record,
        inputType: col.inputType || 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        columns={mergedColumns}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        dataSource={tableData.data}
        pagination={isPagination ? { ...tableData.pagination, total: recordCount.count } : false}
        loading={tableData.loading || recordCount.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      <Modal
        title="Konfirmasi"
        visible={deleteModalData.show}
        onOk={() => handleDeleteRow(deleteModalData.data)}
        onCancel={() => setDeleteModalData({ show: false, data: deleteModalData.data })}
      >
        Apakah yakin ingin menghapus {deleteModalData.data?.name}?
      </Modal>
    </Form>
  );
};
