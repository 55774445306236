import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input, TextArea } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { FormInstance } from 'antd/es/form/Form';
import React from 'react';
import { useAtom } from 'jotai';
import { useLoading } from '@app/hooks/useLoading';
import { createEntityRecord, EntityData } from '@app/api/master/entity.api';

export interface ProductCategoryFormProps {
  form: FormInstance<any> | undefined;
  onSubmit: () => void;
}

export const ProductCategoryModalForm: React.FC<ProductCategoryFormProps> = ({ form, onSubmit }) => {
  const [_, setLoading] = useAtom(useLoading);

  const onFinish = (values: EntityData) => {
    setLoading({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    createEntityRecord('c_product_category', values)
      .then((res) => {
        notificationController.success({ message: 'Berhasil menambahkan kategori' });
      })
      .catch((err) => {
        console.error(`[Create Category Modal Form Error] - ${err}`);
        notificationController.error({ message: 'Gagal menambahkan kategori' });
      })
      .finally(() => {
        setLoading({
          isLoading: false,
        });
        form?.resetFields();
        onSubmit();
      });
  };

  return (
    <>
      <BaseForm form={form} onFinish={onFinish}>
        <BaseForm.Item name="name" label="Nama Kategori">
          <Input />
        </BaseForm.Item>
        <BaseForm.Item name="description" label="Deskripsi">
          <TextArea />
        </BaseForm.Item>
      </BaseForm>
    </>
  );
};
