import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { notificationController } from '@app/controllers/notificationController';
import { useMounted } from '@app/hooks/useMounted';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { FormInstance } from 'antd/es/form/Form';
import { EntityData, EntityField, getEntityRecords } from '@app/api/master/entity.api';
import { Pagination } from '@app/api/general.api';
import useDebounce from '@app/hooks/useDebounce';
import { CloseCircleOutlined } from '@ant-design/icons';

export type SelectionFormProps = {
  field: string;
  entity: string;
  label: string;
  form: FormInstance<any> | undefined;
  metafield?: EntityField;
  onChange?: (value?: any) => void;
  onClear?: (value?: any) => void;
  optionLabel?: string;
  disabled?: boolean;
  getFulldata?: boolean;
  additionalQuery?: string;
  required?: boolean;
};

export const SelectionForm: React.FC<SelectionFormProps> = ({
  field,
  entity,
  label,
  form,
  metafield,
  onChange,
  onClear,
  optionLabel,
  disabled = false,
  getFulldata = false,
  additionalQuery = '',
  required = false,
}) => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [data, setData] = useState<Array<any> | null>(null);
  const [fulldata, setFulldata] = useState<Array<any> | null>(null);
  const [refetchToken, setRefetch] = useState(0);
  const { isMounted } = useMounted();
  const [pagination, setPagination] = useState<Pagination>();
  const [query, setQuery] = useState<string>();

  const search = useDebounce(query, 250);

  useEffect(() => {
    fetch();
  }, [refetchToken, search, additionalQuery]);

  const fetch = () => {
    const id = form?.getFieldValue(field);
    let params: {
      page: number;
      limit: number;
      q?: string;
      inf?: boolean;
    } = {
      page: (pagination?.current || 1) - 1,
      limit: pagination?.pageSize || 10,
      inf: true,
    };

    if (id) params = { ...params, q: 'id==' + id };
    if (search) params = { ...params, q: search };

    if (additionalQuery) params = { ...params, q: `${params.q || ''}${params.q ? ';' : ''}${additionalQuery}` };

    getEntityRecords(entity, params)
      .then((res) => {
        setData(
          res.records.map((data: EntityData) => {
            return {
              value: data.id,
              label: optionLabel ? data[optionLabel] : data.name,
            };
          }),
        );
        setFulldata(res.records);
      })
      .catch((err) => {
        console.error(`[Get Selection List Error] ${err}`);
      })
      .finally(() => {
        if (isMounted.current) {
          setFetchLoading(false);
        }
      });
  };

  const loadingItem = () => {
    return (
      <div style={{ textAlign: 'center', padding: '4px' }}>
        <Spin size="small" />
        <div>Mohon menunggu</div>
      </div>
    );
  };

  const handleChange = (value: unknown) => {
    const val = value as string[];
    form?.setFieldValue(field, val);
    if (onChange !== undefined) {
      if (!getFulldata) onChange(value);
      else {
        const selected = fulldata?.find((dt) => dt.id == value);
        onChange(selected);
      }
    }
  };

  const handleSearch = (value: string) => {
    setFetchLoading(true);
    setPagination({
      current: 1,
      pageSize: 10,
    });
    if (value) setQuery(`${optionLabel ? optionLabel : 'name'}=ilike=${value}`);
    else setQuery('');
  };

  const handleClear = () => {
    form?.resetFields([field]);
    setPagination({
      current: 1,
      pageSize: 10,
    });
    setQuery('');
    setFetchLoading(true);
    setRefetch(refetchToken + 1);

    if (onClear) onClear();
  };

  return (
    <>
      <BaseButtonsForm.Item
        required={required}
        name={field}
        label={label}
        rules={[{ required: !metafield?.nullable, message: 'Harap pilih salah satu', type: 'number' }]}
        hasFeedback
      >
        <Select
          defaultValue={form?.getFieldValue(field)}
          showSearch
          onChange={handleChange}
          onSearch={handleSearch}
          onClear={handleClear}
          filterOption={false}
          notFoundContent={fetchLoading ? loadingItem() : 'Tidak ada data'}
          disabled={disabled}
          clearIcon={<CloseCircleOutlined />}
          allowClear
        >
          {data &&
            data.map((dt, idx) => {
              return (
                <Option value={dt.value} key={`${field}-selection-${idx}`}>
                  {dt.label}
                </Option>
              );
            })}
        </Select>
      </BaseButtonsForm.Item>
    </>
  );
};
