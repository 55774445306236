import { atom } from 'jotai';

type LoadingProps = {
  isLoading: boolean;
  message?: string;
};

export const useLoading = atom<LoadingProps>({
  isLoading: false,
  message: '',
});
