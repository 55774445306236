import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import * as S from './SiderMenu.styles';
import { sidebarNavigation, SidebarNavigationItem } from '../sidebarNavigation';
import { Menu } from 'antd';
import { useAtomValue } from 'jotai';
import { StatementListAtom } from '../../MainLayout/MainLayout';
import { getUserDetail } from '@app/utils/utils';
import { EntityData } from '@app/api/master/entity.api';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const sidebarNavFlat = sidebarNavigation.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(current.children && current.children.length > 0 ? current.children : current),
  [],
);

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = sidebarNavigation.find(({ children }) =>
    children?.some(({ url }) => url === location.pathname),
  );
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  const permissions = useAtomValue(StatementListAtom);
  const [userLogin, setUserLogin] = useState<EntityData>();

  useEffect(() => {
    const user = getUserDetail();
    if (user) setUserLogin(user);
  }, []);

  const isPermitted = (entity?: string): boolean => {
    if (userLogin?.is_root) return true;
    if (!entity || !permissions) return false;
    if (!permissions[entity]) return false;

    return permissions[entity].grant_read;
  };

  const singleSidebarItem = (nav: SidebarNavigationItem) => {
    return isPermitted(nav.permission) ? (
      <Menu.Item key={nav.key} title="" icon={nav.icon}>
        <Link to={nav.url || ''}>{t(nav.title)}</Link>
      </Menu.Item>
    ) : null;
  };

  const multiSidebarItem = (nav: SidebarNavigationItem) => {
    let isHaveChildren = false;
    nav.children?.map((child) => {
      if (isPermitted(child.permission)) isHaveChildren = true;
    });

    return isHaveChildren ? (
      <Menu.SubMenu
        key={nav.key}
        title={t(nav.title)}
        icon={nav.icon}
        onTitleClick={() => setCollapsed(false)}
        popupClassName="d-none"
      >
        {nav.children?.map(
          (childNav) =>
            isPermitted(childNav.permission) && (
              <Menu.Item key={childNav.key} title="">
                <Link to={childNav.url || ''}>{t(childNav.title)}</Link>
              </Menu.Item>
            ),
        )}
      </Menu.SubMenu>
    ) : null;
  };

  return (
    <S.Menu
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      onClick={() => setCollapsed(true)}
    >
      <>
        {permissions &&
          sidebarNavigation.map((nav) =>
            nav.children && nav.children.length > 0 ? multiSidebarItem(nav) : singleSidebarItem(nav),
          )}
        {/* {JSON.stringify(permissions)} */}
      </>
    </S.Menu>
  );
};

export default SiderMenu;
