import React, { useEffect } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { LinkButton } from '@app/components/common/buttons/Button/Button';
import { DashboardOutlined, PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Space } from 'antd';
import { QuotationListTable } from '@app/components/tables/master/quotation/QuotationListTable';
import { useNavigate } from 'react-router-dom';
import { checkPagePermission, checkPermission } from '@app/utils/utils';

const ListQuotationPage: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_quotation',
          action: 'READ',
        },
      ])
    )
      navigate('/');
  }, []);

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="#">Penawaran</BreadcrumbItem>
      </Breadcrumb>
      <Card
        title="Dokumen Penawaran"
        padding="1.25rem 1.25rem 0"
        extra={
          checkPermission('tt_quotation', 'CREATE') && (
            <LinkButton size="middle" icon={<PlusOutlined />} to="/transaction/quotation/create">
              Tambah Penawaran
            </LinkButton>
          )
        }
      >
        <QuotationListTable />
      </Card>
    </>
  );
};

export default ListQuotationPage;
