import React, { useCallback, useEffect, useState } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Col, Row } from 'antd';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMounted } from '@app/hooks/useMounted';
import { Loading } from '@app/components/loading';
import dayjs from 'dayjs';
import {
  EntityData,
  EntityFieldsResponse,
  EntityRecordRequest,
  ReportType,
  getEntityFields,
  getEntityRecord,
  getReport,
  updateEntityRecord,
} from '@app/api/master/entity.api';
import { useAtom } from 'jotai';
import { useLoading } from '@app/hooks/useLoading';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Space } from 'antd';
import { DashboardOutlined, PlusOutlined } from '@ant-design/icons';
import { CurrencyInput } from '@app/components/forms/master/CurrencyInput';
import { SelectionForm } from '@app/components/forms/master/SelectionForm';
import { DatePicker } from '@app/components/common/pickers/DatePicker';
import { Button, LinkButton } from '@app/components/common/buttons/Button/Button';
import { InvoiceProductListTable } from '@app/components/tables/master/invoice_product/InvoiceProductListTable';
import { PaymentListTable } from '@app/components/tables/master/payment/PaymentListTable';
import { TabPane, Tabs } from '@app/components/common/Tabs/Tabs';
import { checkPagePermission, checkPermission, getPaymentOutstandingTag } from '@app/utils/utils';

export enum InvoiceTabData {
  DETAIL = '1',
  PRODUCT = '2',
  PAYMENT = '3',
}

const UpdateInvoicePage: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const navigate = useNavigate();
  const { isMounted } = useMounted();
  const params = useParams();

  const [data, setData] = useState<EntityData | null>(null);
  const [metafield, setMetafield] = useState<EntityFieldsResponse>();
  const [_, setLoadingScreen] = useAtom(useLoading);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_invoice',
          action: 'READ',
        },
      ])
    )
      navigate('/');

    if (!params.id) navigate('/transaction/invoice');

    fetchInvoiceData();
    fetchMetafield();
  }, [params.id]);

  const fetchMetafield = () => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    getEntityFields('tt_invoice')
      .then((res) => {
        setMetafield(res);
      })
      .catch((err) => {
        console.error(`[Error Get Invoice Fields] - ${err}`);
      })
      .finally(() => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  };

  const fetchInvoiceData = () => {
    setGetLoading(true);
    getEntityRecord('tt_invoice', params.id!)
      .then((res) => {
        const newData = {
          ...res,
          spk: res.spk.id,
        };
        if (res.date) newData['date'] = dayjs(res.date);
        if (res.due_date) newData['due_date'] = dayjs(res.due_date);
        setData(newData);
      })
      .catch((err) => {
        navigate('/transaction/invoice');
        notificationController.error({
          message: 'Maaf terjadi kesalahan pada sistem. Silahkan coba beberapa saat lagi.',
        });
        console.error(`[Get Invoice Error] - ${err}`);
      })
      .finally(() => {
        if (isMounted.current) {
          setGetLoading(false);
        }
      });
  };

  const onFinish = useCallback((values: EntityData) => {
    // todo dispatch an action here
    setLoading(true);
    setTimeout(() => {
      setFieldsChanged(false);
      // call create country API
      const body: EntityRecordRequest = {
        ...values,
        spk: {
          id: values.spk,
        },
      };

      if (values.date) body['date'] = dayjs(values.date).format('YYYY-MM-DD 00:00:00');
      if (values.due_date) body['due_date'] = dayjs(values.due_date).format('YYYY-MM-DD 00:00:00');
      updateEntityRecord('tt_invoice', params.id!, body)
        .then((res) => {
          notificationController.success({ message: 'Sukses mengubah invoice' });
        })
        .catch((err) => {
          console.error(`[Update Invoice Error] - ${err}`);
          notificationController.error({ message: 'Gagal mengubah invoice' });
        })
        .finally(() => {
          if (isMounted.current) {
            setLoading(false);
          }
        });
    }, 1000);
  }, []);

  const [form] = BaseButtonsForm.useForm();

  const generateReport = () => {
    getReport('tt_invoice', params.id!, ReportType.INVOICE);
  };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="/transaction/invoice">Invoice</BreadcrumbItem>
        <BreadcrumbItem href="#">Ubah</BreadcrumbItem>
      </Breadcrumb>
      <Row gutter={[30, 30]}>
        {/* Main Data */}
        <Col xs={24} md={24}>
          <Tabs defaultActiveKey={query.get('tab') || '1'}>
            <TabPane tab="Invoice" key={InvoiceTabData.DETAIL}>
              <Card
                title={<Space direction="horizontal">Status {getPaymentOutstandingTag(data?.outstanding)}</Space>}
                padding="1.25rem"
                extra={
                  <Space direction="horizontal">
                    <>
                      <Button onClick={generateReport}>Laporan</Button>
                    </>
                  </Space>
                }
              >
                {getLoading ? (
                  <Loading message="Sedang mengambil data" />
                ) : (
                  <BaseButtonsForm
                    form={form}
                    name="Edit Invoice"
                    isFieldsChanged={isFieldsChanged}
                    onFieldsChange={() => setFieldsChanged(true)}
                    setFieldsChanged={setFieldsChanged}
                    onFinish={onFinish}
                    loading={isLoading}
                    initialValues={data || undefined}
                  >
                    <Row gutter={{ xs: 10, md: 15 }}>
                      <Col xs={24} md={8}>
                        <BaseButtonsForm.Item
                          name="code"
                          label="Kode"
                          rules={[
                            {
                              required: !metafield?.code.nullable,
                              message: 'Harap masukkan kode invoice',
                              type: 'string',
                            },
                          ]}
                        >
                          <Input disabled />
                        </BaseButtonsForm.Item>
                      </Col>

                      <Col xs={24} md={8}>
                        <BaseButtonsForm.Item
                          name="date"
                          label="Tanggal"
                          rules={[{ required: !metafield?.date.nullable, message: 'Harap masukkan tanggal invoice' }]}
                        >
                          <DatePicker
                            format="DD-MM-YYYY"
                            style={{ width: '100%' }}
                            placeholder="Pilih tanggal"
                            disabled
                          />
                        </BaseButtonsForm.Item>
                      </Col>

                      <Col xs={24} md={8}>
                        <BaseButtonsForm.Item
                          name="due_date"
                          label="Tanggal Jatuh Tempo"
                          rules={[
                            {
                              required: !metafield?.due_date.nullable,
                              message: 'Harap masukkan tanggal jatuh tempo',
                            },
                          ]}
                        >
                          <DatePicker
                            format="DD-MM-YYYY"
                            style={{ width: '100%' }}
                            placeholder="Pilih tanggal"
                            disabled
                          />
                        </BaseButtonsForm.Item>
                      </Col>
                    </Row>

                    <BaseButtonsForm.Item>
                      <BaseButtonsForm.Title>Data SPK</BaseButtonsForm.Title>
                    </BaseButtonsForm.Item>

                    <Row gutter={{ xs: 10, md: 15 }}>
                      <Col xs={24} md={8}>
                        <SelectionForm
                          entity="tt_spk"
                          field="spk"
                          form={form}
                          label="SPK"
                          onChange={() => setFieldsChanged(true)}
                          metafield={metafield?.spk}
                          optionLabel="code"
                          disabled
                        />
                      </Col>
                    </Row>

                    <Row gutter={{ xs: 10, md: 15 }}>
                      <Col xs={24} md={8}>
                        <BaseButtonsForm.Item
                          name="total"
                          label="Total"
                          rules={[{ required: !metafield?.total.nullable, message: 'Harap masukkan total' }]}
                        >
                          <CurrencyInput disabled />
                        </BaseButtonsForm.Item>
                      </Col>
                      <Col xs={24} md={8}>
                        <BaseButtonsForm.Item
                          name="paid"
                          label="Terbayar"
                          rules={[{ required: !metafield?.paid.nullable, message: 'Harap masukkan terbayar' }]}
                        >
                          <CurrencyInput disabled />
                        </BaseButtonsForm.Item>
                      </Col>
                      <Col xs={24} md={8}>
                        <BaseButtonsForm.Item
                          name="outstanding"
                          label="Sisa"
                          rules={[{ required: !metafield?.outstanding.nullable, message: 'Harap masukkan sisa' }]}
                        >
                          <CurrencyInput disabled />
                        </BaseButtonsForm.Item>
                      </Col>
                    </Row>
                  </BaseButtonsForm>
                )}
              </Card>
            </TabPane>
            {params.id && checkPermission('tt_invoice_product', 'READ') && (
              <TabPane tab="Produk" key={InvoiceTabData.PRODUCT}>
                <Card title="" padding="1.25rem">
                  <InvoiceProductListTable invoiceId={params.id} />
                </Card>
              </TabPane>
            )}
            {params.id && checkPermission('tt_payment', 'READ') && (
              <TabPane tab="Pembayaran" key={InvoiceTabData.PAYMENT}>
                <Card
                  title=""
                  padding="1.25rem"
                  extra={
                    data &&
                    data.outstanding > 0 &&
                    checkPermission('tt_payment', 'CREATE') && ( // can only do payment when there is outstanding amount
                      <Space>
                        <LinkButton
                          size="middle"
                          icon={<PlusOutlined />}
                          to={`/transaction/payment/create?invoice=${params.id}`}
                        >
                          Tambah Pembayaran
                        </LinkButton>
                      </Space>
                    )
                  }
                >
                  <PaymentListTable invoiceId={params.id} />
                </Card>
              </TabPane>
            )}
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

export default UpdateInvoicePage;
