import React from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Input } from '@app/components/common/inputs/Input/Input';

export const PasswordItem: React.FC = () => {
  return (
    <BaseButtonsForm.Item
      name="password"
      label="Password"
      rules={[{ required: true, message: 'Harap masukkan password' }]}
    >
      <Input type="password" />
    </BaseButtonsForm.Item>
  );
};
