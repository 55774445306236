import { InfoCircleOutlined } from '@ant-design/icons';
import { Pagination } from '@app/api/general.api';
import { EntityData, deleteEntity, getEntityRecordCount, getEntityRecords } from '@app/api/master/entity.api';
import { Modal } from '@app/components/common/Modal/Modal';
import { notificationController } from '@app/controllers/notificationController';
import { VendorPayment as VendorPaymentRecord } from '@app/domain';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useLoading } from '@app/hooks/useLoading';
import { useMounted } from '@app/hooks/useMounted';
import { addPaymentRecords, selectPaymentRecord, setOffset } from '@app/store/slices/vendorPaymentSlice';
import { formatFullDate, getStatusLabelTag } from '@app/utils/utils';
import { Space, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/es/table';
import type { FilterValue } from 'antd/es/table/interface';
import { Table } from 'components/common/Table/Table';
import { useAtom } from 'jotai';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const initialPagination: Pagination = {
  pageSize: 10,
  current: 1,
};

const VendorPaymentListTable: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selected: selectedPayment, offset, records: paymentRecords } = useAppSelector((state) => state.vendorPayment);
  const { isMounted } = useMounted();
  const [refetchToken, setRefetch] = useState(0);
  const [_, setLoading] = useAtom(useLoading);
  const [deleteModalData, setDeleteModalData] = useState<{
    show: boolean;
    data: EntityData | null;
  }>({
    show: false,
    data: null,
  });
  const [tableData, setTableData] = useState<{
    data: EntityData[];
    pagination: Pagination;
    loading: boolean;
    q: number | string | boolean;
  }>({
    data: [],
    pagination: { ...initialPagination, current: offset + 1 },
    loading: false,
    q: '',
  });
  const [recordCount, setRecordCount] = useState<{
    loading: boolean;
    count: number;
  }>({
    loading: false,
    count: 0,
  });

  useEffect(() => {
    dispatch(selectPaymentRecord(undefined));
    const iniPage: Pagination = { ...initialPagination, current: offset + 1 };
    fetch(iniPage);
    fetchCount(iniPage);
  }, []);

  useEffect(() => {
    setTableData({ ...tableData, data: paymentRecords as EntityData[], loading: false });
  }, [paymentRecords]);

  const fetch = useCallback(
    (pagination: Pagination, query?: string) => {
      setTableData((tableData) => ({
        ...tableData,
        pagination: {
          current: pagination.current,
          pageSize: pagination.pageSize,
        },
        q: query || '',
        loading: true,
      }));
      dispatch(setOffset((pagination.current ?? 0) - 1));
      getEntityRecords('tt_vendor_payment', {
        page: (pagination.current || 1) - 1,
        limit: pagination.pageSize || 10,
        q: query,
        sort: 'createdDate DESC',
      })
        .then((res) => {
          dispatch(addPaymentRecords({ nextPage: res.next_page, records: res.records as VendorPaymentRecord[] }));
        })
        .catch((err) => {
          console.error(`[Get Vendor Commission List Error] - ${err}`);
        });
    },
    [isMounted, refetchToken],
  );

  const fetchCount = useCallback(
    (pagination: Pagination, query?: string) => {
      setRecordCount((recordCount) => ({ ...recordCount, loading: true }));
      getEntityRecordCount('tt_vendor_payment', {
        page: (pagination.current || 1) - 1,
        limit: pagination.pageSize || 10,
        q: query,
      })
        .then((res) => {
          if (isMounted.current) {
            setRecordCount({
              count: res.count,
              loading: false,
            });
          }
        })
        .catch((err) => {
          console.error(`[Get Vendor Commission List Error] - ${err}`);
        });
    },
    [isMounted, refetchToken],
  );

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
    if (filters.name) {
      fetchCount(pagination, `name=ilike=${filters.name}`);
      fetch(pagination, `name=ilike=${filters.name}`);
    }
    fetch(pagination);
  };

  const handleDeleteRow = (row: EntityData | null) => {
    setDeleteModalData({
      show: false,
      data: null,
    });

    setLoading({
      isLoading: true,
      message: 'Mohon menunggu...',
    });

    deleteEntity('tt_vendor_payment', row?.id || '')
      .then(() => {
        notificationController.success({ message: 'Sukses menghapus komisi vendor' });
        setRefetch(refetchToken + 1);
      })
      .catch((err) => {
        console.error(`[Delete Vendor Commission Error] - ${err}`);
        notificationController.error({ message: 'Gagal menghapus komisi vendor' });
      })
      .finally(() => {
        setLoading({
          isLoading: false,
        });
      });
  };

  const onNavigate = (record: EntityData) => {
    dispatch(selectPaymentRecord(record as VendorPaymentRecord));
    setTimeout(() => navigate(`/master/vendor-payment/${record.id}/detail`), 75);
  };

  const columns: ColumnsType<EntityData> = [
    {
      title: 'Kode',
      render: (text: string, record: EntityData) => {
        return <Space direction="vertical">{record.code}</Space>;
      },
    },
    {
      title: 'Vendor',
      render: (text: string, record: EntityData) => {
        return <Space direction="vertical">{record.vendor.name}</Space>;
      },
    },
    {
      title: 'Tipe Instalasi',
      render: (text: string, record: EntityData) => {
        return <Space direction="vertical">{record.installation_type}</Space>;
      },
    },
    {
      title: 'Tanggal Instalasi',
      render: (text: string, record: EntityData) => {
        return record.installation_date ? formatFullDate(record.installation_date) : '';
      },
    },
    {
      title: 'Status',
      render: (text: string, record: EntityData) => {
        return getStatusLabelTag(record.state);
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      width: '5%',
      render: (_, record: EntityData) => (
        <InfoCircleOutlined
          onClick={() => onNavigate(record)}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px', color: 'black' }}
        />
      ),
    },
  ];

  const renderTable = useMemo(
    () => (
      <Table
        columns={columns}
        dataSource={tableData.data}
        rowKey={(record) => `spk-payment-${record.id}`}
        pagination={{ ...tableData.pagination, total: recordCount.count }}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
    ),
    [columns, tableData.data, tableData.pagination, tableData.loading, recordCount.count],
  );

  return (
    <>
      {renderTable}
      <Modal
        title="Konfirmasi"
        open={deleteModalData.show}
        onOk={() => handleDeleteRow(deleteModalData.data)}
        onCancel={() => setDeleteModalData({ show: false, data: deleteModalData.data })}
      >
        Apakah yakin ingin menghapus {deleteModalData.data?.code}?
      </Modal>
    </>
  );
};

export default memo(VendorPaymentListTable);
