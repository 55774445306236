import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoginForm } from '@app/components/auth/LoginForm/LoginForm';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { H5 } from '@app/components/common/typography/H5/H5';
import { version } from '../../package.json';

const LoginPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('common.login')}</PageTitle>
      <LoginForm />
      <br />
      <H5>{version ? `v${version}` : ''}</H5>
    </>
  );
};

export default LoginPage;
