import { InfoCircleOutlined } from '@ant-design/icons';
import { EntityData } from '@app/api/master/entity.api';
import { Table as AntTable, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';

const VendorBulkListTable: React.FC = () => {
  const renderTableSummary = (data: EntityData[]) => {
    return (
      <>
        <AntTable.Summary.Row style={{ backgroundColor: '#f8f8f8' }}>
          <AntTable.Summary.Cell index={0} />
          <AntTable.Summary.Cell index={1} />
          <AntTable.Summary.Cell index={2}>
            <div style={{ fontWeight: 'bold', textAlign: 'left', width: '100%' }}>Total:</div>
          </AntTable.Summary.Cell>
          <AntTable.Summary.Cell index={3}>
            <div style={{ fontWeight: 'bold', textAlign: 'left', width: '100%' }}>
              {data.reduce((p, c) => p + c.total, 0).toLocaleString('id')}
            </div>
            <AntTable.Summary.Cell index={4} />
          </AntTable.Summary.Cell>
        </AntTable.Summary.Row>
      </>
    );
  };

  const columns: ColumnsType<EntityData> = [
    {
      title: 'No. SPK',
      dataIndex: 'code',
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
    },
    {
      title: 'Teknisi',
      dataIndex: 'teknisi',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      render: (_, record: EntityData) => {
        return <span style={{ textAlign: 'left' }}>{record.total.toLocaleString('id')}</span>;
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (_, record: EntityData) => (
        <InfoCircleOutlined
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px', color: 'black' }}
        />
      ),
    },
  ];

  const dataSource = [
    {
      code: '1',
      vendor: 'Mike',
      teknisi: 'Mike Tech',
      total: 10000,
    },
    {
      code: '2',
      vendor: 'John',
      teknisi: 'John Tech',
      total: 30000,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ x: 800 }}
        summary={(data) => renderTableSummary(data as EntityData[])}
        bordered
      />
    </>
  );
};

export default VendorBulkListTable;
