import React from 'react';
import { UsernameItem } from './UsernameItem';
import { Col, Row } from 'antd';
import { PasswordItem } from './PasswordItem';

interface UserFormProps {
  hidePassword?: boolean;
}

export const UserForm: React.FC<UserFormProps> = ({ hidePassword = false }) => {
  return (
    <>
      <Row gutter={{ xs: 10, md: 15 }}>
        <Col xs={24} sm={24} md={12}>
          <UsernameItem />
        </Col>
        {!hidePassword && (
          <Col xs={24} sm={24} md={12}>
            <PasswordItem />
          </Col>
        )}
      </Row>
    </>
  );
};
