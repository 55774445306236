import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Image, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { VerificationCodeInput } from '@app/components/common/VerificationCodeInput/VerificationCodeInput';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doVerifySecurityCode } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import VerifyEmailImage from '@app/assets/images/verify-email.webp';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SecurityCodeForm.styles';

export const SecurityCodeForm: React.FC = () => {
  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" requiredMark="optional">
        <S.ContentWrapper>
          <S.ImageWrapper>
            <Image src={VerifyEmailImage} alt="Not found" preview={false} />
          </S.ImageWrapper>
          <Auth.FormTitle>Harap cek email / whatsapp</Auth.FormTitle>
          <S.VerifyEmailDescription>
            Hubungi admin apabila belum mendapatkan link reset password
          </S.VerifyEmailDescription>
        </S.ContentWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
