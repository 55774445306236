export enum TransactionType {
  DRAFT = 'DRAFT',
  IN_PROGRESS = 'IN_PROGRESS',
  PROCESSED = 'PROCESSED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',

  // spk
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
  WAITING_PAYMENT_TO_VENDOR = 'WAITING_PAYMENT_TO_VENDOR',
}
