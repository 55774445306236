import { httpApi } from '@app/api/http.api';
import { TransactionType } from '@app/constants/transaction';
import { notificationController } from '@app/controllers/notificationController';
import { checkPermission } from '@app/utils/utils';
import { UploadFile } from 'antd';
import { RcFile } from 'antd/lib/upload';

export enum ReportType {
  QUOTATION = 'report_quotation',
  SPK = 'report_spk',
  INVOICE = 'report_invoice',
}

export interface EntityRecordFilter {
  q?: string;
  sort?: string;
  page?: number;
  limit?: number;
  inf?: boolean;
}

export interface EntityField {
  property_name: string;
  response_key: string;
  type: string;
  nullable: boolean;
  system_generated: boolean;
  reference?: string;
  expected_values?: Array<string>;
}

export interface EntityData {
  [field: string]: string | number | any;
}

export interface EntityFieldsResponse {
  [field: string]: EntityField;
}

export interface EntityRecordForeignRequest {
  [field: string]: string | number | boolean;
}

export interface EntityRecordRequest {
  [field: string]: string | number | boolean | EntityRecordForeignRequest | null;
}

export interface EntityTableRecord {
  records: Array<EntityData>;
  next_page: boolean;
}

export interface EntityTableCount {
  count: number;
}

export const getEntityRecords = (entity: string, params: EntityRecordFilter): Promise<EntityTableRecord> => {
  const filteredParams = { ...params };
  if (!filteredParams.q) delete filteredParams['q'];
  return httpApi.get<EntityTableRecord>(`entity/${entity}`, { params: filteredParams }).then(({ data }) => data);
};

export const getEntityRecordCount = (entity: string, params: EntityRecordFilter): Promise<EntityTableCount> => {
  const filteredParams = { ...params };
  if (!filteredParams.q) delete filteredParams['q'];
  return httpApi.get<EntityTableCount>(`entity/${entity}/count`, { params: filteredParams }).then(({ data }) => data);
};

export const getEntityFields = (entity: string): Promise<EntityFieldsResponse> =>
  httpApi.get<EntityFieldsResponse>(`entity/${entity}/fields`).then(({ data }) => data);

export const createEntityRecord = (entity: string, params: EntityRecordRequest): Promise<EntityData> =>
  httpApi.post<EntityData>(`entity/${entity}`, params).then(({ data }) => data);

export const updateEntityRecord = async (
  entity: string,
  entityId: string | number,
  params: EntityRecordRequest,
): Promise<EntityFieldsResponse> => {
  // check permission
  if (!checkPermission(entity, 'UPDATE')) {
    notificationController.error({ message: 'Tidak punya akses untuk melakukan update' });
    return Promise.reject();
  }

  const { data } = await httpApi.put<EntityFieldsResponse>(`entity/${entity}/${entityId}`, params);
  return data;
};

export const deleteEntity = (entity: string, entityId: string | number): Promise<undefined> =>
  httpApi.put<undefined>(`entity/${entity}/${entityId}/delete`).then(({ data }) => {
    return data;
  });

export const getEntityRecord = (entity: string, entityId: string | number): Promise<any> =>
  httpApi.get<any>(`entity/${entity}/${entityId}`).then(({ data }) => data);

// custom API
export const changeUserPassword = (userId: string | number, oldPassword: string, newPassword: string): Promise<any> =>
  httpApi
    .post<any>(`entity/s_user/${userId}/pass`, { old_password: oldPassword, new_password: newPassword })
    .then(({ data }) => data);

export const changeUsername = (userId: string | number, username: string): Promise<any> => {
  return httpApi
    .put<any>(`entity/s_user/${userId}`, {
      username,
    })
    .then(({ data }) => data);
};

export const uploadPicture = (filename: string, file: UploadFile): Promise<EntityData> => {
  const formData = new FormData();
  formData.append('file', file as RcFile);
  return httpApi
    .post<EntityData>(`media/${filename}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(({ data }) => data);
};

export const getPicture = (imageId: string | number): Promise<any> =>
  httpApi.get<any>(`media/${imageId}`, { responseType: 'blob' }).then(({ data }) => URL.createObjectURL(data));

export const getEntityMedia = (entityType: string, question: string) => {
  return httpApi.get(`entity/${entityType}?q=${question}`);
};

// update transaction status
export const updateTransactionStatus = (
  entity: string,
  recordId: string,
  status: TransactionType,
): Promise<EntityData> => {
  let statusEndpoint = 'status_process';
  if (status == TransactionType.IN_PROGRESS) statusEndpoint = 'status_in_progress';
  else if (status == TransactionType.CANCELLED) statusEndpoint = 'status_cancelled';
  else if (status == TransactionType.COMPLETED) statusEndpoint = 'status_completed';
  else if (status == TransactionType.WAITING_FOR_PAYMENT) statusEndpoint = 'status_waiting_for_payment';
  else if (status == TransactionType.WAITING_PAYMENT_TO_VENDOR) statusEndpoint = 'status_waiting_payment_to_vendor';

  return httpApi
    .post<EntityData>(`entity/${entity}/${recordId}/fun/${statusEndpoint}`, { ids: [parseInt(recordId)] })
    .then(({ data }) => data);
};

export const getReport = (entity: string, recordId: string, reportType: ReportType): Promise<any> =>
  httpApi
    .get<any>(`entity/${entity}/${recordId}/report/${reportType}`, { responseType: 'blob' })
    .then(({ data }) => window.open(URL.createObjectURL(data)));

export const createSPKfromQuotation = (quotationId: string): Promise<any> =>
  httpApi
    .post<any>(`entity/tt_quotation/${quotationId}/fun/create_spk`, { ids: [parseInt(quotationId)] })
    .then(({ data }) => data);

export const forgotPassword = (username: string): Promise<any> =>
  httpApi.post<any>(`auth/forgot_password`, { username: username }).then(({ data }) => data);

export const sendToken = (username: string, type: string): Promise<any> =>
  httpApi.get<any>(`auth/send_token?username=${username}&type=${type}`).then(({ data }) => data);

export const resetPassword = (token: string, password: string): Promise<any> =>
  httpApi.post<any>(`auth/reset_password`, { token: token, password: password }).then(({ data }) => data);

export const getQRCode = (entity: string, recordId: string): Promise<any> =>
  httpApi
    .get<any>(`entity/${entity}/${recordId}/get/download_qr`, { responseType: 'blob' })
    .then(({ data }) => window.open(URL.createObjectURL(data)));

export const dashboard = (entity: string): Promise<any> =>
  httpApi.get<any>(`dashboard/${entity}`).then(({ data }) => data);

export const getStatementList = (): Promise<any> => httpApi.get<any>(`policy-statement-list`).then(({ data }) => data);

export const getEntityList = (): Promise<any> => httpApi.get<any>(`entity-list`).then(({ data }) => data);

export const getVendorPaymentDetailWithType = (type: string, id: string) =>
  httpApi.get<any>(`entity/${type}?q=vendorPayment.id==${id}`).then(({ data }) => data);

export const getVendorPaymentDetail = (entityName: string, id: string) =>
  httpApi.get<any>(`entity/${entityName}/${id}`).then(({ data }) => data);
