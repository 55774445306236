import { Card } from '@app/components/common/Card/Card';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React from 'react';
import styled from 'styled-components';
import * as S from 'components/layouts/AuthLayout/AuthLayout.styles';

const Numbering = styled.li`
  margin-left: 1rem;
  margin-bottom: 0.5rem;
`;

const Header = styled.p`
  font-weight: 700;
`;

const NoBottom = styled.p`
  margin-bottom: 0;
`;

const TermAndConditionPage = () => {
  const content = () => {
    return (
      <>
        <Header>Kebijakan Privasi Penggunaan Layanan TERBAIKTRONICS App</Header>
        <p>
          TERBAIKTRONICS App sangat menghormati privasi milik pengguna TERBAIKTRONICS App. Seluruh penggunaan data milik
          pengguna akan tunduk pada Kebijakan Privasi ini. Penggunaan situs/mobile apps secara umum dan hubungan
          TERBAIKTRONICS App dengan pengguna tunduk pada Kebijakan Privasi ini. Kebijakan Privasi ini menjelaskan
          bagaimana TERBAIKTRONICS App mengumpulkan informasi dan menggunakan informasi yang dikumpulkan dari pengguna.
        </p>
        <p>
          Data pribadi yang diberikan kepada TERBAIKTRONICS App haruslah data yang benar, akurat, lengkap, jelas, sesuai
          dan tidak menyesatkan. Data pribadi yang diterima TERBAIKTRONICS App akan digunakan selama pengguna tersebut
          aktif. Apabila pengguna melakukan perubahan data, pengguna harus menginformasikan perubahan tersebut pada
          TERBAIKTRONICS App.
        </p>
        <Header>Penggunaan Data Pribadi</Header>
        <p>
          TERBAIKTRONICS App mengumpulkan dan menggunakan keseluruhan data pengguna sebagai acuan untuk upaya
          peningkatan kualitas pelayanan TERBAIKTRONICS App kepada pengguna. TERBAIKTRONICS App Akan menghubungi
          pengguna melalui media email, telepon, WhatsApp, SMS, dan media sosial.
        </p>
        <p>Segala data pribadi milik pengguna adalah untuk:</p>
        <ol>
          <Numbering>Berkomunikasi dengan pengguna layanan TERBAIKTRONICS App.</Numbering>
          <Numbering>Untuk pengembangan website dan Aplikasi TERBAIKTRONICS App.</Numbering>
          <Numbering>Untuk melakukan layanan kepada pengguna.</Numbering>
        </ol>
        <Header>Perubahan Kebijakan Privasi</Header>
        <p>
          TERBAIKTRONICS App berhak untuk mengubah, menambah, mengurangi, atau mengganti beberapa ketentuan mengenai
          kebijakan privasi. Setiap perubahan akan dipublikasikan pada Email/aplikasi.
        </p>
        <Header>Hak TERBAIKTRONICS App</Header>
        <p>
          Pengguna memahami dan menyetujui bahwa TERBAIKTRONICS App memiliki hak untuk mengungkapkan informasi pribadi
          milik pengguna apabila diperintahkan oleh hukum, peraturan, pengadilan yang berwenang, atau instansi lain yang
          berwenang.
        </p>
        <Header>Pengungkapan Informasi Pengguna</Header>
        <p>
          TERBAIKTRONICS App menjamin tidak ada penjualan, pengalihan, distribusi atau meminjamkan informasi/ data
          pribadi pengguna kepada Pihak Ketiga tanpa izin dari pengguna.
        </p>
        <p>Kecuali untuk hal berikut:</p>
        <ol>
          <Numbering>
            TERBAIKTRONICS App wajib mengungkapkan dan/atau berbagi data pribadi pengguna dalam upaya mematuhi kewajiban
            hukum dan/ atau dalam upaya memberlakukan atau menerapkan syarat-syarat penggunaan sebagaimana tercantum
            dalam Syarat dan Ketentuan TERBAIKTRONICS App dan/ atau perikatan-perikatan lainnya antara TERBAIKTRONICS
            App dengan Pihak Ketiga, atau untuk melindungi hak, properti, atau keselamatan TERBAIKTRONICS App dan
            Pelanggan TERBAIKTRONICS App atau pihak lain.
          </Numbering>
          <Numbering>
            TERBAIKTRONICS App berupaya untuk melindungi hak, properti, keselamatan, nama baik pengguna atau pihak lain.
            Termasuk pertukaran informasi dengan perusahaan dan organisasi lain untuk tujuan pencegahan dan penindakan
            segala tindakan yang dapat merugikan pengguna termasuk namun tidak terbatas pada penipuan, kecurangan,
            kerugian financial dan resiko lainnya.
          </Numbering>
        </ol>
        <Header>Pengaduan Pelanggaran Privasi</Header>
        <NoBottom>Jika ada pertanyaan seputar kebijakan privasi ini.</NoBottom>
        <p>Anda dapat menghubungi kami dengan menggunakan informasi di bawah ini.</p>
        <p>ETHERVAL IT Consultancy</p>
        <p>Surabaya, Indonesia</p>
        <NoBottom>Telp: 0813078217</NoBottom>
        <p>Email: info@etherval.id</p>
      </>
    );
  };

  return (
    <>
      <PageTitle>Term and Condition</PageTitle>
      <S.Wrapper>
        <S.BackgroundWrapper>
          <S.LoginWrapper>
            <S.TermWrapper>{content()}</S.TermWrapper>
          </S.LoginWrapper>
        </S.BackgroundWrapper>
      </S.Wrapper>
    </>
  );
};

export default TermAndConditionPage;
