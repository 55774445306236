import React, { useCallback, useEffect, useState } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Col, Row } from 'antd';
import { Input, TextArea } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { useMounted } from '@app/hooks/useMounted';
import { useAtom } from 'jotai';
import { useLoading } from '@app/hooks/useLoading';
import {
  createEntityRecord,
  EntityData,
  EntityFieldsResponse,
  EntityRecordRequest,
  getEntityFields,
} from '@app/api/master/entity.api';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Space } from 'antd';
import { DashboardOutlined } from '@ant-design/icons';
import { H3 } from '@app/components/common/typography/H3/H3';
import { Button } from '@app/components/common/buttons/Button/Button';
import GroupPolicyTable from './components/GroupPolicyTable';
import { Modal } from '@app/components/common/Modal/Modal';
import GroupPolicyModal from './components/GroupPolicyModal';

const CreateGroupPage: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isMounted } = useMounted();
  const [openModal, setOpenModal] = useState(false);

  const [form] = BaseButtonsForm.useForm();
  const [createForm] = BaseButtonsForm.useForm();
  const [refreshToken, setRefreshToken] = useState(0);
  const [_, setLoadingScreen] = useAtom(useLoading);
  const [metafield, setMetafield] = useState<EntityFieldsResponse>();
  const [groupPolicy, setGroupPolicy] = useState<EntityData[]>([]);
  const [loadingFinish, setLoadingFinish] = useState(0);

  useEffect(() => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    getEntityFields('s_group')
      .then((res) => {
        setMetafield(res);
      })
      .catch((err) => {
        console.error(`[Error Get Group Fields] - ${err}`);
      })
      .finally(() => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  }, [refreshToken]);

  const onFinish = useCallback(
    (values: EntityData) => {
      if (groupPolicy.length === 0) {
        notificationController.error({ message: 'Mohon tambahkan minimal 1 hak akses' });
        return;
      }
      setLoadingScreen({
        isLoading: true,
        message: 'Mohon menunggu...',
      });
      setTimeout(() => {
        setFieldsChanged(false);

        createGroup(values, (groupId) => {
          setLoadingFinish(groupPolicy?.length || 0);

          groupPolicy.forEach(async (policy) => {
            const params: EntityRecordRequest = {
              group: {
                id: groupId,
              },
              policy: {
                id: policy.id,
              },
            };
            await createEntityRecord('s_group_policy', params)
              .then((res) => {
                if (loadingFinish === 0) {
                  notificationController.success({ message: 'Sukses menambah grup akses' });
                  navigate(`/permission/group/${groupId}/update`);
                }
              })
              .catch((err) => {
                console.error(`[Create Group Policy Error] - ${err}`);
                notificationController.error({ message: 'Gagal menambah grup akses' });
              })
              .finally(() => {
                setLoadingFinish(loadingFinish - 1);
              });
          });
        });
      }, 1000);
    },
    [groupPolicy],
  );

  const createGroup = (values: EntityData, onSuccess: (groupId: string) => void) => {
    createEntityRecord('s_group', values)
      .then((res) => onSuccess(res.id))
      .catch((err) => {
        console.error(`[Create Group Error] - ${err}`);
        notificationController.error({ message: 'Gagal menambah grup akses' });
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  };

  const addGroupPolicy = (values: any) => {
    if (groupPolicy.findIndex((group) => group.id == values.id) > -1) {
      notificationController.error({ message: 'Akses sudah ditambahkan ke dalam grup ini.' });
      return;
    }

    setGroupPolicy([...groupPolicy, values]);
    setOpenModal(false);
    createForm.resetFields();
  };

  const deleteGroupPolicy = (name: string) => {
    const newGroupPolicy = [...groupPolicy];
    const deletedIndex = newGroupPolicy.findIndex((statement) => statement.name == name);

    newGroupPolicy.splice(deletedIndex, 1);
    if (deletedIndex > -1) setGroupPolicy([...newGroupPolicy]);
  };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="/permission/group">Grup Akses</BreadcrumbItem>
        <BreadcrumbItem href="#">Tambah</BreadcrumbItem>
      </Breadcrumb>
      <Row gutter={[30, 30]}>
        {/* Main Data */}
        <Col xs={24} md={24}>
          <Card title="Data Grup Akses" padding="1.25rem">
            <BaseButtonsForm
              form={form}
              name="Tambah Grup Akses"
              isFieldsChanged={isFieldsChanged}
              onFieldsChange={() => setFieldsChanged(true)}
              setFieldsChanged={setFieldsChanged}
              onFinish={onFinish}
              loading={isLoading}
            >
              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  <BaseButtonsForm.Item
                    name="name"
                    label="Nama Grup Akses"
                    rules={[
                      {
                        required: !metafield?.name.nullable,
                        message: 'Harap masukkan nama grup akses',
                        type: 'string',
                      },
                    ]}
                  >
                    <Input />
                  </BaseButtonsForm.Item>
                </Col>

                <Col xs={24} md={12}>
                  <BaseButtonsForm.Item
                    name="description"
                    label="Deskripsi"
                    rules={[
                      {
                        required: !metafield?.description.nullable,
                        message: 'Harap masukkan deskripsi',
                      },
                    ]}
                  >
                    <TextArea />
                  </BaseButtonsForm.Item>
                </Col>
              </Row>

              <div
                style={{
                  display: 'flex',
                  placeContent: 'space-between',
                  margin: '12px 0',
                  alignItems: 'center',
                }}
              >
                <H3>Hak Akses</H3>
                <Button onClick={() => setOpenModal(true)}>Tambah Akses</Button>
              </div>

              <GroupPolicyTable data={groupPolicy} onDelete={deleteGroupPolicy} />
              <br />
            </BaseButtonsForm>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Tambah Hak Akses"
        open={openModal}
        onOk={() => createForm.submit()}
        onCancel={() => setOpenModal(false)}
        destroyOnClose
      >
        <GroupPolicyModal form={createForm} onFinish={addGroupPolicy} />
      </Modal>
    </>
  );
};

export default CreateGroupPage;
