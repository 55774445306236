import { EntityData } from '@app/api/master/entity.api';
import { Card } from '@app/components/common/Card/Card';
import { Table } from '@app/components/common/Table/Table';
import { formatDateToString } from '@app/utils/utils';
import { ColumnsType } from 'antd/es/table';
import React, { FC } from 'react';

const ProgressTab: FC = () => {
  const tableColumns: ColumnsType<EntityData> = [
    {
      title: 'Tanggal',
      render: (_: string, record: EntityData) => {
        return formatDateToString(record.date as Date);
      },
    },
    {
      title: 'Progress',
      dataIndex: 'progress',
    },
  ];

  return (
    <Card title="Progress" padding="1.25rem">
      <Table
        columns={tableColumns}
        dataSource={[
          {
            date: new Date(),
            progress: 'Progress development Tahap III',
          },
          {
            date: new Date(1692677715000),
            progress: 'Progress development Tahap II',
          },
          {
            date: new Date(1689999315000),
            progress: 'Progress development Tahap I',
          },
        ]}
        pagination={false}
        loading={false}
        scroll={{ x: undefined }}
        bordered
      />
    </Card>
  );
};

export default React.memo(ProgressTab);
