import React, { useCallback, useEffect, useState } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Col, Row } from 'antd';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMounted } from '@app/hooks/useMounted';
import dayjs from 'dayjs';
import { LocationForm } from '@app/components/forms/master/Location/LocationForm';
import { useAtom } from 'jotai';
import { useLoading } from '@app/hooks/useLoading';
import {
  createEntityRecord,
  EntityFieldsResponse,
  getEntityFields,
  EntityRecordRequest,
  EntityData,
} from '@app/api/master/entity.api';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Space } from 'antd';
import { DashboardOutlined } from '@ant-design/icons';
import { DatePicker } from '@app/components/common/pickers/DatePicker';
import { SelectionForm } from '@app/components/forms/master/SelectionForm';
import { CurrencyInput } from '@app/components/forms/master/CurrencyInput';
import { EditableSPKInvoiceListTable } from '@app/components/tables/master/spk_invoice/EditableSPKInvoiceListTable';
import { Modal } from '@app/components/common/Modal/Modal';
import { checkPagePermission } from '@app/utils/utils';

const CreateInvoicePage: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [loadingInsertProducts, setLoadingInsertProducts] = useState(0);
  const navigate = useNavigate();
  const { isMounted } = useMounted();

  const [form] = BaseButtonsForm.useForm();

  const [refreshToken, setRefreshToken] = useState(0);
  const [_, setLoadingScreen] = useAtom(useLoading);
  const [metafield, setMetafield] = useState<EntityFieldsResponse>();

  const [invoiceProduct, setInvoiceProduct] = useState<EntityData[]>([]);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_invoice',
          action: 'CREATE',
        },
      ])
    )
      navigate('/');

    form.setFieldValue('date', dayjs());
  }, []);

  useEffect(() => {
    form.setFieldValue('spk', parseInt(query.get('spk') || '0'));
  }, []);

  useEffect(() => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    getEntityFields('tt_invoice')
      .then((res) => {
        setMetafield(res);
      })
      .catch((err) => {
        console.error(`[Error Get Invoice Fields] - ${err}`);
      })
      .finally(() => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  }, [refreshToken]);

  const onFinish = useCallback(
    (values: EntityData) => {
      // checking do not continue if no product selected
      if (invoiceProduct.length === 0) {
        notificationController.error({ message: 'Gagal membuat invoice. Harap memilih produk terlebih dahulu' });
        return;
      }
      setLoadingInsertProducts(invoiceProduct.length);

      // todo dispatch an action here
      setLoading(true);

      setTimeout(() => {
        setFieldsChanged(false);
        // call create Invoice API
        const params: EntityRecordRequest = {
          ...values,
          spk: {
            id: values.spk,
          },
        };

        if (values.date) params['date'] = dayjs(values.date).format('YYYY-MM-DD 00:00:00');
        if (values.due_date) params['due_date'] = dayjs(values.due_date).format('YYYY-MM-DD 00:00:00');
        createEntityRecord('tt_invoice', params)
          .then((res) => {
            invoiceProduct.map((item, index) => createInvoiceProduct(item, res.id, index == invoiceProduct.length - 1));
          })
          .catch((err) => {
            console.error(`[Create Invoice Error] - ${err}`);
            notificationController.error({ message: 'Gagal membuat invoice' });
          })
          .finally(() => {
            if (isMounted.current) {
              setLoading(false);
            }
          });
      }, 1000);
    },
    [invoiceProduct],
  );

  const createInvoiceProduct = (data: EntityData, invoiceId: string, isLast: boolean) => {
    // call create Invoice API
    const params: EntityRecordRequest = {
      spk: {
        id: data.spk.id,
      },
      invoice: {
        id: invoiceId,
      },
      product: {
        id: data.product.id,
      },
      uom: {
        id: data.product.uom.id,
      },
      pkg: data.pkg
        ? {
            id: data.pkg.id,
          }
        : null,
      product_price: data.price,
      qty: data.qty,
      total: data.subtotal,
    };
    createEntityRecord('tt_invoice_product', params)
      .then((res) => {
        if (isLast) {
          notificationController.success({ message: 'Sukses membuat invoice' });
          navigate(`/transaction/spk/${data.spk.id}/update`);
        }
      })
      .catch((err) => {
        console.error(`[Create Invoice Error] - ${err}`);
        notificationController.error({ message: 'Gagal membuat invoice' });
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });
  };

  const changeProduct = (data: EntityData[]) => {
    setInvoiceProduct(data);
    updateTotal(data);
  };

  const updateTotal = (data: EntityData[]) => {
    let total = 0;

    data.map((item) => {
      total += item.subtotal;
    });

    form.setFieldValue('total', total);
    form.setFieldValue('outstanding', total);
    form.setFieldValue('paid', 0);
  };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="/transaction/invoice">Invoice</BreadcrumbItem>
        <BreadcrumbItem href="#">Tambah</BreadcrumbItem>
      </Breadcrumb>
      <Row gutter={[30, 30]}>
        {/* Main Data */}
        <Col xs={24} md={24}>
          <Card title="Data Invoice" padding="1.25rem">
            <BaseButtonsForm
              form={form}
              name="Tambah Invoice"
              isFieldsChanged={isFieldsChanged}
              onFieldsChange={() => setFieldsChanged(true)}
              setFieldsChanged={setFieldsChanged}
              onFinish={onFinish}
              loading={isLoading}
            >
              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={8}>
                  <BaseButtonsForm.Item
                    name="date"
                    label="Tanggal"
                    rules={[{ required: !metafield?.date.nullable, message: 'Harap masukkan tanggal invoice' }]}
                  >
                    <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} placeholder="Pilih tanggal" disabled />
                  </BaseButtonsForm.Item>
                </Col>

                <Col xs={24} md={8}>
                  <BaseButtonsForm.Item
                    name="due_date"
                    label="Tanggal Jatuh Tempo"
                    rules={[
                      {
                        required: !metafield?.due_date.nullable,
                        message: 'Harap masukkan tanggal jatuh tempo',
                      },
                    ]}
                  >
                    <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} placeholder="Pilih tanggal" />
                  </BaseButtonsForm.Item>
                </Col>
              </Row>

              <BaseButtonsForm.Item>
                <BaseButtonsForm.Title>Data SPK</BaseButtonsForm.Title>
              </BaseButtonsForm.Item>

              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={8}>
                  <SelectionForm
                    entity="tt_spk"
                    field="spk"
                    form={form}
                    label="SPK"
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.spk}
                    optionLabel="code"
                    disabled
                  />
                </Col>
              </Row>

              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={8}>
                  <BaseButtonsForm.Item
                    name="total"
                    label="Total"
                    rules={[{ required: false, message: 'Harap masukkan total' }]}
                  >
                    <CurrencyInput disabled />
                  </BaseButtonsForm.Item>
                </Col>
                <Col xs={24} md={8}>
                  <BaseButtonsForm.Item
                    name="paid"
                    label="Terbayar"
                    rules={[{ required: false, message: 'Harap masukkan terbayar' }]}
                  >
                    <CurrencyInput disabled />
                  </BaseButtonsForm.Item>
                </Col>
                <Col xs={24} md={8}>
                  <BaseButtonsForm.Item
                    name="outstanding"
                    label="Sisa"
                    rules={[{ required: false, message: 'Harap masukkan sisa' }]}
                  >
                    <CurrencyInput disabled />
                  </BaseButtonsForm.Item>
                </Col>
              </Row>
            </BaseButtonsForm>
          </Card>
        </Col>

        {form.getFieldValue('spk') && (
          <Col xs={24}>
            <Card title="Produk SPK" padding="1.25rem">
              <EditableSPKInvoiceListTable spkId={query.get('spk') || '0'} onChange={(data) => changeProduct(data)} />
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
};

export default CreateInvoicePage;
