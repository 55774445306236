export type CategoryType = 'master' | 'system' | 'transaction';

interface Category {
  name: CategoryType;
  title: string;
}

export const categoriesList: Category[] = [
  {
    name: 'master',
    title: 'Master',
  },
  {
    name: 'system',
    title: 'System',
  },
  {
    name: 'transaction',
    title: 'Transaksi',
  },
];
