import { FormInstance, InputNumber } from 'antd';
import React from 'react';
import styled from 'styled-components';

const InfoText = styled.div`
  text-align: right;
  font-size: 14px;
  margin-top: 5px;
  cursor: pointer;
  color: var(--text-nft-light-color);
`;

type CurrencyInputProps = {
  disabled?: boolean;
  onChange?: () => void;
  textInfo?: {
    onChange?: () => void;
    value?: string;
  };
};

export const CurrencyInput: React.FC<CurrencyInputProps> = ({ disabled = false, onChange, textInfo, ...props }) => {
  return (
    <>
      <InputNumber
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
        prefix="Rp"
        controls={false}
        disabled={disabled}
        onChange={onChange}
        style={{ width: '100%' }}
        {...props}
      />
      {textInfo && <InfoText onClick={textInfo.onChange}>{textInfo.value}</InfoText>}
    </>
  );
};
