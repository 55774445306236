import React, { useCallback, useEffect, useState } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Col, Row } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useLoading } from '@app/hooks/useLoading';
import {
  createEntityRecord,
  EntityData,
  getEntityRecord,
  EntityRecordRequest,
  getEntityRecords,
  updateEntityRecord,
} from '@app/api/master/entity.api';
import { SelectionForm } from '@app/components/forms/master/SelectionForm';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Space } from 'antd';
import { DashboardOutlined } from '@ant-design/icons';
import { EditableQuotationProductListTable } from '@app/components/tables/master/quotation_product/EditableQuotationProductListTable';
import { checkPagePermission } from '@app/utils/utils';

const CreateProductPackageQuotationPage: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const navigate = useNavigate();

  const [form] = BaseButtonsForm.useForm();
  const [_, setLoadingScreen] = useAtom(useLoading);
  const [productData, setProductData] = useState<EntityData[]>();
  const [packageData, setPackageData] = useState<EntityData>();

  const [isEditing, setIsEditing] = useState(false);

  const [loadingFinish, setLoadingFinish] = useState(0);
  const [quotationProducts, setQuotationProducts] = useState<EntityData[]>([]);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_product_quotation',
          action: 'CREATE',
        },
      ])
    )
      navigate('/');
    form.setFieldValue('quotation', parseInt(query.get('quotation') || '0'));
    getAllProductQuotation();
  }, []);

  const onFinish = useCallback(
    (values: EntityData) => {
      setLoadingScreen({
        isLoading: true,
        message: 'Mohon menunggu...',
      });
      setLoadingFinish(productData?.length || 0);

      setFieldsChanged(false);
      //need to do it one by one
      productData?.forEach(async (product: EntityData) => {
        const body: EntityRecordRequest = {
          ...product,
          quotation: {
            id: query.get('quotation') || '',
          },
          product: {
            id: product.product.id,
          },
          pkg: null,
        };
        if (body.markup_price) delete body['markup_price'];

        const checkExistingProduct = checkQuotationProducts(body);
        if (checkExistingProduct) await updateProductQuotation(body, checkExistingProduct);
        else await createProductQuotation(body);

        setLoadingFinish(loadingFinish - 1);
      });
      setLoadingScreen({
        isLoading: false,
        message: 'Mohon menunggu...',
      });
    },
    [productData, loadingFinish, quotationProducts],
  );

  const createProductQuotation = (body: EntityData) => {
    const tempBody = { ...body };
    if (tempBody.id) delete tempBody['id'];
    return createEntityRecord('tt_product_quotation', tempBody)
      .then((res) => {
        // only do the real success if all the product is added
        if (loadingFinish === 0) {
          notificationController.success({ message: 'Sukses menambah produk penawaran' });
          navigate(`/transaction/quotation/${query.get('quotation')}/update`);
        }
      })
      .catch((err) => {
        console.error(`[Create Product Quotation Error] - ${err}`);
        notificationController.error({ message: 'Gagal menambah produk penawaran' });
      });
  };

  const updateProductQuotation = (body: EntityData, existingProduct: EntityData) => {
    return updateEntityRecord('tt_product_quotation', existingProduct.id, {
      qty: existingProduct.qty + body.qty,
    })
      .then((res) => {
        // only do the real success if all the product is added
        if (loadingFinish === 0) {
          notificationController.success({ message: 'Sukses menambah produk penawaran' });
          navigate(`/transaction/quotation/${query.get('quotation')}/update`);
        }
      })
      .catch((err) => {
        console.error(`[Create Product Quotation Error] - ${err}`);
        notificationController.error({ message: 'Gagal menambah produk penawaran' });
      });
  };

  const changePackage = (value: any) => {
    setFieldsChanged(true);

    getPackageDetail(value).then((res) => {
      setPackageData(res);
    });
  };

  const getPackageDetail = (packageId: string): Promise<any> => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    return getEntityRecord('tt_package', packageId)
      .then((res) => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
        return res;
      })
      .catch((err) => {
        notificationController.error({
          message: 'Maaf terjadi kesalahan pada sistem. Silahkan coba beberapa saat lagi.',
        });
        console.error(`[Get Package Error] - ${err}`);
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
        return null;
      });
  };

  const getAllProductQuotation = useCallback(() => {
    getEntityRecords('tt_product_quotation', {
      page: 0,
      limit: 50,
      q: `quotation.id==${query.get('quotation') || ''}`,
      sort: 'product.id ASC',
      inf: true,
    })
      .then((res) => {
        setQuotationProducts(res.records);
      })
      .catch((err) => {
        console.error(`[Get Product Quotation List Error] - ${err}`);
      });
  }, []);

  const checkQuotationProducts = (product: EntityData): EntityData | null => {
    let existingProduct = null;
    quotationProducts.map((qProduct: EntityData) => {
      if (
        qProduct.product.id == product.product.id &&
        qProduct.markup_percentage == product.markup_percentage &&
        qProduct.price == product.price
      )
        existingProduct = { ...qProduct };
    });
    return existingProduct;
  };

  const productChanges = (data: EntityData[]) => {
    setFieldsChanged(true);
    setProductData(data);
  };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="/transaction/quotation">Dokumen Penawaran</BreadcrumbItem>
        <BreadcrumbItem href={`/transaction/quotation/${query.get('quotation')}/update`}>Ubah</BreadcrumbItem>
        <BreadcrumbItem href={`?quotation=${query.get('quotation')}`}>Tambah Produk</BreadcrumbItem>
      </Breadcrumb>
      <Card title="Data Paket Penawaran" padding="1.25rem">
        <BaseButtonsForm
          isFieldsChanged={isFieldsChanged && !isEditing}
          onFieldsChange={() => setFieldsChanged(true)}
          setFieldsChanged={setFieldsChanged}
          onFinish={onFinish}
        >
          <Row gutter={{ xs: 10, md: 15 }}>
            <Col xs={24} md={12}>
              <SelectionForm
                entity="tt_package"
                field="package"
                form={form}
                label="Paket"
                onChange={(value) => changePackage(value)}
              />
            </Col>
            <Col xs={24}>
              {packageData && (
                <EditableQuotationProductListTable
                  quotationId={''}
                  packageId={packageData.id || ''}
                  onChange={(data) => productChanges(data)}
                  setIsEditing={setIsEditing}
                />
              )}
            </Col>
          </Row>
        </BaseButtonsForm>
      </Card>
    </>
  );
};

export default CreateProductPackageQuotationPage;
