import { DashboardOutlined } from '@ant-design/icons';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Card } from '@app/components/common/Card/Card';
import { Select } from '@app/components/common/selects/Select/Select';
import { InvoiceListTable } from '@app/components/tables/master/invoice/InvoiceListTable';
import { checkPagePermission } from '@app/utils/utils';
import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ListInvoicePage: React.FC = () => {
  const filterOption = [
    {
      label: 'Semua',
      value: '',
    },
    {
      label: 'Lunas',
      value: 'outstanding==0',
    },
    {
      label: 'Belum Lunas',
      value: 'outstanding>0',
    },
  ];
  const [filterOutstanding, setFilterOutstanding] = useState('');

  const navigate = useNavigate();
  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_invoice',
          action: 'READ',
        },
      ])
    )
      navigate('/');
  }, []);

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="#">Invoice</BreadcrumbItem>
      </Breadcrumb>
      <Card
        title="Invoice"
        padding="1.25rem 1.25rem 0"
        extra={
          <Select
            options={filterOption}
            defaultValue={''}
            style={{ width: '240px' }}
            onChange={(val) => setFilterOutstanding(val as string)}
          />
        }
      >
        <InvoiceListTable filterOutstanding={filterOutstanding} />
      </Card>
    </>
  );
};

export default ListInvoicePage;
