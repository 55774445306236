import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { notificationController } from '@app/controllers/notificationController';
import { useMounted } from '@app/hooks/useMounted';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { Modal } from '@app/components/common/Modal/Modal';
import styled from 'styled-components';
import { PaymentTypeModalForm } from './PaymentTypeModalForm';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { FormInstance } from 'antd/es/form/Form';
import { EntityData, EntityField, getEntityRecords } from '@app/api/master/entity.api';
import { Pagination } from '@app/api/general.api';
import useDebounce from '@app/hooks/useDebounce';

export type PaymentTypeFormProps = {
  form: FormInstance;
  disabled?: boolean;
  metafield?: EntityField;
  onChange: () => void;
};

export const PaymentTypeForm: React.FC<PaymentTypeFormProps> = ({ form, metafield, disabled, onChange }) => {
  const [openModal, setOpenModal] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [paymentTypeData, setPaymentTypeData] = useState<Array<any> | null>(null);
  const [refetchToken, setRefetch] = useState(0);
  const { isMounted } = useMounted();
  const [pagination, setPagination] = useState<Pagination>();
  const [query, setQuery] = useState<string>();

  const search = useDebounce(query, 250);

  useEffect(() => {
    fetch();
  }, [refetchToken, search]);

  const fetch = () => {
    let params: {
      page: number;
      limit: number;
      q?: string;
    } = {
      page: (pagination?.current || 1) - 1,
      limit: pagination?.pageSize || 10,
    };
    if (search) params = { ...params, q: search };

    getEntityRecords('tt_payment_type', params)
      .then((res) => {
        setPaymentTypeData(
          res.records.map((data: EntityData) => {
            return {
              value: data.id,
              label: data.field,
            };
          }),
        );
      })
      .catch((err) => {
        console.error(`[Get Payment Type List Error] ${err}`);
        notificationController.error({ message: 'Gagal memuat data tipe pembayaran' });
      })
      .finally(() => {
        if (isMounted.current) {
          setFetchLoading(false);
        }
      });
  };

  const createPaymentType = () => {
    setOpenModal(false);
    createForm.submit();
  };

  const loadingItem = () => {
    return (
      <div style={{ textAlign: 'center', padding: '4px' }}>
        <Spin size="small" />
        <div>Mohon menunggu</div>
      </div>
    );
  };

  const resetSelection = () => {
    setRefetch(refetchToken + 1);
  };

  const handleChange = (value: unknown) => {
    const val = value as string[];
    form.setFieldValue('type', val);
    if (onChange !== undefined) onChange();

    console.log(form.getFieldsValue());
  };

  const handleSearch = (value: string) => {
    setFetchLoading(true);
    setPagination({
      current: 1,
      pageSize: 10,
    });
    if (value) setQuery(`name=ilike=${value}`);
    else setQuery('');
  };

  const [createForm] = BaseForm.useForm();

  return (
    <>
      <BaseButtonsForm.Item
        name="type"
        label="Tipe Pembayaran"
        rules={[{ required: !metafield?.nullable, message: 'Harap masukkan tipe pembayaran', type: 'number' }]}
        hasFeedback
      >
        <Select
          defaultValue={form.getFieldValue('type')}
          showSearch
          onChange={handleChange}
          onSearch={handleSearch}
          filterOption={false}
          notFoundContent={fetchLoading ? loadingItem() : 'Tidak ada data'}
          disabled={disabled}
        >
          {paymentTypeData &&
            paymentTypeData.map((payment_type, idx) => {
              return (
                <Option value={payment_type.value} key={`payment-type-selection-${idx}`}>
                  {payment_type.label}
                </Option>
              );
            })}
        </Select>
        {!disabled && <ClickableText onClick={() => setOpenModal(true)}>+ Tambah Tipe Pembayaran</ClickableText>}
      </BaseButtonsForm.Item>
      <Modal
        title="Tambah Tipe Pembayaran"
        visible={openModal}
        onOk={() => createPaymentType()}
        onCancel={() => setOpenModal(false)}
      >
        <PaymentTypeModalForm form={createForm} onSubmit={resetSelection} />
      </Modal>
    </>
  );
};

const ClickableText = styled.div`
  text-align: right;
  font-size: 14px;
  margin-top: 5px;
  cursor: pointer;
  color: var(--text-nft-light-color);
`;
