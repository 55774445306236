import {
  EntityData,
  createEntityRecord,
  deleteEntity,
  getEntityRecords,
  updateEntityRecord,
} from '@app/api/master/entity.api';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { SelectionForm } from '@app/components/forms/master/SelectionForm';
import { notificationController } from '@app/controllers/notificationController';
import { getUserDetail } from '@app/utils/utils';
import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import TermAndConditionTable from './components/TermAndConditionTable';
import { Card } from '@app/components/common/Card/Card';

const TERM_AND_CONDITION_KEY_ID = 2;

export const SettingsPage = () => {
  const [form] = BaseButtonsForm.useForm();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isRoot, setIsRoot] = useState(false);

  const [fields, setFields] = useState<
    {
      id: number;
      key: string;
    }[]
  >([]);
  const [data, setData] = useState<EntityData[]>([]);

  useEffect(() => {
    getSettingsField();
    checkIsRoot();
  }, []);

  const checkIsRoot = () => {
    const user = getUserDetail();
    if (user && user.is_root) setIsRoot(true);
  };

  const getSettingsField = () => {
    getEntityRecords('s_settings', {
      inf: true,
      page: 0,
      limit: 100,
    }).then((res) => {
      setFields(
        res.records.map((setting) => {
          return {
            id: setting.id,
            key: setting.key,
          };
        }),
      );
    });
  };

  const getSettingsBranch = (branch_id: number) => {
    getEntityRecords('s_settings_branch', {
      inf: true,
      page: 0,
      limit: 100,
      q: `branch.id==${branch_id}`,
      sort: 'seq ASC',
    }).then((res) => {
      const settings: any = {};
      res.records.map((setting: EntityData) => {
        if (settings[setting.key.id]) settings[setting.key.id] += '\n' + setting.value;
        else settings[setting.key.id] = setting.value;
      });
      fields.forEach((field) => {
        form.setFieldValue(field.id, settings[field.id]);
      });
      setData(res.records);
    });
  };

  const onFinish = useCallback((values: EntityData) => {
    if (!values.branch) {
      notificationController.error({ message: 'Mohon untuk memilih cabang terlebih dahulu' });
      return;
    }
    // todo dispatch an action here
    setLoading(true);
    setTimeout(() => {
      setFieldsChanged(false);

      fields.forEach((field) => {
        const currentData = data.find((dt) => dt.key.id == field.id);
        if (field.id != TERM_AND_CONDITION_KEY_ID) {
          updateEntityRecord('s_settings_branch', currentData?.id, { value: values[field.id] }).then(() => {
            notificationController.success({ message: `Sukses mengubah ${field.key}` });
          });
        }
      });
      setLoading(false);
    }, 1000);
  }, []);

  const onChangeBranch = (value: any) => {
    getSettingsBranch(value);
    setFieldsChanged(true);
  };

  const onSaveTerm = (values: EntityData) => {
    if (values.id > -1) {
      updateEntityRecord('s_settings_branch', values.id, {
        value: values.value,
      })
        .then(() => {
          notificationController.success({ message: 'Sukses mengubah term and condition' });
        })
        .catch((err) => {
          console.error(`[Update Term and Condition Error] - ${err}`);
          notificationController.error({ message: 'Gagal mengubah Term and Condition' });
        });
    } else {
      createEntityRecord('s_settings_branch', {
        seq: values.seq,
        value: values.value,
        key: {
          id: TERM_AND_CONDITION_KEY_ID,
        },
        branch: {
          id: form.getFieldValue('branch'),
        },
      })
        .then(() => {
          notificationController.success({ message: 'Sukses menambah term and condition' });
        })
        .catch((err) => {
          console.error(`[Create Term and Condition Error] - ${err}`);
          notificationController.error({ message: 'Gagal membuat Term and Condition' });
        });
    }
  };

  const onDeleteTerm = (values: EntityData | null) => {
    if (!values) return;
    deleteEntity('s_settings_branch', values.id).then(() => {
      notificationController.success({ message: 'Sukses menghapus term and condition ' });

      // remove the terms in data
      setData(data.filter((dt) => dt.id != values.id));
    });
  };

  return (
    <>
      <Card title="Data Settings" padding="1.25rem">
        <BaseButtonsForm
          form={form}
          isFieldsChanged={isFieldsChanged}
          onFieldsChange={() => setFieldsChanged(true)}
          setFieldsChanged={setFieldsChanged}
          onFinish={onFinish}
          loading={isLoading}
        >
          <Row gutter={{ xs: 10, md: 15 }}>
            <Col xs={24} md={12}>
              <SelectionForm
                entity="tt_branch"
                label="Cabang"
                field="branch"
                form={form}
                onChange={(res) => onChangeBranch(res)}
                disabled={!isRoot}
              />
            </Col>
          </Row>
          <Row gutter={{ xs: 10, md: 15 }}>
            {fields.map((field, index) => {
              if (field.id == TERM_AND_CONDITION_KEY_ID) return null;
              return (
                <Col xs={24} md={12} key={`term-and-condition-${index}`}>
                  <BaseButtonsForm.Item name={field.id} label={field.key}>
                    <Input />
                  </BaseButtonsForm.Item>
                </Col>
              );
            })}
          </Row>

          {/* For term and condition, used table */}
          <Row gutter={{ xs: 10, md: 15 }}>
            <Col xs={24}>
              <TermAndConditionTable
                terms={data.filter((dt) => {
                  return dt.key.id == TERM_AND_CONDITION_KEY_ID;
                })}
                canAddTerms={form.getFieldValue('branch')}
                onSave={(values) => onSaveTerm(values)}
                onDelete={onDeleteTerm}
              />
            </Col>
          </Row>
        </BaseButtonsForm>
      </Card>
    </>
  );
};
