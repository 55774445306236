import { PlusOutlined } from '@ant-design/icons';
import { Card } from '@app/components/common/Card/Card';
import { LinkButton } from '@app/components/common/buttons/Button/Button';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { DatePicker } from '@app/components/common/pickers/DatePicker';
import VendorBulkListTable from '@app/components/tables/master/vendor_payment/VendorBulkListTable';
import { Col, Row } from 'antd';
import React, { memo } from 'react';

const BulkPaymentTab: React.FC = () => {
  return (
    <Card padding="1.25rem">
      <Row justify="space-between">
        <Col xs={24} md={12}>
          <BaseButtonsForm.Item name="payment_date" label="Tanggal Pembayaran">
            <DatePicker format="DD-MM-YYYY" style={{ width: '50%' }} placeholder="Pilih tanggal" />
          </BaseButtonsForm.Item>
        </Col>
        <Col span={4}>
          <LinkButton size="middle" icon={<PlusOutlined />} to="#">
            Tambah Pembayaran
          </LinkButton>
        </Col>
      </Row>
      <Col xs={24} md={24} style={{ margin: '40px 0' }}>
        <VendorBulkListTable />
      </Col>
    </Card>
  );
};

export default memo(BulkPaymentTab);
