import React, { useEffect, useState } from 'react';
import { Calendar as DefaultCalendar, Row } from 'antd';
import { EntityData, getEntityRecords } from '@app/api/master/entity.api';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FileOutlined, ToolOutlined } from '@ant-design/icons';
import { Option, Select } from '../selects/Select/Select';
import Col from 'antd/es/grid/col';
import { MONTHS } from '@app/utils/constants';

export interface ScheduleData {
  date: string;
  label: string;
  description: string | React.ReactNode;
}

const Calendar: React.FC = () => {
  const [refreshToken, setRefreshToken] = useState(0);
  const [dateValue, setDateValue] = useState<moment.Moment>();
  const [monthlyData, setMonthlyData] = useState<{
    [month: string]: {
      [date: string]: EntityData[];
    };
  }>({});

  useEffect(() => {
    // constructor
    fetchSchedule(moment().format('YYYY-MM-DD'));
  }, []);

  const fetchSchedule = async (date: string) => {
    const getCalendarQuery = (date: string) => {
      let query = '';

      const startLimit = moment(date).startOf('month').format('YYYY-MM-DD 00:00:00.0');
      const endLimit = moment(date).endOf('month').format('YYYY-MM-DD 23:59:59.999');
      query = `startDate=ge='${startLimit}';startDate=le='${endLimit}'`;

      return query;
    };

    // check if local data exist, then use local
    // to reduce redundant API calling

    // parse the date
    let formattedDate = '';
    formattedDate = `${moment(date).month() + 1}-${moment(date).year()}`; // MM-YYYY, where MM from 1 to 12
    if (monthlyData && formattedDate in monthlyData) return;

    await getEntityRecords('tt_technician_schedule', {
      page: 0,
      limit: 100,
      q: `deleted==false;${getCalendarQuery(date)}`,
      sort: 'createdDate DESC',
      inf: true,
    }).then((res) => {
      const dateData: {
        [dateFormat: string]: EntityData[];
      } = {};
      res.records.map((record) => {
        const key = `${moment(record.start_date).date()}`;
        if (key in dateData) dateData[key].push(record);
        else dateData[key] = [record];
      });

      const newData = monthlyData;
      newData[formattedDate] = dateData;

      setMonthlyData(newData);
      setRefreshToken(refreshToken + 1);
    });
  };

  const onPanelChange = (value: moment.Moment) => {
    fetchSchedule(value.format('YYYY-MM-DD'));
    setDateValue(value);
  };

  // when clicking the calendar
  const onSelect = (value: moment.Moment) => {
    // function to do when clicking the calendar cell
  };

  const getDateListData = (value: moment.Moment) => {
    const key = `${value.month() + 1}-${value.year()}`;
    if (monthlyData[key]) {
      if (monthlyData[key][value.date()])
        return monthlyData[key][value.date()].map((date) => {
          return {
            technician: date.technician,
            spk: date.spk,
            id: date.id,
          };
        });
    }
    return [];
  };

  const dateCellRender = (value: moment.Moment) => {
    const listData = getDateListData(value);
    return (
      <ul className="events">
        {listData.map((item, index) => (
          <li key={item.id}>
            {item.technician && (
              <div>
                <ToolOutlined />{' '}
                <Link to={`/master/technician/${item.technician.id}/update`} target="_blank">
                  {item.technician.name}
                </Link>
              </div>
            )}
            {item.spk && (
              <div>
                <FileOutlined />{' '}
                <Link to={`/transaction/spk/${item.spk.id}/update`} target="_blank">
                  {item.spk.code}
                </Link>
              </div>
            )}
            {index < listData.length - 1 && <hr style={{ margin: '8px 0' }} />}
          </li>
        ))}
      </ul>
    );
  };

  const cellRender = (current: moment.Moment) => {
    return dateCellRender(current);
  };

  return (
    <>
      <DefaultCalendar
        value={dateValue}
        key={refreshToken}
        onPanelChange={onPanelChange}
        dateCellRender={cellRender}
        onSelect={onSelect}
        headerRender={({ value, type, onChange, onTypeChange }) => {
          const start = 0;
          const end = 12;
          const monthOptions = [];

          const months = MONTHS;

          for (let i = start; i < end; i++) {
            monthOptions.push(
              <Option key={i} value={i} className="month-item">
                {months[i]}
              </Option>,
            );
          }

          const year = value.year();
          const month = value.month();
          const options = [];
          for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
              <Option key={i} value={i} className="year-item">
                {i}
              </Option>,
            );
          }

          return (
            <div style={{ padding: 12, display: 'flex', justifyContent: 'flex-end' }}>
              <Row gutter={8}>
                <Col>
                  <Select
                    size="middle"
                    dropdownMatchSelectWidth={false}
                    value={month}
                    onChange={(newMonth) => {
                      const now = value.clone().month(newMonth as string);
                      onChange(now);
                    }}
                  >
                    {monthOptions}
                  </Select>
                </Col>
                <Col>
                  <Select
                    size="middle"
                    dropdownMatchSelectWidth={false}
                    className="my-year-select"
                    value={year}
                    onChange={(newYear) => {
                      const now = value.clone().year(newYear as number);
                      onChange(now);
                    }}
                  >
                    {options}
                  </Select>
                </Col>
              </Row>
            </div>
          );
        }}
      />
    </>
  );
};

export default Calendar;
