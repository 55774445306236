import { DashboardOutlined } from '@ant-design/icons';
import {
  EntityData,
  EntityFieldsResponse,
  EntityRecordRequest,
  createEntityRecord,
  getEntityFields,
  getEntityRecords,
  getEntityRecord,
} from '@app/api/master/entity.api';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Card } from '@app/components/common/Card/Card';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { DatePicker } from '@app/components/common/pickers/DatePicker';
import { DataSelectionForm } from '@app/components/forms/master/DataSelectionForm';
import { LocationForm } from '@app/components/forms/master/Location/LocationForm';
import { SelectionForm } from '@app/components/forms/master/SelectionForm';
import { PackageProductListTable } from '@app/components/tables/master/package_product/PackageProductListTable';
import { TransactionType } from '@app/constants/transaction';
import { notificationController } from '@app/controllers/notificationController';
import { useLoading } from '@app/hooks/useLoading';
import { useMounted } from '@app/hooks/useMounted';
import { checkPagePermission, getUserBranch } from '@app/utils/utils';
import { Col, Row, Space } from 'antd';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CreateSPKPage: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const { isMounted } = useMounted();

  const [form] = BaseButtonsForm.useForm();

  const refreshToken = useState(0)[0];
  const setLoadingScreen = useAtom(useLoading)[1];
  const [metafield, setMetafield] = useState<EntityFieldsResponse>();
  const [_, setProductData] = useState<EntityData[]>();
  const [packageData, setPackageData] = useState<EntityData>();

  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_spk',
          action: 'CREATE',
        },
      ])
    )
      navigate('/');

    form.setFieldValue('date', dayjs());
  }, []);

  useEffect(() => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    getEntityFields('tt_spk')
      .then((res) => {
        setMetafield(res);
      })
      .catch((err) => {
        console.error(`[Error Get SPK Fields] - ${err}`);
      })
      .finally(() => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  }, [refreshToken]);

  const onFinish = useCallback((values: EntityData) => {
    // todo dispatch an action here
    const activeBranch = getUserBranch();

    // type safety stuff
    if (activeBranch) {
      getEntityRecords('tt_vendor', {
        page: 0,
        limit: 100,
        q: `branch.id == ${activeBranch.id}`,
        sort: '',
      }).then((res) => {
        setLoading(true);

        setTimeout(() => {
          setFieldsChanged(false);
          // call create SPK API
          const params: EntityRecordRequest = {
            ...values,
            city: {
              id: values.city,
            },
            customer: {
              id: values.customer,
            },
            admin: {
              id: values.admin,
            },
            sales: {
              id: values.sales,
            },
            pkg: {
              id: values.pkg,
            },
            vendor: {
              id: res.records[0].id,
            },
            status: TransactionType.DRAFT,
          };

          if (values.vendor) params['vendor'] = { id: values.vendor };
          if (values.technician) params['technician'] = { id: values.technician };
          if (values.offer) params['offer'] = { id: values.offer };

          if (values.date) params['date'] = dayjs(values.date).format('YYYY-MM-DD 00:00:00');
          if (values.date)
            params['installation_date'] = dayjs(values.instaldateation_date).format('YYYY-MM-DD 00:00:00');

          createEntityRecord('tt_spk', params)
            .then((res) => {
              notificationController.success({ message: 'Sukses menambah SPK' });
              navigate(`/transaction/spk/${res.id}/update`);
            })
            .catch((err) => {
              console.error(`[Create SPK Error] - ${err}`);
              notificationController.error({ message: 'Gagal menambah SPK' });
            })
            .finally(() => {
              if (isMounted.current) {
                setLoading(false);
              }
            });
        }, 1000);
      });
    }
  }, []);

  const productChanges = (data: EntityData[]) => {
    setFieldsChanged(true);
    setProductData(data);
  };

  const changePackage = (value: any) => {
    setFieldsChanged(true);

    getPackageDetail(value).then((res) => {
      setPackageData(res);
    });
  };

  const getPackageDetail = async (packageId: string): Promise<any> => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    return getEntityRecord('tt_package', packageId)
      .then((res) => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
        return res;
      })
      .catch((err) => {
        notificationController.error({
          message: 'Maaf terjadi kesalahan pada sistem. Silahkan coba beberapa saat lagi.',
        });
        console.error(`[Get Package Error] - ${err}`);
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
        return null;
      });
  };

  const onChooseQuotation = () => {
    setFieldsChanged(true);
    fetchQuotation();
  };

  const fetchQuotation = () => {
    const quotation = form.getFieldValue('offer');
    if (!quotation) return;

    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    getEntityRecord('tt_quotation', quotation)
      .then((res) => {
        // set the data from quotation
        form.setFieldsValue({
          // detail
          admin: res.admin.id,
          sales: res.sales.id,
          vendor: res.vendor.id,
          technician: res.technician ? res.technician.id : null,

          // customer
          customer: res.customer.id,
          address: res.address,
          phone: res.phone,
          mobile_phone: res.mobile_phone,
          email: res.customer.email,
          city: res.city.id,
          postal_code: res.postal_code,
          latitude: res.latitude,
          longitude: res.longitude,

          // product
          total: res.total,
        });
      })
      .catch((err) => {
        notificationController.error({
          message: 'Maaf terjadi kesalahan pada sistem. Silahkan coba beberapa saat lagi.',
        });
        console.error(`[Get Quotation Error] - ${err}`);
      })
      .finally(() => {
        if (isMounted.current) {
          setLoadingScreen({
            isLoading: false,
            message: 'Mohon menunggu...',
          });
        }
      });
  };

  const setCustomerData = async (e: number) => {
    try {
      // fetching customer data from api
      const customer = await getEntityRecord('c_customer', e);
      // console.log(customer);

      // setting form data based on customer value
      form.setFieldsValue({
        customer_email: customer.email,
        phone: customer.phone,
        mobile_phone: customer.mobile_phone,
        address: customer.address,
        city: customer.city.id,
        postal_code: customer.postal_code,
      });
    } catch (e) {
      notificationController.error({
        message: 'Maaf terjadi kesalahan pada sistem. Silahkan coba beberapa saat lagi.',
      });
    }
  };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="/transaction/spk">SPK</BreadcrumbItem>
        <BreadcrumbItem href="#">Tambah</BreadcrumbItem>
      </Breadcrumb>
      <Row gutter={[30, 30]}>
        {/* Main Data */}
        <Col xs={24} md={24}>
          <Card title="Data SPK" padding="1.25rem">
            <BaseButtonsForm
              form={form}
              name="Tambah SPK"
              isFieldsChanged={isFieldsChanged}
              onFieldsChange={() => setFieldsChanged(true)}
              setFieldsChanged={setFieldsChanged}
              onFinish={onFinish}
              loading={isLoading}
              initialValues={{
                remember: true,
              }}
            >
              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={8}>
                  <BaseButtonsForm.Item
                    name="date"
                    label="Tanggal"
                    rules={[{ required: !metafield?.date.nullable, message: 'Harap masukkan tanggal spk' }]}
                  >
                    <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} placeholder="Pilih tanggal" disabled />
                  </BaseButtonsForm.Item>
                </Col>

                <Col xs={24} md={8}>
                  <SelectionForm
                    entity="tt_quotation"
                    field="offer"
                    form={form}
                    label="Dokumen Penawaran"
                    onChange={onChooseQuotation}
                    metafield={metafield?.offer}
                    optionLabel="code"
                  />
                </Col>

                <Col xs={24} md={8}>
                  <DataSelectionForm
                    field="type"
                    form={form}
                    label="Tipe"
                    options={
                      metafield?.type.expected_values?.map((value) => {
                        return { value: value, label: value };
                      }) || []
                    }
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.type}
                  />
                </Col>

                <Col xs={24} md={8}>
                  <SelectionForm
                    entity="tt_package"
                    field="pkg"
                    form={form}
                    label="Paket"
                    metafield={metafield?.pkg}
                    onChange={(value) => changePackage(value)}
                  />
                </Col>

                <Col xs={24} md={8}>
                  <SelectionForm
                    entity="tt_admin"
                    field="admin"
                    form={form}
                    label="Admin"
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.admin}
                  />
                </Col>

                <Col xs={24} md={8}>
                  <SelectionForm
                    entity="tt_sales_person"
                    field="sales"
                    form={form}
                    label="Sales"
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.sales}
                  />
                </Col>
              </Row>

              <BaseButtonsForm.Item>
                <BaseButtonsForm.Title>Data Customer</BaseButtonsForm.Title>
              </BaseButtonsForm.Item>

              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={8}>
                  <SelectionForm
                    entity="c_customer"
                    field="customer"
                    form={form}
                    label="Customer"
                    onChange={async (e) => {
                      setFieldsChanged(true);
                      if (e != null) {
                        await setCustomerData(e);
                      }
                    }}
                    metafield={metafield?.customer}
                  />
                </Col>

                <Col xs={24} md={8}>
                  <BaseButtonsForm.Item
                    name="customer_email"
                    label="Email Customer"
                    rules={[
                      {
                        required: !metafield?.customer_email.nullable,
                        message: 'Harap masukkan email customer',
                        type: 'string',
                      },
                    ]}
                  >
                    <Input />
                  </BaseButtonsForm.Item>
                </Col>

                <Col xs={24} md={8}>
                  <BaseButtonsForm.Item
                    name="phone"
                    label="No Telp"
                    rules={[
                      {
                        required: !metafield?.phone.nullable,
                        message: 'Harap masukkan nomor telpon',
                        type: 'string',
                      },
                    ]}
                  >
                    <Input />
                  </BaseButtonsForm.Item>
                </Col>

                <Col xs={24} md={8}>
                  <BaseButtonsForm.Item
                    name="mobile_phone"
                    label="Handphone"
                    rules={[
                      {
                        required: !metafield?.mobile_phone.nullable,
                        message: 'Harap masukkan nomor handphone',
                        type: 'string',
                      },
                    ]}
                  >
                    <Input />
                  </BaseButtonsForm.Item>
                </Col>
              </Row>

              <LocationForm form={form} onChange={() => setFieldsChanged(true)} metafield={metafield} />

              {packageData && (
                <Row style={{ marginBottom: 16 }} gutter={{ xs: 10, md: 15 }}>
                  <BaseButtonsForm.Item>
                    <BaseButtonsForm.Title>Data Produk</BaseButtonsForm.Title>
                  </BaseButtonsForm.Item>
                  <Col xs={24}>
                    <PackageProductListTable
                      setIsEditing={setIsEditing}
                      packageId={packageData.id || ''}
                      onChange={(data) => productChanges(data)}
                      isPagination={false}
                      isEditable={false}
                    />
                  </Col>
                </Row>
              )}
            </BaseButtonsForm>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreateSPKPage;
