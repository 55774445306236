import React from 'react';
import styled from 'styled-components';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { LoadingOutlined } from '@ant-design/icons';

interface LoadingProps {
  message?: string;
}

export const GlobalLoading: React.FC<LoadingProps> = ({ message }) => {
  return (
    <SpinnerContainer>
      <Spinner tip={message} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
`;
