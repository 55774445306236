import { EntityData, changeUserPassword, changeUsername } from '@app/api/master/entity.api';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Input } from '@app/components/common/inputs/Input/Input.styles';
import { InputPassword } from '@app/components/common/inputs/InputPassword/InputPassword';
import { notificationController } from '@app/controllers/notificationController';
import { Col, Row } from 'antd';
import React, { useCallback, useState } from 'react';

type UpdatePasswordFormProps = {
  user_id: string;
};

const UpdatePasswordForm = ({ user_id }: UpdatePasswordFormProps) => {
  const [form] = BaseButtonsForm.useForm();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const onFinish = useCallback((values: EntityData) => {
    // todo dispatch an action here
    setLoading(true);
    setTimeout(() => {
      setFieldsChanged(false);
      // call create country API
      changeUsername(user_id, values.username).then((_res) => {
        changeUserPassword(user_id, values.oldPassword, values.newPassword)
          .then((res) => {
            notificationController.success({ message: 'Sukses mengubah username dan password' });
          })
          .catch((err) => {
            console.error(`[Update Password Error] - ${err}`);
            notificationController.error({ message: 'Gagal mengubah username dan password' });
          })
          .finally(() => {
            setLoading(false);
            form.resetFields();
          });
      });
    }, 1000);
  }, []);

  return (
    <BaseButtonsForm
      form={form}
      name="Change Password"
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      setFieldsChanged={setFieldsChanged}
      onFinish={onFinish}
      loading={isLoading}
    >
      <Row gutter={{ xs: 10, md: 15 }}>
        <Col xs={24} md={12}>
          <BaseButtonsForm.Item
            name="username"
            label="Username"
            rules={[
              {
                message: 'Harap masukkan username',
                type: 'string',
              },
            ]}
          >
            <Input />
          </BaseButtonsForm.Item>
          <BaseButtonsForm.Item
            name="oldPassword"
            label="Password Lama"
            rules={[
              {
                message: 'Harap masukkan password lama',
                type: 'string',
              },
            ]}
          >
            <InputPassword />
          </BaseButtonsForm.Item>
          <BaseButtonsForm.Item
            name="newPassword"
            label="Password Baru"
            rules={[
              {
                message: 'Password tidak sama dengan password baru',
                type: 'string',
              },
            ]}
          >
            <InputPassword />
          </BaseButtonsForm.Item>
          <BaseButtonsForm.Item
            name="confirmPassword"
            label="Konfirmasi Password Baru"
            rules={[
              {
                message: 'Harap masukkan password baru',
                type: 'string',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Harap memasukkan ulang password yang baru'));
                },
              }),
            ]}
          >
            <InputPassword />
          </BaseButtonsForm.Item>
        </Col>
      </Row>
    </BaseButtonsForm>
  );
};

export default UpdatePasswordForm;
