import { Pagination } from '@app/api/general.api';
import { EntityData } from '@app/api/master/entity.api';
import { Modal } from '@app/components/common/Modal/Modal';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm';
import { Table } from '@app/components/common/Table/Table';
import { Button } from '@app/components/common/buttons/Button/Button';
import { EditableCell } from '@app/components/tables/editableTable/EditableCell';
import { Form, Space } from 'antd';
import React, { useEffect, useState } from 'react';

const initialPagination: Pagination = {
  pageSize: 50,
  current: 1,
};

type TermAndConditionTableProps = {
  terms: EntityData[];
  canAddTerms: boolean;
  onSave: (record: EntityData) => void;
  onDelete: (record: EntityData | null) => void;
};

const TermAndConditionTable = ({ terms, canAddTerms, onSave, onDelete }: TermAndConditionTableProps) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState(0);
  const isEditing = (record: EntityData) => record.id === editingKey;

  const [tableData, setTableData] = useState<{
    data: EntityData[];
    pagination: Pagination;
    loading: boolean;
    q: number | string | boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
    q: '',
  });
  const [deleteModalData, setDeleteModalData] = useState<{
    show: boolean;
    data: EntityData | null;
  }>({
    show: false,
    data: null,
  });

  useEffect(() => {
    setTableData({
      data: terms,
      pagination: {
        total: terms.length,
        pageSize: initialPagination.pageSize,
        current: initialPagination.pageSize,
      },
      loading: false,
      q: '',
    });
  }, [terms]);

  const edit = (record: EntityData) => {
    form.setFieldsValue(record);
    setEditingKey(record.id);
  };

  const saveRecord = async (id: number) => {
    const row = (await form.validateFields()) as EntityData;

    const newData = [...tableData.data];
    const index = newData.findIndex((item) => id === item.id);
    if (index > -1) {
      const item = newData[index];

      newData.splice(index, 1, {
        ...item,
        ...row,
      });
    } else {
      newData.push(row);
    }
    setTableData({ ...tableData, data: newData });
    setEditingKey(0);
    onSave(newData[index]);
  };

  const cancel = (id: number) => {
    if (id != -1) setEditingKey(0);
    else {
      setTableData({ ...tableData, data: tableData.data.filter((dt) => dt.id != -1) });
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'seq',
    },
    {
      title: 'Term and Condition',
      dataIndex: 'value',
      editable: true,
      inputType: 'textarea',
    },
    {
      title: '',
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: EntityData) => {
        const editable = isEditing(record);
        return (
          <Space>
            {editable ? (
              <>
                <Button type="primary" onClick={() => saveRecord(record.id)}>
                  Simpan
                </Button>
                <Popconfirm title="Batal" onConfirm={() => cancel(record.id)}>
                  <Button type="ghost">Batal</Button>
                </Popconfirm>
              </>
            ) : (
              <>
                <Button type="ghost" disabled={editingKey !== 0} onClick={() => edit(record)}>
                  Ubah
                </Button>
                <Button type="default" danger onClick={() => setDeleteModalData({ show: true, data: record })}>
                  Hapus
                </Button>
              </>
            )}
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: EntityData) => ({
        record,
        inputType: col.inputType || 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleDeleteRow = (row: EntityData | null) => {
    setDeleteModalData({
      show: false,
      data: null,
    });
    onDelete(row);
  };

  const handleAddTerms = () => {
    let maxSeq = tableData.data.length;
    tableData.data.map((dt) => {
      if (dt.seq > maxSeq) maxSeq = dt.seq;
    });
    setTableData({
      ...tableData,
      data: [
        ...tableData.data,
        {
          id: -1,
          seq: maxSeq + 1,
        },
      ],
    });
    setEditingKey(-1);
  };

  return (
    <>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns}
          dataSource={tableData.data}
          pagination={false}
          loading={tableData.loading}
          scroll={{ x: 800 }}
          bordered
        />
        {canAddTerms && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginTop: '8px',
            }}
          >
            <Button onClick={handleAddTerms}>Tambah Term and Condition</Button>
          </div>
        )}
      </Form>
      <Modal
        title="Konfirmasi"
        visible={deleteModalData.show}
        onOk={() => handleDeleteRow(deleteModalData.data)}
        onCancel={() => setDeleteModalData({ show: false, data: deleteModalData.data })}
      >
        Apakah yakin ingin menghapus term and condition ini?
      </Modal>
    </>
  );
};

export default TermAndConditionTable;
