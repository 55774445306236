import React, { useState } from 'react';
import SkeletonImage from 'antd/lib/skeleton/Image';
import * as S from './UploadProfilePicture.styles';
import Avatar from 'antd/lib/avatar/avatar';
import { Modal } from '@app/components/common/Modal/Modal';
import { UploadDragger } from '@app/components/common/Upload/Upload';
import { InboxOutlined } from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { uploadPicture } from '@app/api/master/entity.api';
import { notificationController } from '@app/controllers/notificationController';

export type UploadProfilePictureProps = {
  onUploadSuccess: (imageId: number, file: any) => void;
  filename: string;
  image: any;
};

const UploadProfilePicture = ({ onUploadSuccess, filename, image }: UploadProfilePictureProps) => {
  const [url, setUrl] = useState<any>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [file, setFile] = useState<UploadFile>();

  const updateProfilePicture = () => {
    if (file) {
      uploadPicture(filename, file)
        .then((res) => {
          onUploadSuccess(res.id, file);
          setOpenModal(false);
          setUrl(file);
        })
        .catch((err) => {
          console.error(`[Upload Photo Admin Error] - ${err}`);
          notificationController.error({ message: 'Gagal upload foto. Silahkan dicoba lagi beberapa saat.' });
        });
    } else {
      notificationController.error({ message: 'Harap masukkan foto dulu.' });
    }
  };

  const beforeUpload = (file: UploadFile) => {
    setFile(file);
    return false;
  };

  return (
    <>
      <S.Wrapper>
        <S.Title>Foto Profil</S.Title>
        <S.ImgWrapper>
          {image ? (
            <Avatar shape="circle" src={`${image}`} alt="Profile" size={160} style={{ aspectRatio: 1 }} />
          ) : (
            <SkeletonImage style={{ borderRadius: '50%' }} />
          )}
        </S.ImgWrapper>
        <S.Body>
          <Button onClick={() => setOpenModal(true)}>Upload file</Button>
        </S.Body>
      </S.Wrapper>

      <Modal
        title="Upload foto"
        open={openModal}
        onOk={() => updateProfilePicture()}
        onCancel={() => setOpenModal(false)}
      >
        <UploadDragger maxCount={1} beforeUpload={beforeUpload} accept="image/png, image/jpeg, image/jpg, image/svg">
          <p>
            <InboxOutlined />
          </p>
          <p>Klik atau tarik file foto ke dalam kotak ini</p>
        </UploadDragger>
      </Modal>
    </>
  );
};

export default UploadProfilePicture;
