import { DashboardOutlined } from '@ant-design/icons';
import { Breadcrumb, Space } from 'antd';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import React, { useState } from 'react';
import { BulkMasterView } from './components';

const CreateVendorBulkPage: React.FC = () => {
  const [getLoading, setGetLoading] = useState(false);

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="/master/vendor-payment">Pembayaran Vendor</BreadcrumbItem>
        <BreadcrumbItem href="#">Tambah Bulk</BreadcrumbItem>
      </Breadcrumb>
      <BulkMasterView loading={getLoading} setLoading={setGetLoading} />
    </>
  );
};

export default CreateVendorBulkPage;
