import {
  EntityData,
  getEntityMedia,
  getVendorPaymentDetail,
  getVendorPaymentDetailWithType,
  updateEntityRecord,
} from '@app/api/master/entity.api';
import { Tabs } from '@app/components/common/Tabs/Tabs';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { notificationController } from '@app/controllers/notificationController';
import { TERMedia, VendorPaymentItem, VendorPaymentType } from '@app/domain';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useMounted } from '@app/hooks/useMounted';
import { getUrlEntityType } from '@app/pages/system/vendor_payment/_utils';
import { ProgressTab, ProofsTab, SpkTab } from '@app/pages/system/vendor_payment/components/index';
import { updatePaymentRecord } from '@app/store/slices/vendorPaymentSlice';
import { Col, Row } from 'antd';
import { FC, memo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

enum VendorPaymentTabData {
  SPK = '1',
  PAYMENT_PROOF = '2',
  PROGRESS = '3',
}

type Props = {
  loading: boolean;
  setLoading: (status: boolean) => void;
};

const PaymentDetailMasterView: FC<Props> = ({ loading: getLoading, setLoading: setGetLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selected: selectedPayment } = useAppSelector((state) => state.vendorPayment);
  const { isMounted } = useMounted();
  const [form] = BaseButtonsForm.useForm();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!selectedPayment) {
      navigate('/master/vendor-payment');
      return;
    }
  }, [selectedPayment]);

  useEffect(() => {
    if (!selectedPayment) return;
    const paymentUrlPath = `/master/vendor-payment/${selectedPayment?.id ?? '00'}/detail`;
    if (location.pathname.includes(paymentUrlPath)) {
      fetchVendorPaymentTotal();
      fetchVendorPaymentDetail();
    } else {
      navigate(paymentUrlPath);
    }
  }, [selectedPayment?.id, location.pathname]);

  const fetchVendorPaymentTotal = () => {
    setGetLoading(true);
    getVendorPaymentDetail('tt_vendor_payment', `${selectedPayment?.id ?? 0}`)
      .then((res) => {
        if (!selectedPayment) return;
        dispatch(updatePaymentRecord({ ...selectedPayment, total: res.total }));
      })
      .catch((err) => {
        console.error(`[Get Vendor Payment Error] - ${err}`);
        notificationController.error({
          message: 'Maaf terjadi kesalahan pada sistem. Silahkan coba beberapa saat lagi.',
        });
      })
      .finally(() => {
        if (isMounted.current) {
          setGetLoading(false);
        }
      });
  };

  const fetchVendorPaymentDetail = () => {
    setGetLoading(true);
    getVendorPaymentDetailWithType(
      getUrlEntityType(selectedPayment?.installation_type ?? ''),
      `${selectedPayment?.id ?? 0}`,
    )
      .then((res) => {
        if (!selectedPayment) return;
        dispatch(updatePaymentRecord({ ...selectedPayment, payments: res.records as VendorPaymentItem[] }));
      })
      .catch((err) => {
        console.error(`[Get Vendor Payment Error] - ${err}`);
        notificationController.error({
          message: 'Maaf terjadi kesalahan pada sistem. Silahkan coba beberapa saat lagi.',
        });
      })
      .finally(() => {
        if (isMounted.current) {
          setGetLoading(false);
        }
      });
  };

  const onFinish = () => {
    if (!selectedPayment) {
      notificationController.error({
        message: 'Pembayaran Vendor kosong. Harap hubungi tim support untuk menangani kasus ini.',
      });
      return;
    }
    setGetLoading(true);
    const paymentId = selectedPayment?.id ?? 0;
    updateEntityRecord('tt_vendor_payment', paymentId, {
      id: paymentId,
      state: 'COMPLETE',
    })
      .then(() => {
        navigate('/master/vendor-payment');
        notificationController.success({ message: 'Sukses menyelesaikan pembayaran vendor' });
      })
      .catch((err) => {
        console.error(`[Update Vendor Payment Error] - ${err}`);
        notificationController.error({ message: 'Gagal menyelesaikan pembayaran vendor' });
      })
      .finally(() => setGetLoading(false));
  };

  const onGetMedia = () => {
    setGetLoading(true);
    getEntityMedia(getUrlEntityType(VendorPaymentType.FetchMedia), `vendorPayment.id==${selectedPayment?.id ?? 0}`)
      .then((data) => {
        const arrMedia = data.data.records.map((v: EntityData) => v.media as TERMedia);
        if (arrMedia.length > 0 && selectedPayment) {
          dispatch(updatePaymentRecord({ ...selectedPayment, media: arrMedia }));
        }
      })
      .finally(() => setGetLoading(false));
  };

  const tabItems = [
    {
      label: 'SPK',
      key: VendorPaymentTabData.SPK,
      children: <SpkTab form={form} onFinish={onFinish} setLoading={setLoading} loading={getLoading || isLoading} />,
    },
    {
      label: 'Bukti Bayar',
      key: VendorPaymentTabData.PAYMENT_PROOF,
      children: <ProofsTab getMedia={onGetMedia} />,
    },
    {
      label: 'Progress',
      key: VendorPaymentTabData.PROGRESS,
      children: <ProgressTab />,
    },
  ];

  return (
    <Row gutter={[30, 30]}>
      <Col xs={24} md={24}>
        <Tabs defaultActiveKey="1" items={tabItems} />
      </Col>
    </Row>
  );
};

export default memo(PaymentDetailMasterView);
