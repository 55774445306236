import React, { useCallback, useEffect, useState } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Col, Row } from 'antd';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { useMounted } from '@app/hooks/useMounted';
import { useLoading } from '@app/hooks/useLoading';
import { useAtom } from 'jotai';
import {
  createEntityRecord,
  EntityFieldsResponse,
  EntityRecordRequest,
  getEntityFields,
} from '@app/api/master/entity.api';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Space } from 'antd';
import { DashboardOutlined } from '@ant-design/icons';
import { checkPagePermission } from '@app/utils/utils';

const CreateSpecializationPage: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isMounted } = useMounted();

  const [form] = BaseButtonsForm.useForm();

  const [refreshToken, setRefreshToken] = useState(0);
  const [_, setLoadingScreen] = useAtom(useLoading);
  const [metafield, setMetafield] = useState<EntityFieldsResponse>();

  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_specialization',
          action: 'CREATE',
        },
      ])
    )
      navigate('/');

    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    getEntityFields('tt_specialization')
      .then((res) => {
        setMetafield(res);
      })
      .catch((err) => {
        console.error(`[Error Get Specialization Fields] - ${err}`);
      })
      .finally(() => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  }, [refreshToken]);

  const onFinish = useCallback((values: EntityRecordRequest) => {
    // todo dispatch an action here
    setLoading(true);
    setTimeout(() => {
      setFieldsChanged(false);
      // call create specialization API
      createEntityRecord('tt_specialization', values)
        .then((res) => {
          notificationController.success({ message: 'Sukses menambah spesialisasi' });
          navigate(`/master/specialization/${res.id}/update`);
        })
        .catch((err) => {
          console.error(`[Error Create Specialization] - ${err}`);
          notificationController.error({ message: 'Gagal menambah spesialisasi' });
        })
        .finally(() => {
          if (isMounted.current) {
            setLoading(false);
          }
        });
    }, 1000);
  }, []);

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="/master/specialization">Spesialisasi</BreadcrumbItem>
        <BreadcrumbItem href="#">Tambah</BreadcrumbItem>
      </Breadcrumb>
      <Row gutter={[30, 30]}>
        {/* Main Data */}
        <Col xs={24} md={24}>
          <Card title="Data Spesialisasi" padding="1.25rem">
            <BaseButtonsForm
              form={form}
              name="Tambah Spesialisasi"
              isFieldsChanged={isFieldsChanged}
              onFieldsChange={() => setFieldsChanged(true)}
              setFieldsChanged={setFieldsChanged}
              onFinish={onFinish}
              loading={isLoading}
            >
              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  {/* Admin */}
                  <BaseButtonsForm.Item
                    name="name"
                    label="Nama"
                    rules={[
                      {
                        required: !metafield?.name.nullable,
                        message: 'Harap masukkan nama spesialisasi',
                        type: 'string',
                      },
                    ]}
                  >
                    <Input />
                  </BaseButtonsForm.Item>
                </Col>
              </Row>
            </BaseButtonsForm>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreateSpecializationPage;
