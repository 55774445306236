import React, { useEffect, useState, useCallback } from 'react';
import { Form, Space, TablePaginationConfig } from 'antd';
import { Pagination } from '@app/api/general.api';
import { Table } from 'components/common/Table/Table';
import { Button } from 'components/common/buttons/Button/Button';
import { useMounted } from '@app/hooks/useMounted';
import type { FilterValue } from 'antd/es/table/interface';
import { notificationController } from '@app/controllers/notificationController';
import { EntityData, getEntityRecords, getEntityRecordCount } from '@app/api/master/entity.api';
import { formatNumberWithCommas, formatRupiahPrice } from '@app/utils/utils';
import { EditableCell } from '../../editableTable/EditableCell';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm.styles';

const initialPagination: Pagination = {
  pageSize: 50,
  current: 1,
};

export type SPKInvoiceListTableProps = {
  spkId: string | number;
  onChange: (data: EntityData[]) => void;
};

export const EditableSPKInvoiceListTable: React.FC<SPKInvoiceListTableProps> = ({ spkId, onChange }) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState(0);

  const [tableData, setTableData] = useState<{
    data: EntityData[];
    pagination: Pagination;
    loading: boolean;
    q: number | string | boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
    q: '',
  });

  const [recordCount, setRecordCount] = useState<{
    loading: boolean;
    count: number;
  }>({
    loading: false,
    count: 0,
  });

  const { isMounted } = useMounted();
  const [refetchToken, setRefetch] = useState(0);
  const [loadingSpkProducts, setLoadingSpkProducts] = useState<boolean>(true);

  const fetch = useCallback(
    (pagination: Pagination, query?: string) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));

      getEntityRecords('tt_spk_product', {
        page: (pagination.current || 1) - 1,
        limit: pagination.pageSize || 50,
        q: `spk.id==${spkId}` + (query ? `;${query}` : ''),
        sort: 'product.id ASC',
        inf: true,
      })
        .then((res) => {
          if (isMounted.current) {
            const newData = res.records.map((item, index) => {
              return {
                ...item,
                is_check: false,
                spk_qty: item.qty,
                key: index + 1, // needed for table selection
              };
            });
            setTableData({
              data: newData,
              pagination: {
                current: pagination.current,
                pageSize: pagination.pageSize,
              },
              loading: true,
              q: query || '',
            });
          }
        })
        .catch((err) => {
          console.error(`[Get SPK Product List Error] - ${err}`);
        })
        .finally(() => {
          setLoadingSpkProducts(false);
        });
    },
    [isMounted, refetchToken],
  );

  type TemporarySPKProduct = {
    [key: string]: number;
  };
  const [spkProducts, setSPKProducts] = useState<TemporarySPKProduct>({}); // [pkgId-productId]: qty
  const [loadingInvoiceProducts, setLoadingInvoiceProducts] = useState<boolean>(true);

  useEffect(() => {
    if (!loadingInvoiceProducts && !loadingSpkProducts) {
      const newData = tableData.data.map((item, index) => {
        const key = `${item.pkg ? item.pkg.id : ''}-${item.product.id}`;
        if (key in spkProducts) {
          item.spk_qty = item.qty - spkProducts[key];
          item.qty = item.spk_qty;
        }
        return item;
      });

      setTableData({
        data: newData,
        loading: false,
        pagination: tableData.pagination,
        q: tableData.q,
      });
    }
  }, [loadingInvoiceProducts, loadingSpkProducts]);

  // get the invoice products to filter the spk products
  const fetchInvoiceProducts = useCallback(
    (pagination: Pagination, query?: string) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));

      getEntityRecords('tt_invoice_product', {
        page: (pagination.current || 1) - 1,
        limit: pagination.pageSize || 50,
        q: `spk.id==${spkId}` + (query ? `;${query}` : ''),
        sort: 'product.id ASC',
        inf: true,
      })
        .then((res) => {
          if (isMounted.current) {
            const filteredProducts: TemporarySPKProduct = {};
            res.records.map((item, index) => {
              const key = `${item.pkg ? item.pkg.id : ''}-${item.product.id}`;
              if (key in filteredProducts) filteredProducts[key] += item.qty;
              else filteredProducts[key] = item.qty;
            });
            setSPKProducts(filteredProducts);
          }
        })
        .catch((err) => {
          console.error(`[Get SPK Product List Error] - ${err}`);
          setTableData({
            ...tableData,
            loading: false,
          });
        })
        .finally(() => {
          setLoadingInvoiceProducts(false);
        });
    },
    [isMounted, refetchToken],
  );

  const fetchCount = useCallback(
    (pagination: Pagination, query?: string) => {
      setRecordCount((recordCount) => ({ ...recordCount, loading: true }));

      getEntityRecordCount('tt_spk_product', {
        page: (pagination.current || 1) - 1,
        limit: pagination.pageSize || 50,
        q: query,
      })
        .then((res) => {
          if (isMounted.current) {
            setRecordCount({
              count: res.count,
              loading: false,
            });
          }
        })
        .catch((err) => {
          console.error(`[Get SPK Product List Error] - ${err}`);
        });
    },
    [isMounted, refetchToken],
  );

  useEffect(() => {
    fetch(initialPagination);
    fetchCount(initialPagination);
    fetchInvoiceProducts(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
    if (filters.name) {
      fetchCount(pagination, `product.name=ilike=${filters.name}`);
      fetch(pagination, `product.name=ilike=${filters.name}`);
    }
    fetch(pagination);
  };

  const isEditing = (record: EntityData) => record.id === editingKey;

  const edit = (record: EntityData) => {
    form.setFieldsValue(record);
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey(0);
  };

  const save = async (id: string) => {
    try {
      const row = (await form.validateFields()) as EntityData;

      const newData = [...tableData.data];
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];

        // check if the input qty is bigger than spk qty, then reject
        if (row.qty > item.spk_qty) {
          notificationController.error({ message: 'Jumlah barang tidak boleh melebihi jumlah barang SPK' });
          throw new Error('Jumlah barang tidak boleh melebihi jumlah barang SPK');
        }

        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        newData[index] = recalculateSubtotal(newData[index]);
      } else {
        newData.push(row);
      }
      setTableData({ ...tableData, data: newData });
      setEditingKey(0);
      onChange(newData.filter((item) => item.is_check));
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const recalculateSubtotal = (record: EntityData) => {
    // calculate the rounding up
    const qty = record.qty || 0;
    const price = record.price || 0;

    return {
      ...record,
      subtotal: price * qty,
    };
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: EntityData[]) => {
      // for invoice data, need to pick only selected
      onChange(selectedRows);

      // for the table ui, need to update the is_check
      const selectedIds = selectedRows.map((row) => row.id);
      const newTableData = tableData.data.map((row) => {
        return {
          ...row,
          is_check: selectedIds.includes(row.id),
        };
      });
      setTableData({ ...tableData, data: newTableData });
    },
    getCheckboxProps: (record: EntityData) => ({
      disabled: record.spk_qty <= 0,
    }),
  };

  const columns = [
    {
      title: 'Paket',
      render: (text: string, record: EntityData) => {
        return <>{record.pkg ? record.pkg.name : '-'}</>;
      },
    },
    {
      title: 'Produk',
      dataIndex: 'product',
      render: (text: string, record: EntityData) => {
        return <Space>{record.product.name}</Space>;
      },
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      render: (text: string, record: EntityData) => {
        return formatNumberWithCommas(record.qty);
      },
      editable: true,
      inputType: 'number',
    },
    {
      title: 'UOM',
      dataIndex: 'uom',
      render: (text: string, record: EntityData) => {
        return <Space>{record.product.uom.name}</Space>;
      },
    },
    {
      title: 'Harga',
      dataIndex: 'price',
      render: (text: string, record: EntityData) => {
        return <>{record.price > 0 ? formatRupiahPrice(record.price) : '-'}</>;
      },
      inputType: 'number',
    },
    {
      title: 'Subtotal',
      render: (text: string, record: EntityData) => {
        return <>{record.subtotal > 0 ? formatRupiahPrice(record.subtotal) : '-'}</>;
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: EntityData) => {
        const editable = isEditing(record);
        return (
          <Space>
            {editable ? (
              <>
                <Button type="primary" onClick={() => save(record.id)}>
                  Simpan
                </Button>
                <Popconfirm title="Batal" onConfirm={cancel}>
                  <Button type="ghost">Batal</Button>
                </Popconfirm>
              </>
            ) : (
              <>
                <Button type="ghost" disabled={editingKey !== 0 || !record.is_check} onClick={() => edit(record)}>
                  Ubah Qty
                </Button>
              </>
            )}
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: EntityData) => ({
        record,
        inputType: col.inputType || 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={tableData.data}
        pagination={{ ...tableData.pagination, total: recordCount.count }}
        loading={tableData.loading || recordCount.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
        rowSelection={rowSelection}
      />
    </Form>
  );
};
