import React from 'react';
import { Input, InputNumber, Form } from 'antd';
import { BasicTableRow } from '@app/api/table.api';
import { TextArea } from '@app/components/common/inputs/Input/Input';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'number' | 'text' | 'textarea';
  record: BasicTableRow;
  index: number;
  onInputChange?: () => void;
  children: React.ReactNode;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  ...restProps
}) => {
  let inputNode = <Input />;
  switch (inputType) {
    case 'number':
      inputNode = <InputNumber />;
      break;
    case 'textarea':
      inputNode = <TextArea rows={5} />;
      break;
    default:
      break;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
