import React, { useEffect } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { LinkButton } from '@app/components/common/buttons/Button/Button';
import { DashboardOutlined, PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { checkPagePermission, checkPermission } from '@app/utils/utils';
import { VendorCommissionListTable } from '@app/components/tables/master/vendor_commission/VendorCommissionListTable';

const ListVendorCommissionPage: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_vendor_commission',
          action: 'READ',
        },
      ])
    )
      navigate('/');
  }, []);

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="#">Komisi Vendor</BreadcrumbItem>
      </Breadcrumb>
      <Card
        title="Komisi Vendor"
        padding="1.25rem 1.25rem 0"
        extra={
          checkPermission('tt_vendor_commission', 'CREATE') && (
            <LinkButton size="middle" icon={<PlusOutlined />} to="/master/vendor-commission/create">
              Tambah Komisi Vendor
            </LinkButton>
          )
        }
      >
        <VendorCommissionListTable />
      </Card>
    </>
  );
};

export default ListVendorCommissionPage;
