import { DashboardOutlined } from '@ant-design/icons';
import {
  EntityData,
  EntityFieldsResponse,
  EntityRecordRequest,
  createEntityRecord,
  getEntityFields,
  getEntityRecord,
} from '@app/api/master/entity.api';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Card } from '@app/components/common/Card/Card';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { DatePicker } from '@app/components/common/pickers/DatePicker';
import { LocationForm } from '@app/components/forms/master/Location/LocationForm';
import { SelectionForm } from '@app/components/forms/master/SelectionForm';
import { EditableQuotationProductListTable } from '@app/components/tables/master/quotation_product/EditableQuotationProductListTable';
import { TransactionType } from '@app/constants/transaction';
import { notificationController } from '@app/controllers/notificationController';
import { useLoading } from '@app/hooks/useLoading';
import { useMounted } from '@app/hooks/useMounted';
import { checkPagePermission } from '@app/utils/utils';
import { Col, Row, Space } from 'antd';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CreateQuotationPage: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isMounted } = useMounted();

  const [form] = BaseButtonsForm.useForm();

  const [refreshToken, setRefreshToken] = useState(0);
  const [_, setLoadingScreen] = useAtom(useLoading);
  const [metafield, setMetafield] = useState<EntityFieldsResponse>();
  const [packageData, setPackageData] = useState<EntityData>();
  const [productData, setProductData] = useState<EntityData>();
  const [isEditing, setIsEditing] = useState(false);

  const [vendorId, setVendorId] = useState(0);

  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_quotation',
          action: 'CREATE',
        },
      ])
    )
      navigate('/');

    form.setFieldValue('date', dayjs());
  }, []);

  useEffect(() => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    getEntityFields('tt_quotation')
      .then((res) => {
        setMetafield(res);
      })
      .catch((err) => {
        console.error(`[Error Get Quotation Fields] - ${err}`);
      })
      .finally(() => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  }, [refreshToken]);

  const onFinish = useCallback((values: EntityData) => {
    // todo dispatch an action here
    setLoading(true);

    setTimeout(() => {
      setFieldsChanged(false);
      // call create quotation API
      const params: EntityRecordRequest = {
        ...values,
        city: {
          id: values.city,
        },
        customer: {
          id: values.customer,
        },
        admin: {
          id: values.admin,
        },
        sales: {
          id: values.sales,
        },
        pkg: {
          id: values.pkg,
        },
        status: TransactionType.DRAFT,
      };

      if (values.vendor) params['vendor'] = { id: values.vendor };
      if (values.technician) params['technician'] = { id: values.technician };

      if (values.date) params['date'] = dayjs(values.date).format('YYYY-MM-DD 00:00:00');
      if (values.survey_date) params['survey_date'] = dayjs(values.survey_date).format('YYYY-MM-DD 00:00:00');

      if (!values.spk_id || values.spk_id == '') delete params['spk_id'];

      createEntityRecord('tt_quotation', params)
        .then((res) => {
          notificationController.success({ message: 'Sukses menambah penawaran' });
          navigate(`/transaction/quotation/${res.id}/update`);
        })
        .catch((err) => {
          console.error(`[Create Quoatation Error] - ${err}`);
          notificationController.error({ message: 'Gagal menambah penawaran' });
        })
        .finally(() => {
          if (isMounted.current) {
            setLoading(false);
          }
        });
    }, 1000);
  }, []);

  const productChanges = (data: EntityData[]) => {
    setFieldsChanged(true);
    setProductData(data);
  };

  const changePackage = (value: any) => {
    setFieldsChanged(true);

    getPackageDetail(value).then((res) => {
      setPackageData(res);
    });
  };

  const getPackageDetail = async (packageId: string): Promise<any> => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    return getEntityRecord('tt_package', packageId)
      .then((res) => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
        return res;
      })
      .catch((err) => {
        notificationController.error({
          message: 'Maaf terjadi kesalahan pada sistem. Silahkan coba beberapa saat lagi.',
        });
        console.error(`[Get Package Error] - ${err}`);
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
        return null;
      });
  };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="/transaction/quotation">Dokumen Penawaran</BreadcrumbItem>
        <BreadcrumbItem href="#">Tambah</BreadcrumbItem>
      </Breadcrumb>
      <Row gutter={[30, 30]}>
        {/* Main Data */}
        <Col xs={24} md={24}>
          <Card title="Data Penawaran" padding="1.25rem">
            <BaseButtonsForm
              form={form}
              name="Tambah Penawaran"
              isFieldsChanged={isFieldsChanged && !isEditing}
              onFieldsChange={() => setFieldsChanged(true)}
              setFieldsChanged={setFieldsChanged}
              onFinish={onFinish}
              loading={isLoading}
            >
              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={8}>
                  <BaseButtonsForm.Item
                    name="date"
                    label="Tanggal"
                    rules={[{ required: !metafield?.date.nullable, message: 'Harap masukkan tanggal penawaran' }]}
                  >
                    <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} placeholder="Pilih tanggal" disabled />
                  </BaseButtonsForm.Item>
                </Col>

                <Col xs={24} md={8}>
                  <BaseButtonsForm.Item
                    name="survey_date"
                    label="Tanggal Survei"
                    rules={[{ required: !metafield?.survey_date.nullable, message: 'Harap masukkan tanggal survei' }]}
                  >
                    <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} placeholder="Pilih tanggal" />
                  </BaseButtonsForm.Item>
                </Col>

                <Col xs={24} md={8}>
                  <BaseButtonsForm.Item
                    name="spk_id"
                    label="ID SPK"
                    rules={[
                      { required: !metafield?.spk_id.nullable, message: 'Harap masukkan id SPK', type: 'string' },
                    ]}
                  >
                    <Input />
                  </BaseButtonsForm.Item>
                </Col>

                <Col xs={24} md={8}>
                  <SelectionForm
                    entity="tt_package"
                    field="pkg"
                    form={form}
                    label="Paket"
                    onChange={(value) => changePackage(value)}
                  />
                </Col>

                <Col xs={24} md={8}>
                  <SelectionForm
                    entity="tt_admin"
                    field="admin"
                    form={form}
                    label="Admin"
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.admin}
                  />
                </Col>

                <Col xs={24} md={8}>
                  <SelectionForm
                    entity="tt_vendor"
                    field="vendor"
                    form={form}
                    label="Vendor"
                    onChange={(res) => {
                      setFieldsChanged(true);
                      setVendorId(res);
                      form.resetFields(['technician']);
                    }}
                    onClear={() => setVendorId(0)}
                    metafield={metafield?.vendor}
                  />
                </Col>

                <Col xs={24} md={8}>
                  <SelectionForm
                    entity="tt_sales_person"
                    field="sales"
                    form={form}
                    label="Sales"
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.sales}
                    disabled={!form.getFieldValue('vendor')}
                    additionalQuery={vendorId ? `branch.vendor.id==${vendorId}` : ''}
                  />
                </Col>

                <Col xs={24} md={8}>
                  <SelectionForm
                    entity="tt_technician"
                    field="technician"
                    form={form}
                    label="Teknisi"
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.technician}
                    disabled={!form.getFieldValue('vendor')}
                    additionalQuery={vendorId ? `vendor.id==${vendorId}` : ''}
                  />
                </Col>
              </Row>

              <BaseButtonsForm.Item>
                <BaseButtonsForm.Title>Data Customer</BaseButtonsForm.Title>
              </BaseButtonsForm.Item>

              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={8}>
                  <SelectionForm
                    entity="c_customer"
                    field="customer"
                    form={form}
                    label="Customer"
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.customer}
                  />
                </Col>

                <Col xs={24} md={8}>
                  <BaseButtonsForm.Item
                    name="phone"
                    label="No Telp"
                    rules={[
                      {
                        required: !metafield?.phone.nullable,
                        message: 'Harap masukkan nomor telpon',
                        type: 'string',
                      },
                    ]}
                  >
                    <Input />
                  </BaseButtonsForm.Item>
                </Col>

                <Col xs={24} md={8}>
                  <BaseButtonsForm.Item
                    name="mobile_phone"
                    label="Handphone"
                    rules={[
                      {
                        required: !metafield?.mobile_phone.nullable,
                        message: 'Harap masukkan nomor handphone',
                        type: 'string',
                      },
                    ]}
                  >
                    <Input />
                  </BaseButtonsForm.Item>
                </Col>
              </Row>

              <LocationForm form={form} onChange={() => setFieldsChanged(true)} metafield={metafield} />

              {packageData && (
                <Row style={{ marginBottom: 16 }} gutter={{ xs: 10, md: 15 }}>
                  <BaseButtonsForm.Item>
                    <BaseButtonsForm.Title>Data Produk</BaseButtonsForm.Title>
                  </BaseButtonsForm.Item>
                  <Col xs={24}>
                    <EditableQuotationProductListTable
                      quotationId={''}
                      packageId={packageData.id || ''}
                      onChange={(data) => productChanges(data)}
                      setIsEditing={setIsEditing}
                    />
                  </Col>
                </Row>
              )}
            </BaseButtonsForm>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreateQuotationPage;
