import { DeleteOutlined, EditTwoTone, SearchOutlined } from '@ant-design/icons';
import { Pagination } from '@app/api/general.api';
import { EntityData, deleteEntity, getEntityRecordCount, getEntityRecords } from '@app/api/master/entity.api';
import { Modal } from '@app/components/common/Modal/Modal';
import { notificationController } from '@app/controllers/notificationController';
import { useLoading } from '@app/hooks/useLoading';
import { useMounted } from '@app/hooks/useMounted';
import { formatFullDate } from '@app/utils/utils';
import { Input, Space, TablePaginationConfig } from 'antd';
import { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps, FilterValue } from 'antd/es/table/interface';
import { Table } from 'components/common/Table/Table';
import { Button } from 'components/common/buttons/Button/Button';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const initialPagination: Pagination = {
  pageSize: 10,
  current: 1,
};

export const ProductCategoryListTable: React.FC = () => {
  const [_, setLoading] = useAtom(useLoading);
  const [deleteModalData, setDeleteModalData] = useState<{
    show: boolean;
    data: EntityData | null;
  }>({
    show: false,
    data: null,
  });

  const [tableData, setTableData] = useState<{
    data: EntityData[];
    pagination: Pagination;
    loading: boolean;
    q: number | string | boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
    q: '',
  });

  const [recordCount, setRecordCount] = useState<{
    loading: boolean;
    count: number;
  }>({
    loading: false,
    count: 0,
  });

  const { isMounted } = useMounted();
  const navigate = useNavigate();
  const [refetchToken, setRefetch] = useState(0);

  const fetch = useCallback(
    (pagination: Pagination, query?: string) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));

      getEntityRecords('c_product_category', {
        page: (pagination.current || 1) - 1,
        limit: pagination.pageSize || 10,
        q: query,
        sort: 'createdDate DESC',
      })
        .then((res) => {
          if (isMounted.current) {
            setTableData({
              data: res.records,
              pagination: {
                current: pagination.current,
                pageSize: pagination.pageSize,
              },
              loading: false,
              q: query || '',
            });
          }
        })
        .catch((err) => {
          console.error(`[Get Product Category List Error] - ${err}`);
        });
    },
    [isMounted, refetchToken],
  );

  const fetchCount = useCallback(
    (pagination: Pagination, query?: string) => {
      setRecordCount((recordCount) => ({ ...recordCount, loading: true }));

      getEntityRecordCount('c_product_category', {
        page: (pagination.current || 1) - 1,
        limit: pagination.pageSize || 10,
        q: query,
      })
        .then((res) => {
          if (isMounted.current) {
            setRecordCount({
              count: res.count,
              loading: false,
            });
          }
        })
        .catch((err) => {
          console.error(`[Get Product Category List Count Error] - ${err}`);
        });
    },
    [isMounted, refetchToken],
  );

  useEffect(() => {
    fetch(initialPagination);
    fetchCount(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
    if (filters.name) {
      fetchCount(pagination, `name=ilike='${filters.name}'`);
      fetch(pagination, `name=ilike='${filters.name}'`);
    } else {
      fetch(pagination);
      fetchCount(pagination);
    }
  };

  const handleDeleteRow = (row: EntityData | null) => {
    setDeleteModalData({
      show: false,
      data: null,
    });

    setLoading({
      isLoading: true,
      message: 'Mohon menunggu...',
    });

    deleteEntity('c_product_category', row?.id || '')
      .then(() => {
        notificationController.success({ message: 'Sukses menghapus kategori produk' });
        setRefetch(refetchToken + 1);
      })
      .catch((err) => {
        console.error(`[Delete Product Category Error] - ${err}`);
        notificationController.error({ message: 'Gagal menghapus kategori produk' });
      })
      .finally(() => {
        setLoading({
          isLoading: false,
        });
      });
  };

  const handleEditRow = (rowId: string | number) => {
    navigate(`/master/category/${rowId}/update`);
  };

  // Advanced Search
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string): ColumnType<EntityData> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`cari kategori`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            Cari
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (!record[dataIndex]) return false;
      else
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase());
    },
  });

  const columns: ColumnsType<EntityData> = [
    {
      title: 'Nama',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Deskripsi',
      dataIndex: 'description',
    },
    {
      title: 'Dibuat pada',
      render: (text: string, record: EntityData) => {
        return record.created_date ? formatFullDate(record.created_date) : '';
      },
    },
    {
      title: 'Terakhir diubah',
      render: (text: string, record: EntityData) => {
        return record.updated_date ? formatFullDate(record.updated_date) : '';
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      width: '5%',
      render: (_, record: EntityData) => {
        return (
          <Space
            direction="vertical"
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <EditTwoTone onClick={() => handleEditRow(record.id)} style={{ fontSize: '20px' }} />
            <DeleteOutlined
              onClick={() => setDeleteModalData({ show: true, data: record })}
              style={{
                fontSize: '20px',
                color: 'red',
              }}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={tableData.data}
        pagination={{ ...tableData.pagination, total: recordCount.count }}
        loading={tableData.loading || recordCount.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      <Modal
        title="Konfirmasi"
        visible={deleteModalData.show}
        onOk={() => handleDeleteRow(deleteModalData.data)}
        onCancel={() => setDeleteModalData({ show: false, data: deleteModalData.data })}
      >
        Apakah yakin ingin menghapus {deleteModalData.data?.name}?
      </Modal>
    </>
  );
};
