import React from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { GenericFormProps } from '@app/types/generalTypes';

export const PostalCodeItem: React.FC<GenericFormProps> = ({ metafield, disabled }) => {
  return (
    <BaseButtonsForm.Item
      name="postal_code"
      label="Kodepos"
      hasFeedback
      rules={[{ required: !metafield?.nullable, message: 'Harap masukkan kode pos' }]}
    >
      <Input disabled={disabled} />
    </BaseButtonsForm.Item>
  );
};
