import { InputNumber } from 'antd';
import React from 'react';

type NumberInputProps = {
  disabled?: boolean;
  onChange?: () => void;
};

export const NumberInput: React.FC<NumberInputProps> = ({ disabled = false, onChange, ...props }) => {
  return (
    <InputNumber
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
      controls={false}
      disabled={disabled}
      onChange={onChange}
      style={{ width: '100%' }}
      {...props}
    />
  );
};
