import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useAtom } from 'jotai';
import { useLoading } from '@app/hooks/useLoading';
import { notificationController } from '@app/controllers/notificationController';
import { Card } from '@app/components/common/Card/Card';
import { Button, Col, Empty, Image, Row, Space } from 'antd';
import { createEntityRecord, getPicture } from '@app/api/master/entity.api';
import { VendorPaymentType } from '@app/domain';
import { getUrlEntityType } from '@app/pages/system/vendor_payment/_utils';
import { useMounted } from '@app/hooks/useMounted';
import UploadPhoto from '@app/components/forms/UpdatePhoto/UploadPhoto';
import './ProofsTab.module.css';

const kImageSize = 150;

type Props = {
  getMedia: () => void;
};

const ProofsTab: FC<Props> = ({ getMedia }) => {
  const { isMounted } = useMounted();
  const [_, setLoadingScreen] = useAtom(useLoading);
  const { selected: selectedPayment } = useAppSelector((state) => state.vendorPayment);
  const [images, setImages] = useState<any[]>([]);
  const [triggerUploadPhoto, setTriggerUploadPhoto] = useState<boolean>(false);
  const [refetchPhotoToken, setRefetchPhotoToken] = useState<number>(0);

  useEffect(() => {
    setImages([]);
    if (selectedPayment) {
      getMedia();
    }
  }, [selectedPayment?.code]);

  useEffect(() => {
    const arrMedia = selectedPayment?.media ?? [];
    if (arrMedia.length === 0) {
      return;
    }
    Promise.all(arrMedia.map((v) => getPicture(v.id))).then(
      (data) => {
        console.log('--- Fetch Image Urls: ', data);
        setImages(data);
      },
      (error) => notificationController.error({ message: `Gagal mengambil gambar bukti bayar. ${error.message}` }),
    );
  }, [selectedPayment?.media]);

  const onSuccessUpload = (imageId: number) => {
    console.log('--- Post Upload: ', imageId);
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    createEntityRecord(getUrlEntityType(VendorPaymentType.FetchMedia), {
      vendor_payment: {
        id: selectedPayment?.id ?? '',
      },
      media: {
        id: imageId,
      },
    })
      .then(() => {
        setRefetchPhotoToken(refetchPhotoToken + 1);
        getMedia();
      })
      .catch((err) => {
        console.error(`[Upload Photo Error] - ${err}`);
        notificationController.error({ message: 'Gagal mengupload foto' });
      })
      .finally(() => {
        if (isMounted.current) {
          setLoadingScreen({
            isLoading: false,
            message: 'Mohon menunggu...',
          });
          setTriggerUploadPhoto(false);
        }
      });
  };

  const renderImages =
    images.length < 1 ? null : (
      <Space size={[40, 40]} wrap style={{ marginTop: 40 }}>
        {images.map((v, i) => {
          console.log(`--- URL: ${v} | Index: ${i}`);
          return (
            <Image
              key={`image-bukti-bayar-${i}`}
              width={kImageSize}
              height={kImageSize}
              src={v}
              style={{
                objectFit: 'cover',
                borderRadius: 8,
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
              }}
            />
          );
        })}
      </Space>
    );

  const renderEmpty = (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 75,
        marginBottom: 75,
      }}
    >
      <Empty />
    </div>
  );

  return (
    <>
      <Card title="Bukti Bayar" padding="1.25rem">
        <Col xs={24} md={24}>
          <Row justify="end">
            <Button size="middle" onClick={() => setTriggerUploadPhoto(true)}>
              Tambah Bukti Bayar
            </Button>
          </Row>
          {images.length < 1 ? renderEmpty : renderImages}
        </Col>
      </Card>
      <UploadPhoto
        trigger={triggerUploadPhoto}
        filename={`spk-photo-bukti-bayar-${selectedPayment?.id ?? ''}-${Date.now()}`}
        onUploadSuccess={onSuccessUpload}
        onCancel={() => setTriggerUploadPhoto(false)}
      />
    </>
  );
};

export default React.memo(ProofsTab);
