import React from 'react';
import { ButtonProps as AntButtonProps, Button as AntdButton } from 'antd';
import { Severity } from '@app/interfaces/interfaces';
import * as S from './Button.styles';
import { useNavigate } from 'react-router-dom';

export const { Group: ButtonGroup } = AntdButton;

export interface ButtonProps extends AntButtonProps {
  className?: string;
  severity?: Severity;
  noStyle?: boolean;
  to?: string;
}

export const Button = React.forwardRef<HTMLElement, ButtonProps>(
  ({ className, severity, noStyle, children, ...props }, ref) => (
    <S.Button ref={ref} className={className} $noStyle={noStyle} {...props} $severity={severity}>
      {children}
    </S.Button>
  ),
);

export const LinkButton = React.forwardRef<HTMLElement, ButtonProps>(
  ({ className, severity, noStyle, children, to, ...props }, ref) => {
    const navigate = useNavigate();
    const onClick = (event: React.MouseEvent<HTMLElement>) => {
      if (to) navigate(to);
      else props.onClick ? props.onClick(event) : null;
    };

    return (
      <S.Button ref={ref} className={className} $noStyle={noStyle} {...props} $severity={severity} onClick={onClick}>
        {children}
      </S.Button>
    );
  },
);
