import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import React from 'react';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { FormInstance } from 'antd/es/form/Form';
import { EntityField } from '@app/api/master/entity.api';

type DataSelectionOptions = {
  label: string;
  value: string | number;
};

export type DataSelectionFormProps = {
  field: string;
  label: string;
  form?: FormInstance;
  metafield?: EntityField;
  onChange?: () => void;
  options: Array<DataSelectionOptions>;
  disabled?: boolean;
};

export const DataSelectionForm: React.FC<DataSelectionFormProps> = ({
  field,
  label,
  form,
  metafield,
  onChange,
  options,
  disabled = false,
}) => {
  const handleChange = (value: unknown) => {
    const val = value as string[];
    form?.setFieldValue(field, val);
    if (onChange !== undefined) onChange();
  };

  return (
    <>
      <BaseButtonsForm.Item
        name={field}
        label={label}
        rules={[{ required: !metafield?.nullable, message: 'Harap pilih salah satu' }]}
        hasFeedback
      >
        <Select defaultValue={form?.getFieldValue(field)} onChange={handleChange} disabled={disabled}>
          {options &&
            options.map((dt, idx) => {
              return (
                <Option value={dt.value} key={`${field}-selection-${idx}`}>
                  {dt.label}
                </Option>
              );
            })}
        </Select>
      </BaseButtonsForm.Item>
    </>
  );
};
