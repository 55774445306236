import React from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Col, Row } from 'antd';
import { Input } from '@app/components/common/inputs/Input/Input';
import { RadioButton, RadioGroup } from '@app/components/common/Radio/Radio';
import { DatePicker } from '@app/components/common/pickers/DatePicker';
import dayjs from 'dayjs';
import { FormInstance } from 'antd/es/form/Form';
import { EntityFieldsResponse } from '@app/api/master/entity.api';

export type PersonalInfoFormProps = {
  form?: FormInstance;
  metafield?: EntityFieldsResponse;
  onChange?: () => void;
  desktopColumn?: number;
};

export const PersonalInfoForm: React.FC<PersonalInfoFormProps> = ({ form, metafield, desktopColumn, onChange }) => {
  return (
    <Row gutter={{ xs: 10, md: 15 }}>
      {metafield && metafield?.email && (
        <Col xs={24} md={desktopColumn || 12}>
          <BaseButtonsForm.Item
            name="email"
            label="Email"
            rules={[{ required: !metafield?.email.nullable, message: 'Harap masukkan email', type: 'string' }]}
          >
            <Input />
          </BaseButtonsForm.Item>
        </Col>
      )}

      {metafield && metafield?.phone && (
        <Col xs={24} md={desktopColumn || 12}>
          <BaseButtonsForm.Item
            name="phone"
            label="No Telp"
            rules={[{ required: !metafield?.phone.nullable, message: 'Harap masukkan nomor telpon', type: 'string' }]}
          >
            <Input />
          </BaseButtonsForm.Item>
        </Col>
      )}

      <Col xs={24} md={desktopColumn || 12}>
        <BaseButtonsForm.Item
          name="mobile_phone"
          label="Handphone"
          rules={[
            { required: !metafield?.mobile_phone.nullable, message: 'Harap masukkan nomor handphone', type: 'string' },
          ]}
        >
          <Input />
        </BaseButtonsForm.Item>
      </Col>

      {metafield && metafield?.date_of_birth && (
        <Col xs={24} md={desktopColumn || 12}>
          <BaseButtonsForm.Item
            name="date_of_birth"
            label="Tanggal Lahir"
            rules={[{ required: !metafield?.date_of_birth.nullable, message: 'Harap masukkan tanggal lahir' }]}
          >
            <DatePicker
              format="DD-MM-YYYY"
              style={{ width: '100%' }}
              placeholder="Pilih tanggal"
              disabledDate={(current) => {
                return current && current > dayjs().endOf('day');
              }}
            />
          </BaseButtonsForm.Item>
        </Col>
      )}

      {metafield && metafield?.gender && (
        <Col xs={24} md={desktopColumn || 12}>
          <BaseButtonsForm.Item
            name="gender"
            label="Jenis Kelamin"
            rules={[{ required: !metafield?.gender.nullable, message: 'Harap pilih jenis kelamin', type: 'string' }]}
          >
            <RadioGroup>
              <RadioButton value="MALE">Laki-laki</RadioButton>
              <RadioButton value="FEMALE">Perempuan</RadioButton>
            </RadioGroup>
          </BaseButtonsForm.Item>
        </Col>
      )}
    </Row>
  );
};
