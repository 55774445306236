import { DashboardOutlined, DownOutlined, PlusOutlined } from '@ant-design/icons';
import {
  EntityData,
  EntityFieldsResponse,
  EntityRecordRequest,
  ReportType,
  createEntityRecord,
  getEntityFields,
  getEntityRecord,
  getEntityRecords,
  getReport,
  updateEntityRecord,
  updateTransactionStatus,
} from '@app/api/master/entity.api';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Card } from '@app/components/common/Card/Card';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { Menu, MenuItem } from '@app/components/common/Menu/Menu';
import {
  VerticalLabelCell,
  VerticalRow,
  VerticalTableWrapper,
  VerticalValueCell,
} from '@app/components/common/Table/Table.styles';
import { TabPane, Tabs } from '@app/components/common/Tabs/Tabs';
import { LinkButton } from '@app/components/common/buttons/Button/Button';
import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { DatePicker } from '@app/components/common/pickers/DatePicker';
import UploadPhoto from '@app/components/forms/UpdatePhoto/UploadPhoto';
import { DataSelectionForm } from '@app/components/forms/master/DataSelectionForm';
import { LocationForm } from '@app/components/forms/master/Location/LocationForm';
import { SelectionForm } from '@app/components/forms/master/SelectionForm';
import { Loading } from '@app/components/loading';
import { PackageProductListTable } from '@app/components/tables/master/package_product/PackageProductListTable';
import { SPKInvoiceListTable } from '@app/components/tables/master/spk_invoice/SPKInvoiceListTable';
import { SPKProductListTable } from '@app/components/tables/master/spk_product/SPKProductListTable';
import { TransactionType } from '@app/constants/transaction';
import { notificationController } from '@app/controllers/notificationController';
import { useLoading } from '@app/hooks/useLoading';
import { useMounted } from '@app/hooks/useMounted';
import { checkPagePermission, checkPermission, formatDate, getStatusAction, getStatusLabelTag } from '@app/utils/utils';
import { Col, Row, Space, notification } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SPKPhotoComponent from './photo/SPKPhotoComponent';

dayjs.extend(utc);

export enum SPKTabData {
  DETAIL = '1',
  PRODUCT = '2',
  INVOICE = '3',
  PHOTO = '4',
  PROGRESS = '5',
}

const UpdateSPKPage: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const navigate = useNavigate();
  const { isMounted } = useMounted();
  const params = useParams();

  const setLoadingScreen = useAtom(useLoading)[1];
  const [data, setData] = useState<EntityData | undefined>();
  const [metafield, setMetafield] = useState<EntityFieldsResponse>();

  const [triggerUploadPhoto, setTriggerUploadPhoto] = useState<boolean>(false);
  const [refetchPhotoToken, setRefetchPhotoToken] = useState<number>(0);
  const [_, setProductData] = useState<EntityData[]>();
  const [packageData, setPackageData] = useState<EntityData>();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_spk',
          action: 'READ',
        },
      ])
    )
      navigate('/');

    if (!params.id) navigate('/transaction/spk');

    fetchSPKData();
    fetchMetafield();
    fetchProgress();
  }, [params.id]);

  const [canUpdate, setCanUpdate] = useState(true);

  const statusMenu = () => {
    const actions = getStatusAction(data?.status, 'tt_spk');

    if (actions.length == 0) return <></>;
    return (
      <Menu>
        {actions.map((action) => (
          <MenuItem
            danger={action.action == TransactionType.CANCELLED}
            key={`menu-${action.label}`}
            onClick={() => updateStatus(action.action)}
          >
            <Button type="text" rel="noopener noreferrer">
              {action.label}
            </Button>
          </MenuItem>
        ))}
      </Menu>
    );
  };

  const validateData = (mandatoryFields: Array<{ field: string; title: string }>): boolean => {
    let passed = true;
    mandatoryFields.forEach((field) => {
      if (data && !data[field.field]) {
        notificationController.error({ message: `Harap mengisi data ${field.title} sebelum mengganti status` });
        passed = false;
      }
    });

    return passed;
  };

  const updateStatus = (status: TransactionType) => {
    // checking what to do before changing status
    if (status == TransactionType.IN_PROGRESS)
      if (
        !validateData([
          {
            field: 'customer',
            title: 'customer',
          },
          {
            field: 'vendor',
            title: 'vendor',
          },
          {
            field: 'installation_date',
            title: 'tanggal pemasangan',
          },
          {
            field: 'technician',
            title: 'teknisi',
          },
          {
            field: 'address',
            title: 'alamat',
          },
        ])
      )
        return;

    updateTransactionStatus('tt_spk', params.id!, status)
      .then(() => {
        notificationController.success({ message: 'Sukses mengubah status SPK' });
        setData({ ...data, status: status });

        if ([TransactionType.IN_PROGRESS, TransactionType.COMPLETED, TransactionType.CANCELLED].includes(status))
          setCanUpdate(false);
      })
      .catch((err) => {
        console.error(`[Update Quotation Error] - ${err}`);
        notificationController.error({ message: 'Gagal mengubah status SPK' });
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });

    setSPKDetail(data);
    fetchSPKData();
  };

  const fetchMetafield = () => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    getEntityFields('tt_spk')
      .then((res) => {
        setMetafield(res);
      })
      .catch((err) => {
        console.error(`[Error Get SPK Fields] - ${err}`);
      })
      .finally(() => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  };

  const setSPKDetail = (res: any) => {
    const newData = {
      ...res,
      adminId: res.admin.id,
      customerId: res.customer.id,
      customer: res.customer,
      admin: res.admin,
      sales: res.sales.id,
      city: res.city.id,
      pkg: res.pkg.id,
    };

    if (res.vendor) newData['vendor'] = res.vendor.id;
    if (res.technician) newData['technician'] = res.technician.id;
    if (res.offer) newData['offer'] = res.offer.id;
    if (res.pkg) newData['pkg'] = res.pkg.id;
    if (res.date) newData['date'] = dayjs(res.date);
    if (res.installation_date) newData['installation_date'] = dayjs(formatDate(res.installation_date));
    if (!res.longitude) delete newData['longitude'];
    if (!res.latitude) delete newData['latitude'];

    if (
      [
        TransactionType.WAITING_FOR_PAYMENT,
        TransactionType.WAITING_PAYMENT_TO_VENDOR,
        TransactionType.IN_PROGRESS,
        TransactionType.COMPLETED,
        TransactionType.CANCELLED,
      ].includes(res.status)
    )
      setCanUpdate(false);
    setData(newData);
  };

  const fetchSPKData = () => {
    setGetLoading(true);
    getEntityRecord('tt_spk', params.id!)
      .then((res) => {
        setSPKDetail(res);
      })
      .catch((err) => {
        navigate('/transaction/spk');
        notificationController.error({
          message: 'Maaf terjadi kesalahan pada sistem. Silahkan coba beberapa saat lagi.',
        });
        console.error(`[Get SPK Error] - ${err}`);
      })
      .finally(() => {
        if (isMounted.current) {
          setGetLoading(false);
        }
      });
  };

  const onFinish = useCallback((values: EntityData) => {
    // todo dispatch an action here
    setLoading(true);
    setTimeout(() => {
      setFieldsChanged(false);

      const excludedKeys = ['package', 'adminId', 'customerId'];
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([key, _]) => !excludedKeys.includes(key)),
      );
      // call create country API
      const body: EntityRecordRequest = { ...filteredValues };

      if (values.city) body['city'] = { id: values.city };
      if (values.customer) body['customer'] = { id: values.customerId };
      if (values.admin) body['admin'] = { id: values.adminId };
      if (values.sales) body['sales'] = { id: values.sales };
      if (values.pkg) body['pkg'] = { id: values.pkg };

      if (values.vendor) body['vendor'] = { id: values.vendor };
      if (values.technician) body['technician'] = { id: values.technician };
      if (values.offer) body['offer'] = { id: values.offer };

      if (values.date) body['date'] = dayjs.utc(values.date).format('YYYY-MM-DD HH:mm:ss');
      if (values.installation_date)
        body['installation_date'] = dayjs.utc(values.installation_date).format('YYYY-MM-DD HH:mm:ss');

      updateEntityRecord('tt_spk', params.id!, body)
        .then((res) => {
          setSPKDetail(res);
          notificationController.success({ message: 'Sukses mengubah SPK' });
        })
        .catch((err) => {
          console.error(`[Update SPK Error] - ${err}`);
          notificationController.error({ message: 'Gagal mengubah SPK' });
        })
        .finally(() => {
          if (isMounted.current) {
            setLoading(false);
          }
        });
    }, 1000);
  }, []);

  const productChanges = (data: EntityData[]) => {
    setFieldsChanged(true);
    setProductData(data);
  };

  const changePackage = (value: any) => {
    setFieldsChanged(true);

    getPackageDetail(value).then((res) => {
      setPackageData(res);
    });
  };

  const getPackageDetail = async (packageId: string): Promise<any> => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    return getEntityRecord('tt_package', packageId)
      .then((res) => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
        return res;
      })
      .catch((err) => {
        notificationController.error({
          message: 'Maaf terjadi kesalahan pada sistem. Silahkan coba beberapa saat lagi.',
        });
        console.error(`[Get Package Error] - ${err}`);
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
        return null;
      });
  };

  const generateReport = () => {
    getReport('tt_spk', params.id!, ReportType.SPK);
  };

  const successUploadPhoto = (imageId: number) => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    createEntityRecord('tt_spk_photo', {
      spk: {
        id: params.id!,
      },
      media: {
        id: imageId,
      },
      longitude: 0,
      latitude: 0,
    })
      .then(() => {
        setRefetchPhotoToken(refetchPhotoToken + 1);
      })
      .catch((err) => {
        console.error(`[Upload Photo Error] - ${err}`);
        notificationController.error({ message: 'Gagal mengupload foto' });
      })
      .finally(() => {
        if (isMounted.current) {
          setLoadingScreen({
            isLoading: false,
            message: 'Mohon menunggu...',
          });
          setTriggerUploadPhoto(false);
        }
      });
  };

  const [form] = BaseButtonsForm.useForm();

  const [progressData, setProgressData] = useState<EntityData[]>([]);
  const fetchProgress = useCallback(() => {
    if (!checkPermission('tt_spk_progress', 'READ')) return;

    getEntityRecords('tt_spk_progress', {
      page: 1,
      limit: 100,
      sort: 'createdDate DESC',
      q: `spk.id==${params.id}`,
      inf: true,
    })
      .then((res) => {
        setProgressData(res.records);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [isMounted]);

  const dataSource = [
    {
      key: '1',
      label: 'No. SPK',
      value: data?.code,
    },
    {
      key: '2',
      label: 'Customer',
      value: data?.customer.name,
    },
    {
      key: '3',
      label: 'Alamat',
      value: data?.address,
    },
    {
      key: '4',
      label: 'PIC',
      value: data?.admin.name,
    },
    {
      key: '5',
      label: 'No. Telepon',
      value: data?.mobile_phone,
    },
    {
      key: '6',
      label: 'Tanggal',
      value: data?.created_date ? formatDate(data?.created_date) : '',
    },
    {
      key: '7',
      label: 'E-Mail',
      value: data?.customer_email,
    },
  ];

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="/transaction/spk">SPK</BreadcrumbItem>
        <BreadcrumbItem href="#">Ubah</BreadcrumbItem>
      </Breadcrumb>
      {![TransactionType.DRAFT, TransactionType.PROCESSED].includes(data?.status) && (
        <Row style={{ marginBlock: '16px' }}>
          <Col>
            <Space direction="horizontal" align="center">
              <span style={{ fontSize: '26px', fontWeight: 'bold' }}>Status</span>
              <span>
                {getStatusLabelTag(data?.status || null, {
                  fontSize: '14px',
                })}
              </span>
            </Space>
          </Col>
        </Row>
      )}
      <Row gutter={[30, 30]}>
        {params.id && (
          <Col xs={24}>
            <Tabs defaultActiveKey={query.get('tab') || '1'}>
              <TabPane tab="SPK" key={SPKTabData.DETAIL}>
                <Card
                  title={
                    <>
                      {[TransactionType.DRAFT, TransactionType.PROCESSED].includes(data?.status) && (
                        <Row>
                          <Col>
                            <Space direction="horizontal" align="center">
                              Status
                              {getStatusLabelTag(data?.status || null, {
                                display: 'flex',
                                fontSize: '14px',
                                justifyContent: 'center',
                              })}
                            </Space>
                          </Col>
                        </Row>
                      )}
                      <Row
                        style={{
                          marginTop: [TransactionType.DRAFT, TransactionType.PROCESSED].includes(data?.status)
                            ? '16px'
                            : undefined,
                        }}
                      >
                        <Col>
                          <span>Data SPK</span>
                        </Col>
                      </Row>
                    </>
                  }
                  padding="1.25rem"
                  extra={
                    <Space direction="horizontal">
                      {(data?.status == TransactionType.WAITING_FOR_PAYMENT ||
                        data?.status == TransactionType.PROCESSED ||
                        data?.status == TransactionType.IN_PROGRESS ||
                        data?.status == TransactionType.WAITING_PAYMENT_TO_VENDOR) && (
                        <Button onClick={generateReport}>Laporan</Button>
                      )}
                      {data?.status != TransactionType.CANCELLED && data?.status != TransactionType.COMPLETED && (
                        <Dropdown overlay={statusMenu} trigger={['click']}>
                          <Button onClick={(e) => e.preventDefault()}>
                            Ubah Status <DownOutlined />
                          </Button>
                        </Dropdown>
                      )}
                    </Space>
                  }
                >
                  {getLoading ? (
                    <Loading message="Sedang mengambil data" />
                  ) : data?.status !== TransactionType.DRAFT ? (
                    <Space
                      direction="vertical"
                      size="large"
                      style={{
                        display: 'flex',
                        width: '100%',
                        fontSize: '26px',
                        fontWeight: 'bold',
                      }}
                    >
                      <BaseButtonsForm
                        form={form}
                        name="Edit SPK"
                        isFieldsChanged={isFieldsChanged}
                        onFieldsChange={() => setFieldsChanged(true)}
                        setFieldsChanged={setFieldsChanged}
                        onFinish={onFinish}
                        loading={isLoading}
                        initialValues={data || undefined}
                      >
                        {data?.status === TransactionType.PROCESSED && (
                          <Row gutter={{ xs: 10, md: 15 }}>
                            <Col xs={24} md={8}>
                              <BaseButtonsForm.Item
                                name="installation_date"
                                label="Tanggal Instalasi"
                                rules={[
                                  {
                                    required: !metafield?.installation_date.nullable,
                                    message: 'Harap masukkan tanggal instalasi',
                                  },
                                ]}
                              >
                                <DatePicker
                                  format="DD-MM-YYYY"
                                  style={{ width: '100%' }}
                                  placeholder="Pilih tanggal"
                                  disabled={!canUpdate}
                                />
                              </BaseButtonsForm.Item>
                            </Col>

                            <Col xs={24} md={8}>
                              <SelectionForm
                                entity="tt_vendor"
                                field="vendor"
                                form={form}
                                label="Vendor"
                                onChange={() => setFieldsChanged(true)}
                                metafield={metafield?.vendor}
                                disabled={!canUpdate}
                              />
                            </Col>

                            <Col xs={24} md={8}>
                              <SelectionForm
                                entity="tt_technician"
                                field="technician"
                                form={form}
                                label="Teknisi"
                                onChange={() => setFieldsChanged(true)}
                                metafield={metafield?.technician}
                                disabled={!canUpdate}
                              />
                            </Col>
                          </Row>
                        )}

                        <VerticalTableWrapper>
                          {dataSource.map((item) => (
                            <VerticalRow key={item.key}>
                              <VerticalLabelCell>{item.label}</VerticalLabelCell>
                              <VerticalValueCell>{item.value}</VerticalValueCell>
                            </VerticalRow>
                          ))}
                        </VerticalTableWrapper>
                        <Row style={{ marginBlock: '20px' }} justify="space-between" align="middle">
                          <span style={{ fontSize: '18px' }}>Product</span>
                          <Row gutter={16}>
                            <Col>
                              <Space direction="horizontal">
                                <LinkButton
                                  size="middle"
                                  icon={<PlusOutlined />}
                                  to={`/transaction/product-spk/create?spk=${params.id}`}
                                  disabled={data?.status != TransactionType.IN_PROGRESS}
                                >
                                  Tambah Produk
                                </LinkButton>
                              </Space>
                            </Col>
                            <Col>
                              <Space direction="horizontal">
                                <LinkButton
                                  size="middle"
                                  icon={<PlusOutlined />}
                                  to={`/transaction/package-spk/create?spk=${params.id}`}
                                  disabled={data?.status != TransactionType.IN_PROGRESS}
                                >
                                  Tambah Paket
                                </LinkButton>
                              </Space>
                            </Col>
                          </Row>
                        </Row>
                        <SPKProductListTable
                          spkId={params.id}
                          isEditable={
                            data?.status == TransactionType.IN_PROGRESS ||
                            data?.status == TransactionType.DRAFT ||
                            data?.status == TransactionType.PROCESSED
                          }
                        />
                      </BaseButtonsForm>
                    </Space>
                  ) : (
                    <BaseButtonsForm
                      form={form}
                      name="Edit SPK"
                      isFieldsChanged={isFieldsChanged}
                      onFieldsChange={() => setFieldsChanged(true)}
                      setFieldsChanged={setFieldsChanged}
                      onFinish={onFinish}
                      loading={isLoading}
                      initialValues={data || undefined}
                    >
                      <Row gutter={{ xs: 10, md: 15 }}>
                        <Col xs={24} md={8}>
                          <BaseButtonsForm.Item
                            name="date"
                            label="Tanggal"
                            rules={[
                              {
                                required: !metafield?.date.nullable,
                                message: 'Harap masukkan tanggal spk',
                              },
                            ]}
                          >
                            <DatePicker
                              format="DD-MM-YYYY"
                              style={{ width: '100%' }}
                              placeholder="Pilih tanggal"
                              disabled
                            />
                          </BaseButtonsForm.Item>
                        </Col>

                        <Col xs={24} md={8}>
                          <SelectionForm
                            entity="tt_quotation"
                            field="offer"
                            form={form}
                            label="Offer"
                            onChange={() => setFieldsChanged(true)}
                            metafield={metafield?.offer}
                            optionLabel="code"
                            disabled={!canUpdate}
                          />
                        </Col>

                        <Col xs={24} md={8}>
                          <DataSelectionForm
                            field="type"
                            form={form}
                            label="Tipe"
                            options={
                              metafield?.type.expected_values?.map((value) => {
                                return { value: value, label: value };
                              }) || []
                            }
                            onChange={() => setFieldsChanged(true)}
                            metafield={metafield?.type}
                            disabled={!canUpdate}
                          />
                        </Col>

                        {data?.status === TransactionType.DRAFT && (
                          <Col xs={24} md={8}>
                            <SelectionForm
                              entity="tt_package"
                              field="pkg"
                              form={form}
                              label="Paket"
                              metafield={metafield?.pkg}
                              onChange={(value) => changePackage(value)}
                            />
                          </Col>
                        )}

                        <Col xs={24} md={8}>
                          <SelectionForm
                            entity="tt_admin"
                            field="adminId"
                            form={form}
                            label="Admin"
                            onChange={() => setFieldsChanged(true)}
                            metafield={metafield?.admin}
                            disabled={!canUpdate}
                          />
                        </Col>

                        <Col xs={24} md={8}>
                          <SelectionForm
                            entity="tt_sales_person"
                            field="sales"
                            form={form}
                            label="Sales"
                            onChange={() => setFieldsChanged(true)}
                            metafield={metafield?.sales}
                            disabled={!canUpdate}
                          />
                        </Col>
                      </Row>

                      <BaseButtonsForm.Item>
                        <BaseButtonsForm.Title>Data Customer</BaseButtonsForm.Title>
                      </BaseButtonsForm.Item>

                      <Row gutter={{ xs: 10, md: 15 }}>
                        <Col xs={24} md={8}>
                          <SelectionForm
                            entity="c_customer"
                            field="customerId"
                            form={form}
                            label="Customer"
                            onChange={() => setFieldsChanged(true)}
                            metafield={metafield?.customer}
                            disabled={!canUpdate}
                          />
                        </Col>

                        <Col xs={24} md={8}>
                          <BaseButtonsForm.Item
                            name="customer_email"
                            label="Email Customer"
                            rules={[
                              {
                                required: !metafield?.customer_email.nullable,
                                message: 'Harap masukkan email customer',
                                type: 'string',
                              },
                            ]}
                          >
                            <Input disabled={!canUpdate} />
                          </BaseButtonsForm.Item>
                        </Col>

                        <Col xs={24} md={8}>
                          <BaseButtonsForm.Item
                            name="phone"
                            label="No Telp"
                            rules={[
                              {
                                required: !metafield?.phone.nullable,
                                message: 'Harap masukkan nomor telpon',
                                type: 'string',
                              },
                            ]}
                          >
                            <Input disabled={!canUpdate} />
                          </BaseButtonsForm.Item>
                        </Col>

                        <Col xs={24} md={8}>
                          <BaseButtonsForm.Item
                            name="mobile_phone"
                            label="Handphone"
                            rules={[
                              {
                                required: !metafield?.mobile_phone.nullable,
                                message: 'Harap masukkan nomor handphone',
                                type: 'string',
                              },
                            ]}
                          >
                            <Input disabled={!canUpdate} />
                          </BaseButtonsForm.Item>
                        </Col>

                        <Col xs={24}>
                          <LocationForm
                            form={form}
                            onChange={() => setFieldsChanged(true)}
                            metafield={metafield}
                            disabled={!canUpdate}
                          />
                        </Col>
                      </Row>

                      {packageData && (
                        <Row style={{ marginBottom: 16 }} gutter={{ xs: 10, md: 15 }}>
                          <BaseButtonsForm.Item>
                            <BaseButtonsForm.Title>Data Produk</BaseButtonsForm.Title>
                          </BaseButtonsForm.Item>
                          <Col xs={24}>
                            <PackageProductListTable
                              setIsEditing={setIsEditing}
                              packageId={packageData.id || ''}
                              onChange={(data) => productChanges(data)}
                              isPagination={false}
                              isEditable={data?.status != TransactionType.DRAFT}
                            />
                          </Col>
                        </Row>
                      )}

                      <SPKProductListTable
                        spkId={params.id}
                        isEditable={
                          data?.status == TransactionType.IN_PROGRESS ||
                          data?.status == TransactionType.DRAFT ||
                          data?.status == TransactionType.PROCESSED
                        }
                      />
                    </BaseButtonsForm>
                  )}
                </Card>
              </TabPane>

              {![TransactionType.DRAFT, TransactionType.PROCESSED, TransactionType.IN_PROGRESS].includes(
                data?.status,
              ) &&
                checkPermission('tt_invoice', 'READ') && (
                  <TabPane tab="Invoice" key={SPKTabData.INVOICE}>
                    <Card
                      title=""
                      padding="1.25rem"
                      extra={
                        checkPermission('tt_invoice', 'CREATE') && (
                          <Space>
                            <LinkButton
                              size="middle"
                              icon={<PlusOutlined />}
                              to={`/transaction/invoice/create?spk=${params.id}`}
                              disabled={data?.status != TransactionType.WAITING_FOR_PAYMENT}
                            >
                              Tambah Invoice
                            </LinkButton>
                          </Space>
                        )
                      }
                    >
                      <SPKInvoiceListTable
                        spkId={params.id}
                        isEditable={data?.status == TransactionType.WAITING_FOR_PAYMENT}
                      />
                    </Card>
                  </TabPane>
                )}
              {![TransactionType.DRAFT, TransactionType.PROCESSED].includes(data?.status) &&
                checkPermission('tt_spk_photo', 'READ') && (
                  <TabPane tab="Foto" key={SPKTabData.PHOTO}>
                    <Card
                      title=""
                      padding="1.25rem"
                      extra={
                        checkPermission('tt_spk_photo', 'CREATE') && (
                          <Button size="middle" icon={<PlusOutlined />} onClick={() => setTriggerUploadPhoto(true)}>
                            Tambah Foto
                          </Button>
                        )
                      }
                    >
                      <SPKPhotoComponent spkId={params.id} refetchToken={refetchPhotoToken} />
                    </Card>
                    <UploadPhoto
                      trigger={triggerUploadPhoto}
                      filename={`spk-photo-${params.id}`}
                      onUploadSuccess={successUploadPhoto}
                      onCancel={() => setTriggerUploadPhoto(false)}
                    />
                  </TabPane>
                )}
              {checkPermission('tt_spk_progress', 'READ') && (
                <TabPane tab="Progress" key={SPKTabData.PROGRESS}>
                  <Card title="" padding="1.25rem">
                    {progressData &&
                      progressData.map((progress) => {
                        return (
                          <>
                            <div style={{ fontSize: '14px', fontWeight: '600' }}>{progress.description}</div>
                            <div style={{ fontSize: '11px', marginTop: '4px' }}>
                              {dayjs(progress.created_date).format('DD MMM YYYY hh:mm:ss')}
                            </div>
                            <hr style={{ margin: '4px 0' }} />
                          </>
                        );
                      })}
                    {progressData.length == 0 && <>Belum ada progress</>}
                  </Card>
                </TabPane>
              )}
            </Tabs>
          </Col>
        )}
      </Row>
    </>
  );
};

export default UpdateSPKPage;
