import React from 'react';
import styled from 'styled-components';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { LoadingOutlined } from '@ant-design/icons';

interface LoadingProps {
  message?: string;
}

export const Loading: React.FC<LoadingProps> = ({ message }) => {
  return (
    <SpinnerContainer>
      <Spinner tip={message} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
