import { Pagination } from '@app/api/general.api';
import { EntityData, getEntityRecordCount, getEntityRecords, updateEntityRecord } from '@app/api/master/entity.api';
import { notificationController } from '@app/controllers/notificationController';
import { Popconfirm } from '@app/components/common/Popconfirm/Popconfirm.styles';
import { useMounted } from '@app/hooks/useMounted';
import { formatNumberWithCommas, formatRupiahPrice, roundingPriceUp } from '@app/utils/utils';
import { Form, Space, TablePaginationConfig } from 'antd';
import type { FilterValue } from 'antd/es/table/interface';
import { Table } from 'components/common/Table/Table';
import { Button } from 'components/common/buttons/Button/Button';
import React, { useCallback, useEffect, useState } from 'react';
import { EditableCell } from '../../editableTable/EditableCell';

const initialPagination: Pagination = {
  pageSize: 50,
  current: 1,
};

export type QuotationProductListTableProps = {
  packageId: string | number;
  quotationId: string | number;
  onChange: (data: EntityData[]) => void;
  setIsEditing: (isEdit: boolean) => void;
};

export const EditableQuotationProductListTable: React.FC<QuotationProductListTableProps> = ({
  packageId,
  quotationId,
  onChange,
  setIsEditing,
}) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState(0);

  const [tableData, setTableData] = useState<{
    data: EntityData[];
    loading: boolean;
    q: number | string | boolean;
  }>({
    data: [],
    loading: false,
    q: '',
  });

  const [recordCount, setRecordCount] = useState<{
    loading: boolean;
    count: number;
  }>({
    loading: false,
    count: 0,
  });

  const { isMounted } = useMounted();

  const fetch = useCallback(
    (pagination: Pagination, query?: string) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));

      const table = quotationId !== '' ? `tt_product_quotation` : `tt_package_product`;
      const tableQuery = quotationId !== '' ? `quotation.id==${quotationId}` : `pkg.id==${packageId}`;

      console.table({
        quotationId,
        packageId,
        table,
        tableQuery,
      });

      getEntityRecords(table, {
        q: `${tableQuery}` + (query ? `;${query}` : ''),
      })
        .then((res) => {
          if (isMounted.current) {
            const newData = res.records.map((item) => {
              return recalculateSubtotal({
                ...item,
                qty: item.qty || 0,
                markup_percentage: item.markup_percentage || 0,
              });
            });
            setTableData({
              data: newData,
              loading: false,
              q: query || '',
            });
            console.log(newData);
            onChange(newData);
          }
        })
        .catch((err) => {
          console.error(`[Get Quotation Product List Error] - ${err}`);
        });
    },
    [packageId, quotationId, isMounted],
  );

  const fetchCount = useCallback(
    (pagination: Pagination, query?: string) => {
      setRecordCount((recordCount) => ({ ...recordCount, loading: true }));

      getEntityRecordCount('tt_product_quotation', {
        page: (pagination.current || 1) - 1,
        limit: pagination.pageSize || 50,
        q: query,
      })
        .then((res) => {
          if (isMounted.current) {
            setRecordCount({
              count: res.count,
              loading: false,
            });
          }
        })
        .catch((err) => {
          console.error(`[Get Quotation Product List Error] - ${err}`);
        });
    },
    [packageId, isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
    fetchCount(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
    if (filters.name) {
      fetchCount(pagination, `product.name=ilike=${filters.name}`);
      fetch(pagination, `product.name=ilike=${filters.name}`);
    }
    fetch(pagination);
  };

  const handleDeleteRow = (rowId: number) => {
    const newData = tableData.data.filter((item) => item.id !== rowId);
    setTableData({ ...tableData, data: newData });
    onChange(newData);
  };

  const isEditing = (record: EntityData) => record.id === editingKey;

  const edit = (record: EntityData) => {
    form.setFieldsValue(record);
    setEditingKey(record.id);
    setIsEditing(record.id ? true : false);
  };

  const cancel = () => {
    setIsEditing(false);
    setEditingKey(0);
  };

  const save = async (id: string) => {
    try {
      const row = (await form.validateFields()) as EntityData;

      const newData = [...tableData.data];

      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        newData[index] = recalculateSubtotal(newData[index]);
      } else {
        newData.push(row);
      }

      console.log(newData[index]);

      setTableData({ ...tableData, data: newData });
      setEditingKey(0);
      onChange(newData);
      setIsEditing(false);

      updateEntityRecord('tt_product_quotation', newData[index].id, newData[index]).then(() => {
        notificationController.success({ message: 'Sukses merubah data untuk product quotation' });
        fetch(initialPagination);
      });
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const recalculateSubtotal = (record: EntityData) => {
    // calculate the rounding up
    const qty = record.qty || 0;
    const markup = record.markup_percentage || 0;
    const price = record.price || 0;
    const markupPrice = price + price * (markup / 100);
    const onePiecePrice = roundingPriceUp(markupPrice, 3);

    return {
      ...record,
      markup_price: markupPrice,
      rounded: onePiecePrice,
      subtotal: onePiecePrice * qty,
    };
  };

  const columns = [
    {
      title: 'Produk',
      dataIndex: 'product',
      render: (text: string, record: EntityData) => {
        return <Space>{record.product.name}</Space>;
      },
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      render: (text: string, record: EntityData) => {
        return formatNumberWithCommas(record.qty);
      },
      editable: true,
      inputType: 'number',
    },
    {
      title: 'UOM',
      dataIndex: 'uom',
      render: (text: string, record: EntityData) => {
        return <Space>{record.product.uom.name}</Space>;
      },
    },
    {
      title: 'Harga',
      dataIndex: 'price',
      render: (text: string, record: EntityData) => {
        return <>{record.price > 0 ? formatRupiahPrice(record.price) : '-'}</>;
      },
      editable: true,
      inputType: 'number',
    },
    {
      title: 'Markup',
      dataIndex: 'markup_percentage',
      render: (text: string, record: EntityData) => {
        return <>{record.qty > 0 ? `${record.markup_percentage}%` : '-'}</>;
      },
      editable: true,
      inputType: 'number',
    },
    {
      title: 'Harga Markup',
      render: (text: string, record: EntityData) => {
        return <>{record.markup_price ? formatRupiahPrice(record.markup_price) : '-'}</>;
      },
    },
    {
      title: 'Pembulatan',
      render: (text: string, record: EntityData) => {
        return <>{record.rounded > 0 ? formatRupiahPrice(record.rounded) : '-'}</>;
      },
    },
    {
      title: 'Subtotal',
      render: (text: string, record: EntityData) => {
        return <>{record.subtotal > 0 ? formatRupiahPrice(record.subtotal) : '-'}</>;
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: EntityData) => {
        const editable = isEditing(record);
        return (
          <Space>
            {editable ? (
              <>
                <Button type="primary" onClick={() => save(record.id)}>
                  Simpan
                </Button>
                <Popconfirm title="Batal" onConfirm={cancel}>
                  <Button type="ghost">Batal</Button>
                </Popconfirm>
              </>
            ) : (
              <>
                <Button type="ghost" disabled={editingKey !== 0} onClick={() => edit(record)}>
                  Ubah
                </Button>
                <Button type="default" danger onClick={() => handleDeleteRow(record.id)}>
                  Delete
                </Button>
              </>
            )}
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: EntityData) => ({
        record,
        inputType: col.inputType || 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={tableData.data}
        pagination={false}
        loading={tableData.loading || recordCount.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
    </Form>
  );
};
