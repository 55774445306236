import React, { useCallback, useEffect, useState } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Col, Row } from 'antd';
import { Input } from '@app/components/common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { useMounted } from '@app/hooks/useMounted';
import { useLoading } from '@app/hooks/useLoading';
import { useAtom } from 'jotai';
import {
  createEntityRecord,
  EntityData,
  EntityFieldsResponse,
  EntityRecordRequest,
  getEntityFields,
} from '@app/api/master/entity.api';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Space } from 'antd';
import { DashboardOutlined } from '@ant-design/icons';
import { checkPagePermission } from '@app/utils/utils';
import { SelectionForm } from '@app/components/forms/master/SelectionForm';
import { CurrencyInput } from '@app/components/forms/master/CurrencyInput';

const CreateVendorCommissionPage: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isMounted } = useMounted();

  const [form] = BaseButtonsForm.useForm();

  const [refreshToken, setRefreshToken] = useState(0);
  const [_, setLoadingScreen] = useAtom(useLoading);
  const [metafield, setMetafield] = useState<EntityFieldsResponse>();

  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_vendor_commission',
          action: 'CREATE',
        },
      ])
    )
      navigate('/');

    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    getEntityFields('tt_vendor_commission')
      .then((res) => {
        setMetafield(res);
      })
      .catch((err) => {
        console.error(`[Error Get Vendor Commission Fields] - ${err}`);
      })
      .finally(() => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  }, [refreshToken]);

  const onFinish = useCallback((values: EntityData) => {
    // todo dispatch an action here
    setLoading(true);
    setTimeout(() => {
      setFieldsChanged(false);
      createEntityRecord('tt_vendor_commission', {
        ...values,
        product: {
          id: values.product,
        },
        vendor: {
          id: values.vendor,
        },
      })
        .then((res) => {
          notificationController.success({ message: 'Sukses menambah komisi vendor' });
          navigate(`/master/vendor-commission/${res.id}/update`);
        })
        .catch((err) => {
          console.error(`[Error Create Vendor Commission] - ${err}`);
          notificationController.error({ message: 'Gagal menambah komisi vendor' });
        })
        .finally(() => {
          if (isMounted.current) {
            setLoading(false);
          }
        });
    }, 1000);
  }, []);

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="/master/vendor-commission">Komisi Vendor</BreadcrumbItem>
        <BreadcrumbItem href="#">Tambah</BreadcrumbItem>
      </Breadcrumb>
      <Row gutter={[30, 30]}>
        {/* Main Data */}
        <Col xs={24} md={24}>
          <Card title="Data Komisi Vendor" padding="1.25rem">
            <BaseButtonsForm
              form={form}
              name="Tambah Komisi Vendor"
              isFieldsChanged={isFieldsChanged}
              onFieldsChange={() => setFieldsChanged(true)}
              setFieldsChanged={setFieldsChanged}
              onFinish={onFinish}
              loading={isLoading}
            >
              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  <SelectionForm
                    entity="tt_product"
                    label="Produk"
                    field="product"
                    form={form}
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.product}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <SelectionForm
                    entity="tt_vendor"
                    label="Vendor"
                    field="vendor"
                    form={form}
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.vendor}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <BaseButtonsForm.Item
                    name="commission"
                    label="Komisi"
                    rules={[{ required: !metafield?.commission.nullable, message: 'Harap masukkan komisi' }]}
                  >
                    <CurrencyInput />
                  </BaseButtonsForm.Item>
                </Col>
              </Row>
            </BaseButtonsForm>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreateVendorCommissionPage;
