import React, { useEffect, useState } from 'react';
import { Avatar, Col, Row } from 'antd';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { H6 } from '@app/components/common/typography/H6/H6';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import Cookies from 'js-cookie';
import { doLogout } from '@app/store/slices/authSlice';
import { EntityData, getPicture } from '@app/api/master/entity.api';
import { SkeletonImage } from '@app/components/common/Skeleton/Skeleton';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useProfileUpdate } from '@app/hooks/useProfileUpdate';

export const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();
  const [userLog, setUserLog] = useState<EntityData | null>(null);
  const [image, setImage] = useState<any>();
  const [, setProfileUpdate] = useAtom(useProfileUpdate);
  const navigate = useNavigate();
  const [refetchToken, setRefetchToken] = useState<number>(0);

  useEffect(() => {
    loadUser();
    setProfileUpdate({
      onUpdate: () => loadUser(),
    });
  }, []);

  const loadUser = () => {
    const user = Cookies.get('user_logged');
    if (!user) {
      // no user logged in, force logout
      doLogout();
      navigate('/auth/login');
      return;
    }
    const parsedUser = JSON.parse(user || '');
    setUserLog(parsedUser);
    getProfilePic(parsedUser?.profile_picture?.id);
    setRefetchToken(refetchToken + 1);
  };

  const getProfilePic = (imageId: string | null) => {
    if (!imageId) return;

    getPicture(imageId)
      .then((res) => {
        setImage(res);
      })
      .catch((err) => {
        console.error(`[Error Get Profile Photo] - ${err}`);
      });
  };

  const getInitial = (name: string) => {
    if (!name) return '';

    const words = name.split(' ');
    let initial = name[0];
    if (words.length > 1) initial += words[1][0];

    return initial.toUpperCase();
  };

  return userLog ? (
    <Dropdown overlay={<ProfileOverlay />} trigger={['click']}>
      <S.ProfileDropdownHeader as={Row} gutter={[10, 10]} align="middle">
        <Col>
          {image ? (
            <Avatar src={`${image}`} alt="User" shape="circle" size={40} />
          ) : (
            <div
              style={{
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                backgroundColor: 'var(--secondary-background-selected-color)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              key={refetchToken}
            >
              {userLog.name && getInitial(userLog.name)}
            </div>
          )}
        </Col>
        {isTablet && (
          <Col>
            <H6>{`${userLog.name || ''}`}</H6>
          </Col>
        )}
      </S.ProfileDropdownHeader>
    </Dropdown>
  ) : null;
};
