import React from 'react';
import {
  DashboardOutlined,
  FileOutlined,
  ShopOutlined,
  TeamOutlined,
  TagsOutlined,
  CalendarOutlined,
  LockOutlined,
  SettingOutlined,
} from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  permission?: string;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Dashboard',
    key: 'dashboard',
    url: '/',
    icon: <DashboardOutlined />,
    permission: 'dashboard',
  },
  {
    title: 'Jadwal Teknisi',
    key: 'schedule',
    url: '/system/schedule',
    icon: <CalendarOutlined />,
    permission: 'tt_technician_schedule',
  },
  {
    icon: <ShopOutlined />,
    title: 'Vendor',
    key: 'vendor',
    children: [
      {
        title: 'Vendor',
        key: 'vendor',
        url: '/master/vendor',
        permission: 'tt_vendor',
      },
      {
        title: 'Spesialisasi',
        key: 'specialization',
        url: '/master/specialization',
        permission: 'tt_specialization',
      },
      {
        title: 'Teknisi',
        key: 'technician',
        url: '/master/technician',
        permission: 'tt_technician',
      },
      {
        title: 'Komisi Vendor',
        key: 'vendor-commission',
        url: '/master/vendor-commission',
        permission: 'tt_vendor_commission',
      },
      {
        title: 'Pembayaran Vendor',
        key: 'vendor-payment',
        url: '/master/vendor-payment',
        permission: 'tt_vendor_payment',
      },
    ],
  },
  {
    icon: <TagsOutlined />,
    title: 'Produk',
    key: 'product',
    children: [
      {
        title: 'Produk',
        key: 'product',
        url: '/master/product',
        permission: 'tt_product',
      },
      {
        title: 'Kategori Produk',
        key: 'category',
        url: '/master/category',
        permission: 'c_product_category',
      },
      {
        title: 'UOM',
        key: 'uom',
        url: '/master/uom',
        permission: 'c_uom',
      },
      {
        title: 'Paket',
        key: 'package',
        url: '/master/package',
        permission: 'tt_package',
      },
    ],
  },
  {
    icon: <FileOutlined />,
    title: 'Penawaran & SPK',
    key: 'transaction',
    children: [
      {
        title: 'Customer',
        key: 'customer',
        url: '/master/customer',
        permission: 'c_customer',
      },
      {
        title: 'Dokumen Penawaran',
        key: 'quotation',
        url: '/transaction/quotation',
        permission: 'tt_quotation',
      },
      {
        title: 'SPK',
        key: 'spk',
        url: '/transaction/spk',
        permission: 'tt_spk',
      },
      {
        title: 'Invoice',
        key: 'invoice',
        url: '/transaction/invoice',
        permission: 'tt_invoice',
      },
      {
        title: 'Pembayaran',
        key: 'payment',
        url: '/transaction/payment',
        permission: 'tt_payment',
      },
    ],
  },
  {
    icon: <TeamOutlined />,
    title: 'Admin',
    key: 'admin',
    children: [
      {
        title: 'Admin',
        key: 'admin',
        url: '/master/admin',
        permission: 'tt_admin',
      },
      {
        title: 'Cabang',
        key: 'branch',
        url: '/master/branch',
        permission: 'tt_branch',
      },
      {
        title: 'Sales',
        key: 'sales',
        url: '/master/sales',
        permission: 'tt_sales_person',
      },
    ],
  },
  {
    icon: <LockOutlined />,
    title: 'Pengaturan Akses',
    key: 'policy',
    children: [
      {
        title: 'Hak Akses',
        key: 'policy',
        url: '/permission/policy',
        permission: 's_policy',
      },
      {
        title: 'Grup Akses',
        key: 'group',
        url: '/permission/group',
        permission: 's_group',
      },
    ],
  },
  {
    icon: <SettingOutlined />,
    title: 'Settings',
    key: 'settings',
    url: '/system/settings',
    permission: 's_settings',
  },
];
