import React, { useEffect } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { LinkButton } from '@app/components/common/buttons/Button/Button';
import { DashboardOutlined, PlusOutlined } from '@ant-design/icons';
import { VendorListTable } from '@app/components/tables/master/vendor/VendorListTable';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Space } from 'antd';
import { checkPagePermission, checkPermission } from '@app/utils/utils';
import { useNavigate } from 'react-router-dom';

const ListVendorPage: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_vendor',
          action: 'READ',
        },
      ])
    )
      navigate('/');
  }, []);

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="#">Vendor</BreadcrumbItem>
      </Breadcrumb>
      <Card
        title="Vendor"
        padding="1.25rem 1.25rem 0"
        extra={
          checkPermission('tt_vendor', 'CREATE') && (
            <LinkButton size="middle" icon={<PlusOutlined />} to="/master/vendor/create">
              Tambah Vendor
            </LinkButton>
          )
        }
      >
        <VendorListTable />
      </Card>
    </>
  );
};

export default ListVendorPage;
