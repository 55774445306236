import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TERMedia, VendorPayment, VendorPaymentItem } from '@app/domain';

const STORAGE_PAYMENTS_KEY = 'vendorPayments';
const STORAGE_SELECTED_PAYMENT_KEY = 'selectedVendorPayment';

interface VendorPaymentState {
  nextPage: boolean;
  offset: number;
  records: VendorPayment[];
  selected?: VendorPayment;
}

const initialState: VendorPaymentState = {
  nextPage: false,
  offset: 0,
  records: [],
};

type AddVendorPaymentPayload = {
  nextPage: boolean;
  records: VendorPayment[];
};

const getSelectedPayment = () => {
  try {
    const dataString = localStorage.getItem(STORAGE_SELECTED_PAYMENT_KEY);
    if (dataString === null) return undefined;
    return JSON.parse(dataString) as VendorPayment;
  } catch (ex) {
    console.log(`[Vendor Payment Slice]: Can't find or read selected vendor payment item. ${ex}`);
    return undefined;
  }
};

const getPayments = () => {
  try {
    const dataString = localStorage.getItem(STORAGE_PAYMENTS_KEY);
    if (dataString === null) return [];
    return JSON.parse(dataString) as VendorPayment[];
  } catch (ex) {
    console.log(`[Vendor Payment Slice]: Can't find or read selected vendor payment item. ${ex}`);
    return [];
  }
};

export const vendorPaymentSlice = createSlice({
  name: 'vendorPayment',
  initialState: {
    ...initialState,
    selected: getSelectedPayment(),
    records: getPayments(),
  },
  reducers: {
    addPaymentRecords(state, action: PayloadAction<AddVendorPaymentPayload>) {
      state.nextPage = action.payload.nextPage;
      if (action.payload.records.length === 0) {
        state.records = [];
      } else {
        action.payload.records.forEach((v) => {
          const existingIndex = state.records.findIndex((existing) => existing.id === v.id);
          if (existingIndex >= 0) {
            state.records[existingIndex] = v;
          } else {
            state.records.push(v);
          }
        });
        state.records.sort((a, b) => b.id - a.id);
      }
      localStorage.setItem(STORAGE_PAYMENTS_KEY, JSON.stringify(action.payload.records));
    },
    selectPaymentRecord(state, action: PayloadAction<VendorPayment | undefined>) {
      state.selected = action.payload;
      localStorage.setItem(STORAGE_SELECTED_PAYMENT_KEY, JSON.stringify(action.payload));
    },
    updatePaymentRecord(state, action: PayloadAction<VendorPayment>) {
      state.selected = action.payload;
      for (let i = 0; i < state.records.length; i++) {
        if (state.records[i].id === action.payload.id) {
          state.records[i] = action.payload;
          break;
        }
      }
    },
    setOffset(state, action: PayloadAction<number>) {
      state.offset = action.payload;
    },
  },
});

export const { addPaymentRecords, selectPaymentRecord, updatePaymentRecord, setOffset } = vendorPaymentSlice.actions;
export default vendorPaymentSlice.reducer;
