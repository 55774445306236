import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as S from './BranchOverlay.styles';
import { DropdownMenu } from '@app/components/header/Header.styles';
import Cookies from 'js-cookie';
import { EntityData } from '@app/api/master/entity.api';
import { ActiveBranchAtom } from '.';
import { useAtom } from 'jotai';

export const BranchOptions: React.FC = ({ ...props }) => {
  const [_, setActiveBranch] = useAtom(ActiveBranchAtom);
  const [options, setOptions] = useState<
    {
      branch_name: string;
      branch_id: number;
      selected: boolean;
    }[]
  >([]);

  useEffect(() => {
    const branches = Cookies.get('branch');

    if (!branches) return;
    const dataBranches = JSON.parse(branches) as Array<EntityData>;

    setOptions(
      dataBranches.map((branch, index) => {
        return {
          branch_id: branch.id,
          branch_name: branch.name,
          selected: branch.selected,
        };
      }),
    );
  }, []);

  const selectBranch = (branch_id: number) => {
    const branches = Cookies.get('branch');
    if (!branches) return;
    const dataBranches = JSON.parse(branches) as Array<EntityData>;

    const newBranches: EntityData[] = dataBranches.map((data: EntityData) => {
      if (data.id == branch_id)
        setActiveBranch({
          name: data.name,
          id: branch_id.toString(),
        });
      return {
        ...data,
        selected: data.id == branch_id,
      };
    });
    Cookies.set('branch', JSON.stringify(newBranches));
    setOptions(
      newBranches.map((branch, index) => {
        return {
          branch_id: branch.id,
          branch_name: branch.name,
          selected: branch.selected,
        };
      }),
    );
  };

  return (
    <DropdownMenu selectable {...props}>
      {options.map((option, index) => (
        <S.MenuItem
          key={`menu-branch-${index}`}
          disabled={option.selected}
          onClick={() => selectBranch(option.branch_id)}
        >
          <S.Text>{option.branch_name}</S.Text>
        </S.MenuItem>
      ))}
    </DropdownMenu>
  );
};
