import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import * as S from './ForgotPasswordForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doResetPassword } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { forgotPassword, sendToken } from '@app/api/master/entity.api';
import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button';

interface ForgotPasswordFormData {
  username: string;
}

type ForgotPasswordChoice = {
  type: string;
  value: string;
};

const ChoiceButtonWrapper = styled.div`
  display: flex;
  place-content: space-evenly;
`;

export const ForgotPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const [chooseType, setChooseType] = useState<ForgotPasswordChoice[]>([]);
  const [username, setUsername] = useState<string>('');

  const handleSubmit = (values: ForgotPasswordFormData) => {
    setLoading(true);
    forgotPassword(values.username)
      .then((res) => {
        // navigate('/auth/security-code');
        if (res.success) {
          setChooseType(res.choice);
          setUsername(values.username);
        }
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  const forgotAction = (button: ForgotPasswordChoice) => {
    sendToken(username, button.type)
      .then((res) => {
        if (res.success) navigate('/auth/security-code');
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional">
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          Kembali
        </Auth.BackWrapper>
        <Auth.FormTitle>{t('common.resetPassword')}</Auth.FormTitle>
        {chooseType.length == 0 ? (
          <>
            <S.Description>Masukkan username yang digunakan</S.Description>
            <Auth.FormItem
              name="username"
              label="Username"
              rules={[{ required: true, message: 'Pastikan username yang dimasukkan telah benar' }]}
            >
              <Auth.FormInput placeholder="Username" />
            </Auth.FormItem>
            <BaseForm.Item noStyle>
              <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
                {t('forgotPassword.sendInstructions')}
              </S.SubmitButton>
            </BaseForm.Item>
          </>
        ) : (
          <>
            <S.Description>Pilih pengiriman token reset password</S.Description>
            <ChoiceButtonWrapper>
              {chooseType.map((button: ForgotPasswordChoice, index: number) => (
                <Button onClick={() => forgotAction(button)} type="primary" key={`password-type-${index}`}>
                  {button.type}
                </Button>
              ))}
            </ChoiceButtonWrapper>
          </>
        )}
      </BaseForm>
    </Auth.FormWrapper>
  );
};
