import { VendorPaymentType } from '@app/domain';

export const getUrlEntityType = (type: string) => {
  switch (type) {
    case VendorPaymentType.Survey:
      return 'tt_vendor_payment_survey';
    case VendorPaymentType.Template:
      return 'tt_vendor_payment_template';
    case VendorPaymentType.FetchMedia:
      return 'tt_vendor_payment_media';
    default:
      return 'tt_vendor_payment_unknown';
  }
};
