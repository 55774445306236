import React from 'react';
import { AddressItem } from './AddressItem';
import { CityForm } from './CityForm';
import { PostalCodeItem } from './PostalCodeItem';
import { Col, Input, Row } from 'antd';
import { LongitudeItem } from './LongitudeItem';
import { LatitudeItem } from './LatitudeItem';
import { FormInstance } from 'antd/es/form/Form';
import { EntityFieldsResponse } from '@app/api/master/entity.api';
import { MapItem } from './MapItem';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

export type LocationFormProps = {
  form: FormInstance;
  metafield?: EntityFieldsResponse;
  onChange: () => void;
  disabled?: boolean;
};

export const LocationForm: React.FC<LocationFormProps> = ({ form, metafield, onChange, disabled = false }) => {
  return (
    <>
      <Row gutter={{ xs: 10, md: 15 }}>
        <Col xs={24} sm={24} md={8}>
          <AddressItem metafield={metafield?.address} disabled={disabled} />
        </Col>
        <Col xs={24} sm={24} md={8}>
          <CityForm form={form} metafield={metafield?.city} onChange={onChange} disabled={disabled} />
          <PostalCodeItem metafield={metafield?.postal_code} disabled={disabled} />
        </Col>
        <Col xs={24} sm={24} md={8}>
          {/* Disable Map */}
          {/* <MapItem
            form={form}
            nullable={metafield?.latitude.nullable || metafield?.longitude.nullable}
            disabled={disabled}
            onChange={onChange}
          /> */}
          <BaseButtonsForm.Item name="longitude" label="Longitude" hidden>
            <Input />
          </BaseButtonsForm.Item>
          <BaseButtonsForm.Item name="latitude" label="Latitude" hidden>
            <Input />
          </BaseButtonsForm.Item>
        </Col>
        {/* <Col xs={24} sm={24} md={8}>
          <LongitudeItem metafield={metafield?.longitude} />
        </Col>
        <Col xs={24} sm={24} md={8}>
          <LatitudeItem metafield={metafield?.latitude} />
        </Col> */}
      </Row>
    </>
  );
};
