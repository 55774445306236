import { METABASE_TOKEN, METABASE_URL, METABASE_DASHBOARD } from '@app/utils/constants';
import { checkPermission } from '@app/utils/utils';
import jwt from 'jsonwebtoken';
import * as S from './DashboardPage.styles';

export const DashboardPage = () => {
  const payload = {
    resource: { dashboard: Number.parseInt(METABASE_DASHBOARD.toString()) },
    params: {},
    exp: Math.round(Date.now() / 1000) + 10 * 60,
  };
  const token = jwt.sign(payload, METABASE_TOKEN);
  const iframeUrl = METABASE_URL + '/embed/dashboard/' + token + '#bordered=false&titled=false';

  return (
    <S.LeftSideCol>
      {checkPermission('dashboard', 'READ') && (
        <iframe
          src={iframeUrl}
          title="Metabase Dashboard"
          frameBorder="0"
          width="100%"
          height="100%"
          style={{ position: 'absolute', top: '0', left: '0' }}
        />
      )}
    </S.LeftSideCol>
  );
};
