import React, { useEffect, useState } from 'react';
import Photo from './Photo';
import { deleteEntity, getEntityRecords } from '@app/api/master/entity.api';
import { notificationController } from '@app/controllers/notificationController';
import { Col, Row } from 'antd';
import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { useAtom } from 'jotai';
import { useLoading } from '@app/hooks/useLoading';

type SPKPhotoComponentProps = {
  spkId: string;
  refetchToken: number;
};

const SPKPhotoComponent = ({ spkId, refetchToken }: SPKPhotoComponentProps) => {
  const [listImage, setListImage] = useState<Array<any>>([]);
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [pagination, setPagination] = useState<{
    page: number;
    limit: number;
  }>({
    page: 0,
    limit: 12,
  });
  const [_, setLoadingScreen] = useAtom(useLoading);

  useEffect(() => {
    fetchSPKPhoto();
  }, [refetchToken, pagination]);

  const fetchSPKPhoto = () => {
    getEntityRecords('tt_spk_photo', {
      page: pagination.page,
      limit: pagination.limit,
      q: `spk.id==${spkId}`,
      sort: 'createdDate DESC',
    })
      .then((res) => {
        if (pagination.page == 0) setListImage(res.records);
        else setListImage([...listImage, ...res.records]);
        setHasNext(res.next_page);
      })
      .catch((err) => {
        notificationController.error({
          message: 'Maaf terjadi kesalahan pada sistem. Silahkan coba beberapa saat lagi.',
        });
        console.error(`[Get SPK Photos Error] - ${err}`);
      });
  };

  const loadMore = () => {
    setPagination({ ...pagination, page: pagination.page + 1 });
  };

  const deletePhoto = (mediaId: string) => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    deleteEntity('tt_spk_photo', mediaId)
      .then((res) => {
        notificationController.success({ message: 'Sukses menghapus foto spk' });
        setPagination({ ...pagination, page: 0 });
      })
      .catch((err) => {
        console.error(`[Delete SPK Photo Error] - ${err}`);
        notificationController.error({ message: 'Gagal menghapus foto spk' });
      })
      .finally(() => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  };

  return (
    <>
      <Row gutter={[12, 12]}>
        {listImage &&
          listImage?.map((photo: any, index: number) => (
            <Col key={index} xs={24} md={8} lg={4}>
              <Photo mediaId={photo.media.id} onDelete={() => deletePhoto(photo.id)} />
            </Col>
          ))}
      </Row>
      {hasNext && (
        <div style={{ marginTop: '12px', display: 'flex', justifyContent: 'center' }}>
          <Button size="middle" type="primary" onClick={loadMore}>
            Lihat lebih
          </Button>
        </div>
      )}
    </>
  );
};

export default SPKPhotoComponent;
