import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { notificationController } from '@app/controllers/notificationController';
import { useMounted } from '@app/hooks/useMounted';
import { Col, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { Modal } from '@app/components/common/Modal/Modal';
import styled from 'styled-components';
import { ProductCategoryModalForm } from './CategoryModalForm';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { FormInstance } from 'antd/es/form/Form';
import { EntityData, EntityField, getEntityRecords } from '@app/api/master/entity.api';
import { Pagination } from '@app/api/general.api';
import useDebounce from '@app/hooks/useDebounce';

export type ProductCategoryFormProps = {
  form: FormInstance;
  metafield?: EntityField;
  onChange: () => void;
};

export const ProductCategoryForm: React.FC<ProductCategoryFormProps> = ({ form, metafield, onChange }) => {
  const [openModal, setOpenModal] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [categoryData, setCategoryData] = useState<Array<any> | null>(null);
  const [refetchToken, setRefetch] = useState(0);
  const { isMounted } = useMounted();
  const [pagination, setPagination] = useState<Pagination>();
  const [query, setQuery] = useState<string>();

  const search = useDebounce(query, 250);

  useEffect(() => {
    fetch();
  }, [refetchToken, search]);

  const fetch = () => {
    let params: {
      page: number;
      limit: number;
      q?: string;
    } = {
      page: (pagination?.current || 1) - 1,
      limit: pagination?.pageSize || 10,
    };
    if (search) params = { ...params, q: search };

    getEntityRecords('c_product_category', params)
      .then((res) => {
        setCategoryData(
          res.records.map((data: EntityData) => {
            return {
              value: data.id,
              label: data.name,
            };
          }),
        );
      })
      .catch((err) => {
        console.error(`[Get Category List Error] ${err}`);
      })
      .finally(() => {
        if (isMounted.current) {
          setFetchLoading(false);
        }
      });
  };

  const createCategory = () => {
    setOpenModal(false);
    createForm.submit();
  };

  const loadingItem = () => {
    return (
      <div style={{ textAlign: 'center', padding: '4px' }}>
        <Spin size="small" />
        <div>Mohon menunggu</div>
      </div>
    );
  };

  const resetSelection = () => {
    setRefetch(refetchToken + 1);
  };

  const handleChange = (value: unknown) => {
    const val = value as string[];
    form.setFieldValue('category', val);
    if (onChange !== undefined) onChange();

    console.log(form.getFieldsValue());
  };

  const handleSearch = (value: string) => {
    setFetchLoading(true);
    setPagination({
      current: 1,
      pageSize: 10,
    });
    if (value) setQuery(`name=ilike=${value}`);
    else setQuery('');
  };

  const [createForm] = BaseForm.useForm();

  return (
    <>
      <BaseButtonsForm.Item
        name="category"
        label="Kategori"
        rules={[{ required: !metafield?.nullable, message: 'Harap masukkan kategori', type: 'number' }]}
        hasFeedback
      >
        <Select
          defaultValue={form.getFieldValue('category')}
          showSearch
          onChange={handleChange}
          onSearch={handleSearch}
          filterOption={false}
          notFoundContent={fetchLoading ? loadingItem() : 'Tidak ada data'}
        >
          {categoryData &&
            categoryData.map((category, idx) => {
              return (
                <Option value={category.value} key={`category-selection-${idx}`}>
                  {category.label}
                </Option>
              );
            })}
        </Select>
        <ClickableText onClick={() => setOpenModal(true)}>+ Tambah Kategori</ClickableText>
      </BaseButtonsForm.Item>
      <Modal
        title="Tambah Kategori"
        visible={openModal}
        onOk={() => createCategory()}
        onCancel={() => setOpenModal(false)}
      >
        <ProductCategoryModalForm form={createForm} onSubmit={resetSelection} />
      </Modal>
    </>
  );
};

const ClickableText = styled.div`
  text-align: right;
  font-size: 14px;
  margin-top: 5px;
  cursor: pointer;
  color: var(--text-nft-light-color);
`;
