import React, { useState } from 'react';
import { Switch } from '@app/components/common/Switch/Switch';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Input } from '@app/components/common/inputs/Input/Input';
import { Col, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { SelectionForm } from '@app/components/forms/master/SelectionForm';
import { EntityData } from '@app/api/master/entity.api';

type GroupPolicyModalProps = {
  form: FormInstance<any> | undefined;
  onFinish: (values: any) => void;
};

const GroupPolicyModal = ({ form, onFinish }: GroupPolicyModalProps) => {
  const [data, setData] = useState<EntityData>({});

  const onFinishForm = (values: any) => {
    onFinish({ ...data });
  };

  return (
    <>
      <BaseForm form={form} onFinish={onFinishForm}>
        <Row gutter={{ xs: 10, md: 15 }}>
          <Col xs={24}>
            <SelectionForm
              entity="s_policy"
              field="policy"
              form={form}
              label="Dokumen Penawaran"
              onChange={(res) => setData(res)}
              optionLabel="name"
              getFulldata
            />
          </Col>
        </Row>
      </BaseForm>
    </>
  );
};

export default GroupPolicyModal;
