import React, { useCallback, useEffect, useState } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Col, Row } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMounted } from '@app/hooks/useMounted';
import { useAtom } from 'jotai';
import { useLoading } from '@app/hooks/useLoading';
import { createEntityRecord, EntityData, EntityFieldsResponse, getEntityFields } from '@app/api/master/entity.api';
import { SelectionForm } from '@app/components/forms/master/SelectionForm';
import { SpecializationForm } from '@app/components/forms/master/Specialization/SpecializationFormSelection';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
import { Space } from 'antd';
import { DashboardOutlined } from '@ant-design/icons';
import { checkPagePermission } from '@app/utils/utils';

const CreateVendorSpecializationPage: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isMounted } = useMounted();

  const [form] = BaseButtonsForm.useForm();
  const [refreshToken, setRefreshToken] = useState(0);
  const [_, setLoadingScreen] = useAtom(useLoading);
  const [metafield, setMetafield] = useState<EntityFieldsResponse>();
  const [initialData, setInitialData] = useState<EntityData>();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  useEffect(() => {
    if (
      !checkPagePermission([
        {
          entity: 'tt_vendor_specialization',
          action: 'CREATE',
        },
      ])
    )
      navigate('/');

    form.setFieldValue('vendor', parseInt(query.get('vendor') || '0'));
  }, []);

  useEffect(() => {
    setLoadingScreen({
      isLoading: true,
      message: 'Mohon menunggu...',
    });
    getEntityFields('tt_vendor_specialization')
      .then((res) => {
        setMetafield(res);
      })
      .catch((err) => {
        console.error(`[Error Get Vendor Specialization Fields] - ${err}`);
      })
      .finally(() => {
        setLoadingScreen({
          isLoading: false,
          message: 'Mohon menunggu...',
        });
      });
  }, [refreshToken]);

  const onFinish = useCallback((values: EntityData) => {
    // todo dispatch an action here
    setLoading(true);
    setTimeout(() => {
      setFieldsChanged(false);
      // call create country API

      createEntityRecord('tt_vendor_specialization', {
        ...values,
        vendor: {
          id: values.vendor,
        },
        specialization: {
          id: values.specialization,
        },
      })
        .then((res) => {
          notificationController.success({ message: 'Sukses menambah spesialisasi vendor' });
          navigate(`/master/vendor/${values.vendor}/update`);
        })
        .catch((err) => {
          console.error(`[Create Vendor Specialization Error] - ${err}`);
          notificationController.error({ message: 'Gagal menambah spesialisasi vendor' });
        })
        .finally(() => {
          if (isMounted.current) {
            setLoading(false);
          }
        });
    }, 1000);
  }, []);

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/">
          <Space>
            <DashboardOutlined />
            <span>Dashboard</span>
          </Space>
        </BreadcrumbItem>
        <BreadcrumbItem href="/master/vendor">Vendor</BreadcrumbItem>
        <BreadcrumbItem href={`/master/vendor/${query.get('vendor')}/update`}>Ubah</BreadcrumbItem>
        <BreadcrumbItem href={`?vendor=${query.get('vendor')}`}>Spesialisasi</BreadcrumbItem>
      </Breadcrumb>
      <Row gutter={[30, 30]}>
        {/* Main Data */}
        <Col xs={24} md={24}>
          <Card title="Data Spesialisasi Vendor" padding="1.25rem">
            <BaseButtonsForm
              form={form}
              name="Tambah Spesialisasi Vendor"
              isFieldsChanged={isFieldsChanged}
              onFieldsChange={() => setFieldsChanged(true)}
              setFieldsChanged={setFieldsChanged}
              onFinish={onFinish}
              loading={isLoading}
              initialValues={initialData}
            >
              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  {/* Paket */}
                  <SelectionForm
                    entity="tt_vendor"
                    field="vendor"
                    form={form}
                    label="Vendor"
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.vendor}
                    disabled
                  />
                </Col>
              </Row>

              <Row gutter={{ xs: 10, md: 15 }}>
                <Col xs={24} md={12}>
                  {/* Product */}
                  <SpecializationForm
                    form={form}
                    onChange={() => setFieldsChanged(true)}
                    metafield={metafield?.specialization}
                  />
                </Col>
              </Row>
            </BaseButtonsForm>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CreateVendorSpecializationPage;
